<template>
  <div>
    <span @change="onChangeFile" ref="input" type="file" multiple></span>
  </div>
</template>

<script>
import * as FilePond from 'filepond';
import { loadStyle } from '../../services/script.service';

export default {
  name: 'FilePond',
  methods: {
    onChangeFile(e) {
      this.$emit('onChangeFile', e);
    },
  },
  mounted() {
    loadStyle('https://unpkg.com/filepond@^4/dist/filepond.min.css');

    if (this.$refs.input) {
      // Create a multi file upload component
      FilePond.create(this.$refs.input, {
        onaddfile: (_fileItem, out) => {
          this.$emit('onAddFile', out);
        },
      });
    }
  },
};
</script>

<style>
.filepond--credits {
  display: none !important;
  visibility: none !important;
}
</style>
