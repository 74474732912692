<template>
  <div
    class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
  >
    <div
      class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      aria-hidden="true"
    ></div>
    <span
      class="hidden sm:inline-block sm:h-screen sm:align-middle"
      aria-hidden="true"
      >&#8203;</span
    >
    <div
      class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:p-6 sm:align-middle"
      :class="[widthClass]"
    >
      <div>
        <slot name="svg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5 text-black"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              d="M5.5 16a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 16h-8z"
            />
          </svg>
        </slot>
        <div class="mt-3 text-center sm:mt-5">
          <slot name="heading"> placeholder </slot>
        </div>
      </div>
      <div class="mt-5 sm:mt-6">
        <div v-if="actions" class="flex items-center gap-2">
          <button
            @click.prevent="close"
            class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
          >
            Tambah Lagi
          </button>
          <router-link
            v-if="link"
            :to="link"
            class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
          >
            Selesai
          </router-link>
          <button
            @click.prevent="close"
            v-else
            class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
          >
            Selesai
          </button>
        </div>
        <div v-else class="flex items-center gap-2">
          <slot name="actions"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    heading: {
      type: String,
    },
    link: {
      type: String,
    },
    actions: {
      type: Boolean,
      default: true,
    },
    width: String,
  },
  methods: {
    close() {
      this.$emit('modal-action');
    },
  },
  computed: {
    widthClass() {
      const widhts = {
        default: 'sm:max-w-sm',
        medium: 'sm:max-w-md',
      };

      return widhts[this.width] ?? widhts['default'];
    },
  },
};
</script>
