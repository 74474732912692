<template>
  <div>
    <div class="text-sm font-bold text-gray-900">
      {{ value?.attributes?.origin_code }}
    </div>
    <div class="text-xs text-gray-500">
      {{ dayjs(value?.attributes?.updatedAt).format('ll LT') }}
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';

export default {
  name: 'OrderCodeColumn',
  props: {
    value: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      getOrders: 'orders/getOrders',
    })
  },
  methods: {
    dayjs,
  },
};
</script>
