<template>
  <Datatable
    :total="getReturShipments.meta.page.total"
    :perPage="getReturShipments.meta.page.perPage"
    :currentPage="getReturShipments.meta.page.currentPage"
    @pagechanged="(page) => $emit('page-change', page)"
    :is-empty="!getReturShipments.data.length"
    id="table_retur"
  >
    <template v-slot:thead>
      <thead class="bg-gray-50">
        <tr>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            Nomor Retur / Nomor Kemasan
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            Total Barang
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            Total Berat
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            Status
          </th>
        </tr>
      </thead>
    </template>
    <template v-slot:tbody class="relative">
      <tbody v-if="getReturShipments.data.length">
        <tr
          class="cursor-pointer bg-white hover:bg-green-100"
          v-for="(data, dataIdx) in getReturShipments.data"
          :key="data.id"
          :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
          @click.prevent="$emit('detail', data)"
        >
          <td
            class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
          >
            <div class="text-sm font-bold text-gray-900">
              {{ data.attributes.retur_origin_code }} /
              {{ data.attributes.code }}
            </div>
            <div class="text-xs text-gray-500">
              {{ data.attributes.updatedAt | formatDate }}
            </div>
          </td>
          <td
            class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
          >
            {{ data.attributes.product_count | toCurrency }}
          </td>
          <td
            class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
          >
            {{ data.attributes.total_weight | toCurrency }}
          </td>
          <td
            class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500"
          >
            <base-badge color="indigo">
              {{ data.attributes.shipment_status }}
            </base-badge>
          </td>
        </tr>
      </tbody>
    </template>
  </Datatable>
</template>

<script>
import { mapGetters } from 'vuex';
import Datatable from '@/components/base/Datatable';

export default {
  components: {
    Datatable,
  },
  emits: ['page-change', 'detail'],
  computed: {
    ...mapGetters({
      getReturShipments: 'retur_shipments/getReturShipments',
    }),
  },
};
</script>
