<template>
  <base-input :label="withLabel ? 'Periode' : ''" :with-label="withLabel">
    <base-dropdown width="200px">
      <template #toggle="{ toggle }">
        <base-button color="white" size="sm" v-on:click="toggle" :shadow="false">{{
          month ? selectedMonth.attributes.name : 'Periode'
        }}</base-button>
      </template>
      <template #content>
        <div class="space-y-4 p-4">
          <base-input label="Tahun" with-label>
            <base-select
              :options="selectYearOptions"
              :shadow="false"
              min-w-fit
              v-model="filter.year"
              v-on:change="handleChangeYear"
            />
          </base-input>
          <base-input label="Bulan" with-label>
            <base-select
              :options="selectMonthOptions"
              :shadow="false"
              min-w-fit
              v-model="month"
              v-on:change="handleChangeMonth"
            />
          </base-input>
        </div>
      </template>
    </base-dropdown>
  </base-input>
</template>

<script>
import BaseInput from '@/components/base/BaseInput.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import BaseDropdown from '@/components/base/BaseDropdown.vue';
import { getListPastNYear } from '@/services/date.service.js';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: { BaseSelect, BaseInput, BaseButton, BaseDropdown },
  props: {
    value: {
      type: String,
      default: null,
    },
    withLabel: {
      type: Boolean,
      default: true
    }
  },
  emits: ['input', 'change'],
  data() {
    return {
      filter: {
        year: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      getPeriods: 'periods/getPeriods',
    }),
    month: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    selectYearOptions() {
      return [
        {
          key: 'all',
          value: null,
          label: 'Semua',
        },
        ...getListPastNYear().map((year) => ({
          key: year,
          value: year,
          label: year,
        })),
      ];
    },
    selectMonthOptions() {
      return [
        {
          key: 'all',
          value: null,
          label: 'Semua',
        },
        ...this.getPeriods.data.map((period) => ({
          key: period.attributes.name,
          value: period.id,
          label: period.attributes.name,
        })),
      ];
    },
    selectedMonth() {
      return this.getPeriods.data.find((period) => period.id === this.month);
    },
  },
  methods: {
    ...mapActions({
      fetchPeriods: 'periods/fetchPeriods',
    }),
    handleChangeYear() {
      this.fetchPeriods({
        'filter[year]': this.filter.year,
      });
    },
    handleChangeMonth() {
      this.$emit('change');
    },
  },
};
</script>
