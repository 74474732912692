<template>
  <div>
    <base-modal :showing="visible" @close="$emit('close')">
      <div class="space-y-6">
        <div class="flex items-center justify-between">
          <div>
            <h3 class="text-lg font-bold leading-6 text-gray-900">Retur</h3>
            <p class="mt-1 max-w-2xl text-sm text-gray-500">Detail Retur</p>
          </div>
          <div>
            <img src="@/assets/images/logo.png" alt="" class="w-32" />
          </div>
        </div>
        <div v-if="visible" class="border-t border-gray-200 pt-6">
          <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6">
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Nomor Pengiriman</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ getReturShipment.data.attributes.code }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Nomor Retur</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ getReturShipment.data.attributes.retur_origin_code }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Nama Kantor</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{
                  getSingleIncluded(
                    getReturShipment,
                    getReturShipment.data.relationships['origin-office'].data.id
                  ).attributes.code
                }}
                /
                {{
                  getSingleIncluded(
                    getReturShipment,
                    getReturShipment.data.relationships['origin-office'].data.id
                  ).attributes.name
                }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Tanggal</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ getReturShipment.data.attributes.updatedAt | formatDate }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Total Barang</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ getReturShipment.data.attributes.product_count }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Status</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ getReturShipment.data.attributes.shipment_status }} /
                {{ getReturShipment.data.attributes.receivement_status }}
              </dd>
            </div>
            <div class="sm:col-span-6">
              <dt class="text-sm font-bold text-gray-700">Barang</dt>
              <dd class="mt-1 text-sm text-gray-900">
                <Datatable :paginated="false" :footer="false">
                  <template v-slot:thead>
                    <thead class="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                        >
                          Kode
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                        >
                          Nama
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                        >
                          Jumlah
                        </th>
                        <th
                          v-if="getReturShipment.data.attributes.is_received"
                          scope="col"
                          class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                        ></th>
                      </tr>
                    </thead>
                  </template>
                  <template v-slot:tbody>
                    <tbody>
                      <template
                        v-for="(product, index) in getIncludedByType(
                          getReturShipment,
                          'retur-shipment-details'
                        )"
                      >
                        <tr
                          :key="index"
                          class="bg-white hover:bg-green-100"
                          :class="{
                            'cursor-pointer':
                              getReturShipment.data.attributes.is_received,
                          }"
                          @click="onToggleActiveRow(index)"
                        >
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                          >
                            {{ product.attributes.product_code }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                          >
                            {{ product.attributes.product_name }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                          >
                            {{ product.attributes.product_qty | toCurrency }}
                          </td>
                          <td
                            v-if="getReturShipment.data.attributes.is_received"
                            class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                          >
                            <button>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="mx-auto h-4 w-4"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2"
                                v-if="activeRowIndex === index"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M5 15l7-7 7 7"
                                />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-4 w-4"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2"
                                v-else
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M19 9l-7 7-7-7"
                                />
                              </svg>
                            </button>
                          </td>
                        </tr>
                        <template v-if="activeRowIndex === index">
                          <tr
                            v-if="product.attributes.expired_count"
                            class="bg-gray-50"
                            :key="`detail_kadaluarsa_${index}`"
                          >
                            <td></td>
                            <td
                              class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                            >
                              Kadaluarsa
                            </td>
                            <td
                              class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                            >
                              {{ product.attributes.expired_count }}
                            </td>
                            <td></td>
                          </tr>
                          <tr
                            v-if="product.attributes.damaged_count"
                            class="bg-gray-50"
                            :key="`detail_rusak_${index}`"
                          >
                            <td></td>
                            <td
                              class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                            >
                              Rusak
                            </td>
                            <td
                              class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                            >
                              {{ product.attributes.damaged_count }}
                            </td>
                            <td></td>
                          </tr>
                          <tr
                            v-if="product.attributes.suitable_for_sale_count"
                            class="bg-gray-50"
                            :key="`detail_layak_jual_${index}`"
                          >
                            <td></td>
                            <td
                              class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                            >
                              Layak Jual
                            </td>
                            <td
                              class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                            >
                              {{ product.attributes.suitable_for_sale_count }}
                            </td>
                            <td></td>
                          </tr>
                          <tr
                            v-if="product.attributes.unsuitable_for_sale_count"
                            class="bg-gray-50"
                            :key="`detail_tidak_layak_jual_${index}`"
                          >
                            <td></td>
                            <td
                              class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                            >
                              Tidak Layak Jual
                            </td>
                            <td
                              class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                            >
                              {{ product.attributes.unsuitable_for_sale_count }}
                            </td>
                            <td></td>
                          </tr>
                        </template>
                      </template>
                    </tbody>
                  </template>
                </Datatable>
              </dd>
            </div>
          </dl>
        </div>
        <div class="flex justify-end gap-x-2">
          <base-button v-if="canShip" @click="onShip" type="button">
            Kirim
          </base-button>
          <base-button v-if="canReceive" @click="onReceive" type="button">
            Terima
          </base-button>
        </div>
      </div>
    </base-modal>

    <retur-shipment-classify-modal
      :visible="receivementModal.visible"
      @close="receivementModal.visible = false"
      @success="onReceived"
    />
  </div>
</template>

<script>
import BaseModal from '@/components/base/BaseModal.vue';
import ReturShipmentClassifyModal from './retur-shipment-classify-modal.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    BaseModal,
    ReturShipmentClassifyModal,
  },
  props: {
    visible: Boolean,
  },
  emits: ['close', 'received', 'shipped'],
  data() {
    return {
      receivementModal: {
        visible: false,
      },
      activeRowIndex: null,
    };
  },
  computed: {
    ...mapGetters({
      getReturShipment: 'retur_shipments/getReturShipment',
      me: 'auth/getUser',
    }),
    canShip() {
      if (
        this.me.office_id !==
        this.getReturShipment.data.relationships['origin-office']?.data?.id
      ) {
        return false;
      }

      return !this.getReturShipment.data.attributes.is_shipped;
    },
    canReceive() {
      if (
        this.me.office_id !==
        this.getReturShipment.data.relationships['destination-office']?.data?.id
      ) {
        return false;
      }

      return !this.getReturShipment.data.attributes.is_received;
    },
  },
  watch: {
    visible() {
      this.activeRowIndex = null;
    },
  },
  methods: {
    ...mapActions({
      shipReturShipment: 'retur_shipments/shipReturShipment',
    }),
    async onShip() {
      const res = await this.shipReturShipment({
        id: this.getReturShipment.data.id,
      });

      if (res) {
        this.$emit('shipped');
      }
    },
    async onReceive() {
      this.receivementModal.visible = true;
    },
    async onReceived() {
      this.receivementModal.visible = false;

      this.$emit('received');
    },
    onToggleActiveRow(index) {
      if (!this.getReturShipment.data.attributes.is_received) {
        return;
      }

      if (index === this.activeRowIndex) {
        this.activeRowIndex = null;
      } else {
        this.activeRowIndex = index;
      }
    },
  },
};
</script>
