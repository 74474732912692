<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div class="mb-5 rounded-lg py-4">
      <div class="flex flex-col">
        <div class="flex items-center justify-end space-x-2">
          <base-select
            v-model="is_packed"
            :expand="false"
            @change="filterByPacked"
          >
            <option :value="null">Semua</option>
            <option :value="true">Selesai</option>
            <option :value="false">Belum Selesai</option>
          </base-select>
          <base-search
            v-model="search"
            @input="debounceSearch"
            placeholder="Cari kode atau nama"
          />
        </div>
        <div class="flex my-2 sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <Datatable
              :total="getOrders.meta.page.total"
              :perPage="getOrders.meta.page.perPage"
              :currentPage="getOrders.meta.page.currentPage"
              @pagechanged="onPageChangeProducts"
            >
              <template v-slot:thead>
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      No. Penjualan
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Susulan
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Kode Gudang
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Status Susulan
                    </th>
                  </tr>
                </thead>
              </template>
              <template v-slot:tbody>
                <div v-if="isLoading" class="flex items-center text-center">
                  <loading></loading>
                </div>
                <tbody v-if="getOrders.data?.length > 0">
                  <tr
                    class="cursor-pointer bg-white hover:bg-green-100"
                    v-for="(data, dataIdx) in getOrders.data"
                    :key="data.id"
                    :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                    @click="redirectToDetail(data)"
                  >
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
                    >
                      <div class="text-sm font-bold text-gray-900">
                        {{ data.attributes.origin_code }} /
                        {{ data.attributes.destination_code }}
                      </div>
                      <div class="text-xs font-light text-gray-300">
                        {{ dayjs(data.attributes.updatedAt).format('ll LT') }}
                      </div>
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ data.attributes.item_count }}
                      Produk
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ data.attributes.origin_warehouse_code }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500"
                    >
                      <span
                        class="inline-flex items-center rounded-full bg-indigo-100 px-3 py-1 text-sm font-medium text-indigo-800"
                      >
                        {{ data.attributes.warehouse_status }}
                      </span>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr class="bg-white">
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                      colspan="8"
                    >
                      Data tidak ditemukan
                    </td>
                  </tr>
                </tbody>
              </template>
            </Datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import Datatable from '@/components/base/Datatable';
import { StorageService } from '@/services/storage.service';
import dayjs from 'dayjs';
import BaseSearch from '@/components/base/Search.vue';

export default {
  name: 'Users',
  components: {
    Datatable,
    BaseSearch,
  },
  data() {
    return {
      search: null,
      is_packed: false,
      page: {
        pageNumber: 1,
        pageSize: 5,
      },
    };
  },
  created() {
    this.fetchOrders({
      ...this.fetchOrderParams,
    });
  },
  computed: {
    ...mapGetters({
      getOrders: 'orders/getOrders',
      isLoading: 'orders/getLoading',
    }),
    fetchOrderParams: function () {
      return {
        pageNumber: this.page.pageNumber,
        pageSize: this.page.pageSize,
        origin_warehouse_id: StorageService.getUser().current_warehouse,
        destination_office_category: 'Stockist',
        is_pending_follow_up_delivery: true,
        is_valid_for_shipment: false,
        has_stock_movement: true,
        is_packed: this.is_packed,
        search: this.search,
        include:
          'destination-office,order-details,destination-warehouse,origin-warehouse,payments',
        fields: {
          orders:
            'item_count,origin_code,destination_code,origin_warehouse_code,warehouse_status,updatedAt',
        },
      };
    },
  },
  methods: {
    ...mapActions({
      fetchOrders: 'orders/fetchOrders',
    }),
    dayjs,
    debounceSearch: debounce(function () {
      this.fetchOrders({
        ...this.fetchOrderParams,
      });
    }, 300),
    filterByPacked() {
      this.fetchOrders({
        ...this.fetchOrderParams,
      });
    },
    onPageChangeProducts(page) {
      this.page.pageNumber = page;
      this.fetchOrders({
        ...this.fetchOrderParams,
      });
    },
    redirectToDetail(data) {
      this.$router.push(`sc/${data.id}`);
    },
    getRelationships(resource, id) {
      let data = resource.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
  },
};
</script>
