<template>
  <BaseModal
    :showing="visible"
    @close="onClose"
    :showClose="visible"
    :backgroundClose="true"
    size="max-w-6xl"
  >
    <div class="space-y-6">
      <div class="flex items-center justify-between">
        <div>
          <h3 class="text-lg font-bold leading-6 text-gray-900">
            Detail Stock
          </h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">
            Berdasarkan data stock yang telah dibuat
          </p>
        </div>
        <div>
          <img src="@/assets/images/logo.png" alt="" class="w-32" />
        </div>
      </div>
      <div class="border-t border-gray-200 pt-6" v-if="visible">
        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6">
          <slot name="attribute" :product="getStock">
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Kode Barang</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ getStock.data.attributes.product_code }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Nama Barang</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ getStock.data.attributes.product_name }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Stock</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ getStock.data.attributes.stock_qty }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Stock Kedaluwarsa</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ getStock.data.attributes.expired_count }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Stock Rusak</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ getStock.data.attributes.damaged_count }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">
                Stock Layak Dijual
              </dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ getStock.data.attributes.suitable_for_sale_count }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">
                Stock Tidak Layak Dijual
              </dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ getStock.data.attributes.unsuitable_for_sale_count }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Stock Reimburse</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ getStock.data.attributes.reimburse_count }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Stock Free</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ getStock.data.attributes.free_qty }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Total Berat</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ getStock.data.attributes.total_weight }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Status Order</dt>
              <dd class="mt-1 text-sm text-gray-900">
                <base-badge
                  :color="
                    getStock.data.attributes.is_orderable ? 'blue' : 'yellow'
                  "
                >
                  {{
                    getStock.data.attributes.is_orderable ? 'Open' : 'Close'
                  }}</base-badge
                >
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Status Pre Order</dt>
              <dd class="mt-1 text-sm text-gray-900">
                <base-badge
                  :color="
                    getStock.data.attributes.is_pre_orderable
                      ? 'blue'
                      : 'yellow'
                  "
                >
                  {{
                    getStock.data.attributes.is_pre_orderable ? 'Open' : 'Close'
                  }}</base-badge
                >
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Status Izin</dt>
              <dd class="mt-1 text-sm text-gray-900">
                <base-badge
                  :color="
                    getStock.data.attributes.license_status ? 'blue' : 'yellow'
                  "
                >
                  {{
                    getStock.data.attributes.license_status
                      ? 'Sudah Berizin'
                      : 'Belum Berizin'
                  }}</base-badge
                >
              </dd>
            </div>
            <div class="sm:col-span-2">
              <template v-if="editNotes">
                <dt class="text-sm font-bold text-gray-700"></dt>
                <dd class="mt-1 text-sm text-gray-900">
                  <base-input
                    label="Tanggal Kedaluwarsa"
                    type="date"
                    inset
                    with-label
                    fullwidth
                    :value="getStock.data.attributes.expired_at"
                    @change="onChangeNote"
                    v-if="editNotes"
                  />
                </dd>
              </template>
              <template v-else>
                <dt class="text-sm font-bold text-gray-700">
                  Tanggal Kedaluwarsa
                </dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ getStock.data.attributes.expired_at ?? '-' }}
                </dd>
              </template>
            </div>
          </slot>
          <div class="sm:col-span-6">
            <stock-history-table
              v-if="withStockHistory"
              @change-page="onStockTrxesChangePage"
              @change-period="onChangePeriod"
            />
          </div>
        </dl>
      </div>
      <div class="flex justify-end gap-x-2">
        <base-button @click="onClose" type="button" color="white">
          Tutup
        </base-button>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseModal from '@/components/base/BaseModal.vue';
import BaseBadge from '@/components/base/BaseBadge.vue';
import BaseInput from '@/components/base/BaseInput.vue';
import StockHistoryTable from './StockHistoryTable.vue';

export default {
  name: 'StockDetailModal',
  components: { BaseModal, BaseBadge, BaseInput, StockHistoryTable },
  props: {
    withStockHistory: {
      default: true,
      type: Boolean,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    editNotes: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      stock: {
        attributes: {
          expired_at: '',
        },
      },
    };
  },
  emits: ['close', 'change-page-stock-trx'],
  computed: {
    ...mapGetters({
      getStock: 'stocks/getStock',
      getStocks: 'stocks/getStocks',
      getStockTrxes: 'stock_trxes/getStockTrxes',
    }),
  },
  methods: {
    ...mapActions({
      updateStockById: 'stocks/updateStockById',
      fetchPeriods: 'periods/fetchPeriods',
    }),
    onChangeNote(e) {
      this.updateStockById({
        id: this.getStock.data.id,
        payload: {
          data: {
            id: this.getStock.data.id,
            type: 'stocks',
            attributes: {
              expired_at: e.target.value,
            },
          },
        },
      });
    },
    onChangePeriod(periodUuid) {
      this.$emit('change-period', periodUuid);
    },
    onClose() {
      this.$emit('close');
    },
    onStockTrxesChangePage(page) {
      this.$emit('change-page-stock-trx', page);
    },
  },
  created() {
    this.fetchPeriods({
      'filter[year]': new Date().getFullYear(),
    });
  },
};
</script>
