export const loadingMixin = {
    data() {
        return {
            loading: {
                visible: false,
                text: null
            }
        }
    },
    methods: {
        startLoading(text) {
            this.loading.text = text
            this.loading.visible = true
        },
        stopLoading() {
            this.loading.text = null
            this.loading.visible = false
        }
    }
}