<template>
  <div class="mb-5 rounded-lg bg-white py-4">
    <div class="flex flex-col" v-if="getOrders?.data">
      <div class="flex items-center justify-end space-x-2">
        <div class="relative mt-1 rounded-md shadow-sm">
          <div>
            <select
              v-model="print_invoice_enabled"
              @change="filterByShipped"
              class="focus:outline-none mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-green-500 focus:ring-green-500 sm:text-sm"
            >
              <option :value="null">Semua</option>
              <option :value="false">Persiapan</option>
              <option :value="true">Diproses Gudang</option>
            </select>
          </div>
        </div>
        <base-search
          placeholder="Cari Kode"
          v-model="search"
          @input="debounceSearch"
        />
      </div>
      <div class="my-2 flex sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <Datatable
            :total="getOrders.meta.page.total"
            :perPage="getOrders.meta.page.perPage"
            :currentPage="getOrders.meta.page.currentPage"
            @pagechanged="onPageChangeOrder"
            :paginated="false"
            :is-empty="!getOrders.data.length"
          >
            <template v-slot:thead>
              <thead class="bg-gray-50">
                <tr>
                  <th
                    v-for="item in [
                      {
                        label: 'No. Faktur',
                      },
                      {
                        label: 'Stockist',
                      },
                      {
                        label: 'Nama Pemilik',
                      },
                      {
                        label: 'Kode Gudang',
                      },
                      {
                        label: 'Wilayah Harga',
                      },
                      {
                        label: 'Saldo Stockist',
                        classes: 'text-right',
                      },
                      {
                        label: 'Total Penjualan',
                        classes: 'text-right',
                      },
                      {
                        label: 'Status Pengeluaran',
                      },
                    ]"
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    :key="item.label"
                  >
                    {{ item.label }}
                  </th>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <div v-if="isLoading" class="flex items-center text-center">
                <loading></loading>
              </div>
              <tbody v-if="getOrders?.data.length">
                <tr
                  class="cursor-pointer bg-white hover:bg-green-100"
                  v-for="(data, dataIdx) in getOrders.data"
                  :key="data.id"
                  :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                  @click="redirectToDetail(data)"
                >
                  <td
                    class="whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
                  >
                    {{ data.attributes.origin_code }}
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    {{
                      getRelationships(
                        data.relationships['destination-office'].data.id
                      ).attributes.code
                    }}
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    {{
                      getRelationships(
                        data.relationships['destination-office'].data.id
                      ).attributes.name
                    }}
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    {{ data.attributes.origin_warehouse_code }}
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    {{ data.attributes.area_name }}
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    -
                  </td>
                  <td
                    class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                  >
                    {{ data.attributes.final_price | toCurrency }}
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    <span
                      class="inline-flex items-center rounded-full bg-indigo-100 px-3 py-1 text-sm font-medium text-indigo-800"
                    >
                      {{ data.attributes.warehouse_status }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </template>
          </Datatable>
        </div>
      </div>
      <transition
        name="customFade"
        enter-active-class="fadeIn"
        leave-active-class="fadeOut"
      >
        <div
          v-if="openDetail"
          style="animation-duration: 0.3s"
          class="fixed inset-0 z-10 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div
            class="mx-4 flex items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
          >
            <div
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              class="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
              >&#8203;</span
            >
            <div
              class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl sm:p-6 sm:align-middle"
            >
              <div class="mb-8 flex justify-between">
                <div>
                  <h3 class="text-lg font-bold leading-6 text-gray-900">
                    Detail Penjualan
                  </h3>
                  <p class="mt-1 max-w-2xl text-sm text-gray-500">
                    Data penjualan yang telah dibuat
                  </p>
                </div>
                <div>
                  <img
                    src="@/assets/images/logo.png"
                    alt=""
                    class="flex justify-end"
                  />
                </div>
              </div>
              <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                <div class="flex w-full flex-col items-end py-2">
                  <span
                    v-if="!!dataPenjualan.attributes.is_packed"
                    class="inline-flex w-24 items-center justify-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
                  >
                    Selesai
                  </span>
                  <span
                    v-else
                    class="inline-flex w-36 items-center justify-center rounded-full bg-red-100 px-3 py-1 text-center text-sm font-medium text-red-800"
                  >
                    Belum Selesai
                  </span>
                </div>
                <dl class="flex flex-row gap-x-4">
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">No Invoice</dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{ dataPenjualan.attributes.origin_code }}
                    </dd>
                  </div>
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">Tanggal</dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{
                        dayjs(dataPenjualan.attributes.updatedAt).format(
                          'll LT'
                        )
                      }}
                    </dd>
                  </div>
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">Stockist</dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{
                        dataPenjualan.relationships['destination-office'].data
                          ? getRelationships(
                              dataPenjualan.relationships['destination-office']
                                .data.id
                            ).attributes.code
                          : '-'
                      }}
                    </dd>
                  </div>
                </dl>
                <dl class="mt-6 flex flex-row gap-x-4">
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">
                      Nama Stockist
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{
                        dataPenjualan.relationships['destination-office'].data
                          ? getRelationships(
                              dataPenjualan.relationships['destination-office']
                                .data.id
                            ).attributes.name
                          : '-'
                      }}
                    </dd>
                  </div>
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">
                      Alamat Stockist 1
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{
                        dataPenjualan.relationships['destination-office'].data
                          ? getRelationships(
                              dataPenjualan.relationships['destination-office']
                                .data.id
                            ).attributes.address
                          : '-'
                      }}
                    </dd>
                  </div>
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">
                      Alamat Stockist 2
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{
                        dataPenjualan.relationships['destination-office'].data
                          ? getRelationships(
                              dataPenjualan.relationships['destination-office']
                                .data.id
                            ).attributes.address2
                          : '-'
                      }}
                    </dd>
                  </div>
                </dl>
                <div class="my-6 sm:col-span-6">
                  <dt class="text-sm font-bold text-gray-700">Daftar Barang</dt>
                  <Datatable :paginated="false" :footer="false" class="mt-4">
                    <template v-slot:thead>
                      <thead class="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            KODE BARANG
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 pr-12 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            JUMLAH
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 pr-12 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            BERAT
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 pr-12 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            TOTAL HARGA
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 pr-12 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            TOTAL BERAT
                          </th>
                        </tr>
                      </thead>
                    </template>
                    <template v-slot:tbody>
                      <tbody>
                        <tr
                          class="bg-white"
                          v-for="(barang, index) in dataPenjualan.attributes
                            .order_details"
                          :key="index"
                        >
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                          >
                            {{ barang.product_code }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
                          >
                            {{ barang.product_qty }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
                          >
                            {{ barang.product_weight }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
                          >
                            {{ barang.total_price | toCurrency }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
                          >
                            {{ barang.total_weight }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </Datatable>
                </div>
                <div class="my-6 sm:col-span-6">
                  <dt class="text-sm font-bold text-gray-700">
                    Riwayat Faktur
                  </dt>
                  <Datatable :paginated="false" :footer="false" class="mt-4">
                    <template v-slot:thead>
                      <thead class="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            CODE
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            TANGGAL
                          </th>
                        </tr>
                      </thead>
                    </template>
                    <template v-slot:tbody>
                      <tbody
                        v-if="
                          dataPenjualan.relationships['stock-movements'].data
                            .length > 0
                        "
                      >
                        <tr
                          class="bg-white"
                          v-for="(invoice, index) in dataPenjualan
                            .relationships['stock-movements'].data"
                          :key="index"
                        >
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                          >
                            {{ getRelationships(invoice.id).attributes.code }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                          >
                            {{
                              dayjs(
                                getRelationships(invoice.id).attributes
                                  .createdAt
                              ).format('ll LT')
                            }}
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr class="bg-white">
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                            colspan="2"
                          >
                            Belum ada riwayat faktur
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </Datatable>
                </div>
                <div class="flex justify-end">
                  <div class="flex w-1/3 flex-col items-end gap-y-2">
                    <div class="inline-flex w-full justify-between">
                      <div class="sm:col-span-1">
                        <label
                          for="invoice"
                          class="block text-sm font-medium text-gray-700"
                        >
                          Total harga keseluruhan
                        </label>
                      </div>
                      <div class="sm:col-span-1">
                        <label
                          for="invoice"
                          class="block text-sm font-bold text-gray-900"
                        >
                          {{
                            dataPenjualan.attributes.final_price | toCurrency
                          }}
                        </label>
                      </div>
                    </div>
                    <div class="inline-flex w-full justify-between">
                      <div class="sm:col-span-1">
                        <label
                          for="invoice"
                          class="block text-sm font-medium text-gray-700"
                        >
                          Total berat keseluruhan
                        </label>
                      </div>
                      <div class="sm:col-span-1">
                        <label
                          for="invoice"
                          class="block text-sm font-bold text-gray-900"
                        >
                          {{ dataPenjualan.attributes.grand_total_weight }}
                          Kg
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-6 flex justify-between gap-x-2">
                  <div class="flex">
                    <button
                      v-if="!dataPenjualan.attributes.is_shipped"
                      @click="openConfirmation()"
                      type="button"
                      class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Batalkan Faktur
                    </button>
                  </div>
                  <div class="flex gap-x-2">
                    <router-link
                      v-if="!dataPenjualan.attributes.has_warehouse_receipt"
                      :to="'barang-ke-Stockist/' + dataPenjualan.id"
                      class="focus:outline-none inline-flex items-center rounded-md border border-transparent bg-green-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="mr-2 h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"
                        />
                      </svg>
                      Buat Faktur
                    </router-link>
                    <button
                      v-else-if="
                        dataPenjualan.attributes.has_warehouse_receipt &&
                        !dataPenjualan.attributes.is_shipped
                      "
                      @click="sendOrder(dataPenjualan)"
                      class="focus:outline-none inline-flex items-center rounded-md border border-transparent bg-green-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="mr-2 h-6 w-6"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M8.965 18a3.5 3.5 0 0 1-6.93 0H1V6a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2h3l3 4.056V18h-2.035a3.5 3.5 0 0 1-6.93 0h-5.07zM15 7H3v8.05a3.5 3.5 0 0 1 5.663.95h5.674c.168-.353.393-.674.663-.95V7zm2 6h4v-.285L18.992 10H17v3zm.5 6a1.5 1.5 0 1 0 0-3.001 1.5 1.5 0 0 0 0 3.001zM7 17.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0z"
                        />
                      </svg>
                      Kirim
                    </button>
                    <button
                      @click="openDetail = !openDetail"
                      type="button"
                      class="bg-white-600 rounded-md border border-gray-300 py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-white hover:shadow-lg"
                    >
                      Tutup
                    </button>
                  </div>
                </div>
              </div>
              <transition
                name="customFade"
                enter-active-class="fadeIn"
                leave-active-class="fadeOut"
              >
                <div
                  v-if="cancelConfirmation"
                  style="animation-duration: 0.3s"
                  class="fixed inset-0 z-10"
                  aria-labelledby="modal-title"
                  role="dialog"
                  aria-modal="true"
                >
                  <div
                    class="mx-4 flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
                  >
                    <div
                      class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                      aria-hidden="true"
                    ></div>
                    <span
                      class="hidden sm:inline-block sm:h-screen sm:align-middle"
                      aria-hidden="true"
                      >&#8203;</span
                    >
                    <div
                      class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"
                    >
                      <div>
                        <div
                          class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100"
                        >
                          <!-- Heroicon name: outline/check -->
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-6 w-6 text-red-600"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </div>
                        <div class="mt-3 text-center sm:mt-5">
                          <h3
                            class="text-lg font-medium leading-6 text-gray-900"
                            id="modal-title"
                          >
                            Batalkan faktur pembayaran
                          </h3>
                          <div class="mt-2">
                            <p class="text-sm text-gray-500">
                              Sudah ada faktur pembayaran. Anda yakin ingin
                              membatalkan?
                            </p>
                          </div>
                        </div>
                        <div class="pt-4">
                          <label
                            for="comment"
                            class="block text-sm font-medium text-gray-700"
                            >Alasan pembatalan</label
                          >
                          <div class="mt-1">
                            <textarea
                              rows="4"
                              name="comment"
                              id="comment"
                              class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div class="mt-5 sm:mt-6 sm:flex sm:flex-row sm:gap-x-3">
                        <button
                          type="button"
                          @click="cancelOrder(dataPenjualan)"
                          class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                        >
                          Ya
                        </button>
                        <button
                          @click="cancelConfirmation = !cancelConfirmation"
                          type="button"
                          class="focus:outline-none mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                        >
                          Tutup
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import { debounce } from 'debounce';
import { mapGetters, mapActions } from 'vuex';
import { StorageService } from '@/services/storage.service';
import Datatable from '@/components/base/Datatable';
import dayjs from 'dayjs';
import BaseSearch from '@/components/base/Search.vue';

export default {
  name: 'Home',
  components: {
    Datatable,
    BaseSearch,
  },

  data() {
    return {
      search: null,
      openDetail: false,
      is_packed: false,
      print_invoice_enabled: null,
      dataPenjualan: {},
      dataBrgMasuk: {},
      detailBrgMasuk: {},
      cancelConfirmation: false,
    };
  },

  computed: {
    ...mapGetters({
      getStockMovements: 'stock_movements/getStockMovements',
      getOrders: 'orders/getOrders',
      isLoading: 'orders/getLoading',
    }),
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchStockMovements: 'stock_movements/fetchStockMovements',
      updateStockMovement: 'stock_movements/updateStockMovement',
      fetchOrders: 'orders/fetchOrders',
      updateOrder: 'orders/updateOrder',
    }),
    debounceSearch: debounce(function () {
      this.fetchOrders({
        origin_warehouse_id: StorageService.getUser().current_warehouse,
        pageNumber: 1,
        pageSize: 5,
        is_valid_for_packing: true,
        is_valid_for_shipment: false,
        has_follow_up_delivery: false,
        print_invoice_enabled: this.print_invoice_enabled,
        is_packed: this.is_packed,
        destination_office_category: 'Stockist',
        search: this.search,
        ascending: true,
      });
    }, 300),
    onPageChangeOrder(page) {
      this.fetchOrders({
        origin_warehouse_id: StorageService.getUser().current_warehouse,
        pageNumber: page,
        pageSize: 5,
        is_valid_for_packing: true,
        is_valid_for_shipment: false,
        has_follow_up_delivery: false,
        print_invoice_enabled: this.print_invoice_enabled,
        is_packed: this.is_packed,
        destination_office_category: 'Stockist',
        search: this.search,
        ascending: true,
      });
    },
    filterByShipped() {
      this.fetchOrders({
        origin_warehouse_id: StorageService.getUser().current_warehouse,
        pageNumber: 1,
        pageSize: 5,
        is_valid_for_packing: true,
        is_valid_for_shipment: false,
        has_follow_up_delivery: false,
        print_invoice_enabled: this.print_invoice_enabled,
        is_packed: this.is_packed,
        destination_office_category: 'Stockist',
        search: this.search,
        ascending: true,
      });
    },
    async cancelOrder(data) {
      if (data.attributes.has_warehouse_receipt) {
        await this.dataPenjualan.relationships['stock-movements'].data.map(
          (mov) => {
            const payloadDelete = {
              data: {
                type: 'stock-movements',
                id: mov.id,
                attributes: {
                  deletedAt: new Date(),
                },
              },
            };
            this.updateStockMovement(payloadDelete);
          }
        );
        const payload = {
          data: {
            type: 'orders',
            id: data.id,
            attributes: {
              is_valid_for_packing: false,
              has_warehouse_receipt: false,
            },
          },
        };
        await this.updateOrder(payload).then((response) => {
          if (response) {
            this.openDetail = false;
            this.fetchOrders({
              origin_warehouse_id: StorageService.getUser().current_warehouse,
              pageNumber: 1,
              pageSize: 5,
              is_valid_for_packing: true,
              is_valid_for_shipment: false,
              has_follow_up_delivery: false,
              print_invoice_enabled: this.print_invoice_enabled,
              is_packed: this.is_packed,
              destination_office_category: 'Stockist',
              ascending: true,
            });
          }
        });
      } else {
        const payload = {
          data: {
            type: 'orders',
            id: data.id,
            attributes: {
              is_valid_for_packing: false,
            },
          },
        };
        this.updateOrder(payload).then((response) => {
          if (response) {
            this.openDetail = false;
            this.fetchOrders({
              origin_warehouse_id: StorageService.getUser().current_warehouse,
              pageNumber: 1,
              pageSize: 5,
              is_valid_for_packing: true,
              is_valid_for_shipment: false,
              has_follow_up_delivery: false,
              print_invoice_enabled: this.print_invoice_enabled,
              is_packed: this.is_packed,
              destination_office_category: 'Stockist',
              ascending: true,
            });
          }
        });
      }
    },
    tableDetails(data) {
      this.openDetail = !this.openDetail;
      this.dataPenjualan = data;
    },
    getRelationships(id) {
      let data = this.getOrders.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    redirectToDetail(data) {
      this.$router.push(
        `pengeluaran/barang-ke-stockist/${data.attributes.order_id}`
      );
    },
    sendOrder(data) {
      const payload = {
        data: {
          type: 'stock-movements',
          id: data.relationships['stock-movements'].data[0].id,
          attributes: {
            is_shipped: true,
          },
        },
      };
      this.updateStockMovement(payload).then((response) => {
        if (response) {
          this.is_packed = true;
          this.fetchOrders({
            origin_warehouse_id: StorageService.getUser().current_warehouse,
            pageNumber: 1,
            pageSize: 5,
            is_valid_for_packing: true,
            is_valid_for_shipment: false,
            has_follow_up_delivery: false,
            print_invoice_enabled: this.print_invoice_enabled,
            is_packed: this.is_packed,
            destination_office_category: 'Stockist',
            ascending: true,
          });
        }
        this.openDetail = false;
      });
    },
    openConfirmation() {
      this.cancelConfirmation = !this.cancelConfirmation;
    },
  },
  created() {
    this.fetchOrders({
      origin_warehouse_id: StorageService.getUser().current_warehouse,
      pageNumber: 1,
      pageSize: 5,
      is_valid_for_packing: true,
      is_valid_for_shipment: false,
      has_follow_up_delivery: false,
      print_invoice_enabled: this.print_invoice_enabled,
      is_packed: this.is_packed,
      destination_office_category: 'Stockist',
      ascending: true,
    });
    const handleEscape = () => {
      this.openDetail = false;
    };
    this.setEscape(handleEscape);
  },
};
</script>
