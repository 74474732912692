<template>
  <BaseModal
    :showing="visible"
    @close="onClose"
    :showClose="visible"
    :backgroundClose="true"
    size="max-w-6xl"
  >
    <div class="flex justify-between px-4 py-5 sm:px-6">
      <div>
        <h3 class="text-lg font-bold leading-6 text-gray-900">Daftar Produk</h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">
          Data barang berdasarkan supplier yang dipilih
        </p>
      </div>
      <div>
        <img src="@/assets/images/logo.png" alt="" class="w-52" />
      </div>
    </div>
    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
      <div class="flex justify-end">
        <base-search
          v-model="productKeyword"
          @input="debounceSearchProduct"
          placeholder="Cari kode atau nama"
        />
      </div>
      <div class="my-2 flex sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <Datatable
            :total="getProductsByOffice.meta.page.total"
            :perPage="getProductsByOffice.meta.page.perPage"
            :currentPage="getProductsByOffice.meta.page.currentPage"
            @pagechanged="onPageChangeProducts"
          >
            <template v-slot:thead>
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Kode Barang
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Nama Barang
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Kelompok Produk
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Unit
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Harga Supplier
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Harga Supplier Promo
                  </th>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody v-if="getProductsByOffice?.data?.length > 0">
                <tr
                  class="cursor-pointer bg-white hover:bg-green-100"
                  v-for="(data, dataIdx) in getProductsByOffice.data"
                  :key="data.id"
                  :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                  @click="changeProduct(data)"
                >
                  <td
                    class="whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
                  >
                    {{ data.attributes.code }}
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    {{ data.attributes.name }}
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    {{
                      getRelationships(
                        getProductsByOffice,
                        data.relationships['product-category'].data?.id
                      )?.attributes.name
                    }}
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    {{
                      getRelationships(
                        getProductsByOffice,
                        data.relationships['unit'].data?.id
                      )?.attributes.name
                    }}
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    {{ data.attributes.supplier_price | toCurrency }}
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    {{ data.attributes.supplier_free_price | toCurrency }}
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr class="bg-white">
                  <td
                    class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                    colspan="6"
                  >
                    Data tidak ditemukan
                  </td>
                </tr>
              </tbody>
            </template>
          </Datatable>
        </div>
      </div>
    </div>
    <div class="flex justify-end">
      <base-button @click="onClose" type="button"> Tutup </base-button>
    </div>
  </BaseModal>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseModal from '@/components/base/BaseModal';
import BaseSearch from '@/components/base/Search.vue';
import debounce from 'debounce';

export default {
  name: 'ProductModal',
  props: {
    visible: {
      type: Boolean,
    },
  },
  data() {
    return {
      productKeyword: '',
    };
  },
  computed: {
    ...mapGetters({
      getProductsByOffice: 'products/getProductsByOffice',
    }),
  },
  components: {
    BaseModal,
    BaseSearch,
  },
  methods: {
    onPageChangeProducts() {},
    getRelationships(resource, id) {
      let data = resource.included?.filter(function (el) {
        return el.id === id;
      });
      return data?.[0];
    },
    debounceSearchProduct: debounce(function () {
      this.fetchProductsByOffice({
        include: 'product-limits,unit,product-category',
        office_id:
          this.order.relationships.destinationOffice.attributes.office_id,
        pageNumber: 1,
        pageSize: 5,
        search: this.productKeyword,
      });
    }, 300),
    changeProduct(val) {
      this.$emit('change', val);
    },
    onClose() {
      this.$emit('close');
    },
  },
  created() {
    // Code to run when the component is created
  },
};
</script>

<style scoped>
/* Your component styles here */
</style>
