export const paymentColumns = [];
export const stockColumns = (
  { withCode } = {
    withCode: true,
  }
) => {
  const columns = [
    {
      label: 'Kode Barang',
    },
    {
      label: 'Nama Barang',
    },
    {
      label: 'Stock',
      classes: 'text-right',
    },
    {
      label: 'Stock Ordered',
      classes: 'text-right',
    },
    {
      label: 'Stock Dikirim',
      classes: 'text-right',
    },
  ];
  withCode
    ? columns.push({
        label: 'Kode Gudang',
      })
    : null;
  return columns;
};

export const ScStockColumns = (
  { withAreaZeroPrice } = { withAreaZeroPrice: false }
) => {
  const columns = [
    {
      label: 'Kode Barang',
    },
    {
      label: 'Nama Barang',
    },
    {
      label: 'P/N',
    },
    withAreaZeroPrice && {
      label: 'HS 0',
      classes: 'text-right',
    },
    {
      label: 'Stock di Gudang',
      classes: 'text-right',
    },
    {
      label: 'Stock Promo',
      classes: 'text-right',
    },
    {
      label: 'Jumlah di Order',
      classes: 'text-right',
    },
    {
      label: 'Jumlah Order Promo',
      classes: 'text-right',
    },
    {
      label: 'Stock Tersedia',
      classes: 'text-right',
    },
    {
      label: 'Stock Tersedia Promo',
      classes: 'text-right',
    },
  ].filter(Boolean);
  return columns;
};
