<template>
  <div class="mx-auto px-4 sm:px-4 lg:px-8">
    <BaseCard title="Penerimaan Barang dari Pusat dan KP">
      <div class="flex items-center justify-end space-x-2">
        <base-select
          :expand="false"
          :options="[
            {
              label: 'Semua',
              value: null,
            },
            {
              label: 'Diterima',
              value: true,
            },
            {
              label: 'Belum diterima',
              value: false,
            },
          ]"
          v-model="filter.isReceived"
          @change="handleFilter"
        />
        <base-search
          v-model="filter.search"
          @input="handleSearch"
          placeholder="Cari kode atau nama"
        />
      </div>
      <div class="mt-2 flex sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <StockMovementTable
            @page-change="handleChangePage"
            @click-row="handleClickItem"
            is-shipped
          />
        </div>
      </div>

      <stock-movement-modal
        :visible="visibleDetail"
        description="Data penerimaan barang dari Pusat"
        :with-dispute="false"
        @close="visibleDetail = false"
        @refresh="handleRefresh"
      >
        <template #origin-office="{ originOffice }">
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">Nomor Pusat</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ originOffice.attributes.code }}
            </dd>
          </div>
        </template>
      </stock-movement-modal>
    </BaseCard>
  </div>
</template>

<script>
import { debounce } from 'debounce';
import { mapGetters, mapActions } from 'vuex';
import dayjs from 'dayjs';
import StockMovementModal from '@/components/stock-movement/StockMovementModal.vue';
import BaseSearch from '@/components/base/Search.vue';
import BaseCard from '@/components/base/BaseCard.vue';
import StockMovementTable from '@/components/stock-movement/stock-movement-table.vue';

export default {
  name: 'Home',
  components: {
    StockMovementModal,
    BaseSearch,
    BaseCard,
    StockMovementTable,
  },

  data() {
    return {
      visibleDetail: false,
      page: {
        number: 1,
        size: 5,
      },
      filter: {
        isReceived: false,
        search: null,
      },
    };
  },

  computed: {
    ...mapGetters({
      getStockMovements: 'stock_movements/getStockMovements',
      isLoading: 'stock_movements/getLoading',
      me: 'auth/getUser',
    }),
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchStockMovements: 'stock_movements/fetchStockMovements',
      fetchStockMovement: 'stock_movements/fetchStockMovement',
    }),
    async handleClickItem(item) {
      const res = await this.fetchStockMovement({
        id: item.attributes.stock_movement_id,
      });
      if (res) {
        this.visibleDetail = true;
      }
    },
    handleChangePage(page) {
      this.loadStockMovements({ resetPage: false, ...page });
    },
    handleSearch: debounce(function () {
      this.handleFilter();
    }, 300),
    handleFilter() {
      this.loadStockMovements();
    },
    handleRefresh() {
      this.resetFilter();
      this.loadStockMovements();

      this.visibleDetail = false;
    },
    resetPage() {
      this.page.size = 5;
      this.page.number = 1;
    },
    resetFilter() {
      this.filter.isReceived = false;
      this.filter.search = null;
    },
    loadStockMovements({ resetPage = true } = {}) {
      if (resetPage) {
        this.resetPage();
      }
      this.fetchStockMovements({
        'filter[origin_office_category_id]': 1,
        'filter[destination_office_category_id]': 3,
        'filter[destination_office_id]': this.me.office_id,
        'filter[is_shipped]': true,
        'filter[is_received]': this.filter.isReceived,
        'filter[search]': this.filter.search,
        'page[limit]': this.page.size,
        'fields[stock-movements]': 'product_summaries,order_code',
        include: 'stock-movement',
      });
    },
  },
  created() {
    this.loadStockMovements();
  },
};
</script>
