var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.handleClickOutside),expression:"handleClickOutside"}],staticClass:"relative"},[_c('base-input',{attrs:{"fullwidth":_vm.fullwidth,"type":"text","placeholder":_vm.placeholder,"disabled":_vm.disabled,"shadow":_vm.shadow},on:{"input":_vm.handleInput,"focus":_vm.handleFocus},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.isLoading)?_c('div',{staticClass:"absolute top-2 right-2 z-20"},[_c('base-spinner')],1):(
      _vm.selectedItem?.attributes ? !!_vm.selectedItem.attributes[_vm.label] : false
    )?_c('button',{staticClass:"absolute top-3 right-3 ml-auto inline-flex items-center rounded-lg bg-transparent p-0.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900",attrs:{"type":"button"},on:{"click":_vm.handleClickClear}},[_c('svg',{staticClass:"h-4 w-4",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor","stroke-width":"2"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M6 18L18 6M6 6l12 12"}})])]):_c('div',{staticClass:"absolute top-3 right-3 ml-auto inline-flex items-center text-gray-400"},[_c('svg',{staticClass:"h-4 w-4",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z","clip-rule":"evenodd"}})])]),_c('div',{ref:"content",staticClass:"absolute top-12 right-0 z-10 w-full divide-y divide-gray-100 overflow-y-auto rounded bg-white shadow",class:{ hidden: !_vm.visible },staticStyle:{"max-height":"200px"}},[(!_vm.data.length)?_c('ul',{staticClass:"py-1 text-sm text-gray-700"},[(_vm.emptyable)?_c('li',{staticClass:"block w-full py-2 px-4 text-left text-sm"},[_vm._v(" Data Kosong ")]):_vm._e(),(_vm.createable)?_c('li',{staticClass:"block w-full py-2 px-4 text-left text-sm hover:bg-gray-100",on:{"click":_vm.creating}},[_c('div',{staticClass:"flex items-center"},[_c('Icon',{staticClass:"h-5 w-5",attrs:{"icon":"heroicons:plus-20-solid"}}),_c('p',[_vm._v("Tambah Data Baru \""+_vm._s(_vm.search)+"\"")])],1)]):_vm._e()]):_c('ul',{staticClass:"py-1 text-sm text-gray-700"},[_vm._l((_vm.data),function(item){return [(typeof item?.attributes[_vm.label] != 'undefined' || _vm.formatter)?_c('li',{key:item[_vm.id],staticClass:"block w-full py-2 px-4 text-left text-sm",class:[
            _vm.isItemActive(item)
              ? 'bg-green-600 text-white'
              : 'hover:bg-gray-100',
          ],on:{"click":function($event){return _vm.handleClickItem(item)}}},[_vm._t("label",function(){return [_vm._v(" "+_vm._s(_vm.formatter ? _vm.formatter(item) : item?.attributes[_vm.label])+" ")]},{"item":item})],2):_vm._e()]}),(_vm.createable)?_c('li',{staticClass:"block w-full py-2 px-4 text-left text-sm hover:bg-gray-100",on:{"click":_vm.creating}},[_c('div',{staticClass:"flex items-center"},[_c('Icon',{staticClass:"h-5 w-5",attrs:{"icon":"heroicons:plus-20-solid"}}),_c('p',[_vm._v("Tambah Data Baru: \""+_vm._s(_vm.search)+"\"")])],1)]):_vm._e()],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }