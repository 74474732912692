<template>
  <div>
    <div class="sm:hidden">
      <select
        id="tabs"
        name="tabs"
        v-model="computedValue"
        class="block w-full rounded-md border-gray-300 focus:border-green-600 focus:ring-green-600"
      >
        <option v-for="(tab, index) in tabsData" :key="index" :value="tab">
          {{ tab }}
        </option>
      </select>
    </div>
    <div class="hidden sm:block">
      <nav
        class="relative z-0 flex overflow-x-auto rounded-t-lg bg-white shadow"
        aria-label="Tabs"
      >
        <button
          v-for="(tab, index) in tabsData"
          :key="index"
          @click="changeTab(tab)"
          type="button"
          class="first:rounded-lt-lg group relative min-w-0 max-w-xs flex-1 overflow-hidden border-r border-gray-200 bg-white py-4 px-4 text-center text-sm font-medium hover:bg-green-50 focus:z-10"
          :class="
            tab === tabActive
              ? 'bg-green-600 text-green-600'
              : 'text-gray-500 hover:bg-green-50'
          "
          aria-current="page"
        >
          <span>{{ tab }}</span>
          <span
            v-if="tab === tabActive"
            aria-hidden="true"
            class="absolute inset-x-0 bottom-0 h-0.5 bg-green-600"
          ></span>
        </button>
      </nav>
    </div>
    <div class="rounded-b-lg bg-white p-8 shadow">
      <slot :tabActive="tabActive" />
      <!-- <template v-for="slotComponent in $slots">
        <slot v-if="tabActive === slotComponent.isActive" :name="slotComponent.name" />
      </template> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabsData: {
      type: Array,
      default: () => [],
    },
    tabActive: {
      type: String,
      default: null,
    },
  },
  emits: ['updateTabActive'],
  methods: {
    changeTab(tab) {
      this.$emit('updateTabActive', tab);
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.tabActive;
      },
      set(value) {
        this.$emit('updateTabActive', value);
      },
    },
  },
};
</script>
