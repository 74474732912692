<template>
  <Modal
    :showing="visible"
    @close="onCloseModal"
    :showClose="true"
    :backgroundClose="true"
    size="max-w-4xl"
  >
    <form @submit.prevent="onSubmit" id="modal-add" class="space-y-5">
      <div>
        <h3 class="text-lg font-semibold leading-6 text-gray-900">
          {{ form.id ? 'Edit Ekspedisi' : 'Tambah Ekspedisi' }}
        </h3>
      </div>
      <div class="space-y-4 border-t border-gray-200 pt-5">
        <div class="flex sm:flex-row sm:gap-x-4">
          <div class="field-inset-default w-full">
            <label
              for="kode_ekspedisi"
              class="block text-xs font-bold text-gray-700"
            >
              Kode Ekspedisi
            </label>
            <input
              v-model="form.attributes.code"
              ref="focusNamaBarang"
              @keypress.enter.prevent
              type="text"
              name="kode_ekspedisi"
              id="kode_ekspedisi"
              class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
              placeholder="Masukkan kode ekspedisi"
            />
          </div>
          <div class="field-inset-default w-full">
            <label
              for="nama_ekspedisi"
              class="block text-xs font-bold text-gray-700"
            >
              Nama Ekspedisi
            </label>
            <input
              v-model="form.attributes.name"
              ref="focusNamaBarang"
              @keypress.enter.prevent
              type="text"
              name="nama_ekspedisi"
              id="nama_ekspedisi"
              class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
              placeholder="Masukkan nama ekspedisi"
            />
          </div>
        </div>

        <div class="flex sm:flex-row sm:gap-x-4">
          <div class="field-inset-default w-full">
            <label
              for="deskripsi_ekspedisi"
              class="block text-xs font-bold text-gray-700"
            >
              Deskripsi Ekspedisi
            </label>
            <input
              v-model="form.attributes.description"
              ref="focusNamaBarang"
              type="text"
              name="deskripsi_ekspedisi"
              id="deskripsi_ekspedisi"
              class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
              placeholder="Masukkan nama ekspedisi"
            />
          </div>
        </div>
        <div class="flex sm:flex-row sm:gap-x-4">
          <div class="field-inset-default w-full">
            <label for="alamat1" class="block text-xs font-bold text-gray-700">
              Alamat 1
            </label>
            <input
              v-model="form.attributes.address_1"
              ref="focusNamaBarang"
              type="text"
              name="alamat1"
              id="alamat1"
              class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
              placeholder="Masukkan alamat 1"
            />
          </div>
        </div>
        <div class="flex sm:flex-row sm:gap-x-4">
          <div class="field-inset-default w-full">
            <label for="alamat2" class="block text-xs font-bold text-gray-700">
              Alamat 2
            </label>
            <input
              v-model="form.attributes.address_2"
              ref="focusNamaBarang"
              type="text"
              name="alamat2"
              id="alamat2"
              class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
              placeholder="Masukkan alamat 1"
            />
          </div>
        </div>
      </div>
      <div class="flex justify-end gap-x-2">
        <button
          type="submit"
          id="simpan_supplier"
          class="inline-flex rounded-md border border-gray-300 bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-600 hover:shadow-lg"
        >
          Simpan
        </button>
        <button
          @click="onClearData"
          id="batal_supplier"
          type="button"
          class="bg-white-600 rounded-md border border-gray-300 py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-white hover:shadow-lg"
        >
          Batal
        </button>
        <button
          @click="onCloseModal"
          id="selesai_supplier"
          class="inline-flex rounded-md border border-gray-300 bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-600 hover:shadow-lg"
        >
          Selesai
        </button>
      </div>
    </form>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Modal from '@/components/base/BaseModal';

export default {
  name: 'OfficeExpeditionForm',
  components: { Modal },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    expedition: null,
  },
  emits: ['close', 'success', 'error'],
  data() {
    return {
      form: {
        id: null,
        attributes: {
          code: null,
          name: null,
          description: null,
          address_1: null,
          address_2: null,
        },
      },
    };
  },
  watch: {
    visible(value) {
      if (value) {
        this.clearForm();

        if (this.expedition) {
          this.setForm();
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/getUser',
    }),
  },
  methods: {
    ...mapActions({
      updateOfficeExpedition: 'office_expeditions/updateOfficeExpedition',
      createOfficeExpedition: 'office_expeditions/createOfficeExpedition',
    }),
    clearForm() {
      this.form.id = null;
      this.form.attributes.code = null;
      this.form.attributes.name = null;
      this.form.attributes.description = null;
      this.form.attributes.address_1 = null;
      this.form.attributes.address_2 = null;
    },
    setForm() {
      this.form.id = this.expedition.id;
      this.form.attributes.code = this.expedition.attributes.code;
      this.form.attributes.name = this.expedition.attributes.name;
      this.form.attributes.description = this.expedition.attributes.description;
      this.form.attributes.address_1 = this.expedition.attributes.address_1;
      this.form.attributes.address_2 = this.expedition.attributes.address_2;
    },
    onCloseModal() {
      this.$emit('close');
    },
    onClearData() {
      this.clearForm();
    },
    async onSubmit() {
      const payload = {
        data: {
          type: 'office-expeditions',
          attributes: {
            code: this.form.attributes.code,
            name: this.form.attributes.name,
            description: this.form.attributes.description,
            address_1: this.form.attributes.address_1,
            address_2: this.form.attributes.address_2,
          },
          relationships: {
            office: {
              data: {
                type: 'offices',
                id: this.getUser.office_id,
              },
            },
          },
        },
      };

      if (this.form.id) {
        payload.data.id = this.form.id;

        await this.updateOfficeExpedition(payload).then((response) => {
          if (response) {
            this.$emit('success');
          } else {
            this.$emit('error');
          }
        });
      } else {
        await this.createOfficeExpedition(payload).then((response) => {
          if (response) {
            this.$emit('success');
          } else {
            this.$emit('error');
          }
        });
      }
    },
  },
};
</script>
