<template>
  <base-card class="space-y-5">
    <div>
      <h3 class="text-lg font-bold leading-6 text-gray-900">
        Promo
      </h3>
      <p class="max-w-4xl text-sm text-gray-500">
        Promo tersedia pada penjualan ini.
      </p>
    </div>
    <div class="grid grid-cols-4 gap-4">
      <div v-for="(bundle, index) in bundles" :key="bundle.id" :class="['rounded-md border border-gray-200 px-4 py-2 text-sm flex', bundle.selected || checkBundleSelectable(bundle) ? 'hover:bg-gray-50' : 'bg-gray-50']">
        <base-checkbox ref="checkbox" :input-value="bundle.id" disabled />
        <div class="mt-1 flex flex-grow justify-between">
          <div class="space-y-1 truncate">
            <p class="font-medium text-gray-900 hover:text-gray-600">{{ bundle.bundle_name }}</p>
            <div>
              <p class="text-xs text-gray-500">Dengan Membeli</p>
              <p v-for="requirement in bundle.requirements" :key="requirement.product_code" class="text-xs text-gray-500">- {{ requirement.min_qty }} Produk {{ requirement.product_code }}</p>
            </div>
            <div>
              <p class="text-xs text-gray-500">Gratis</p>
              <p v-for="bonus in bundle.bonuses" :key="bonus.product_code" class="text-xs text-gray-500">- {{ bonus.bonus_qty }} Produk {{ bonus.product_code }} {{ bundle.bonus_choosen === bonus.product_code ? '(Terpilih)' : '' }}</p>
            </div>
            <p v-if="checkBundleIsQuantity(bundle)" class="text-xs text-gray-500">Dengan Total Minimal {{ bundle.bundle_min_qty }}</p>
            <template v-if="!bundle.selected">
              <template v-if="checkBundleIsQuantity(bundle)">
                <p v-if="!checkBundleSelectable(bundle)" class="text-xs text-red-500">Kurang {{ Math.abs(getBundleRequirementInsufficient(bundle)) }} Produk</p>
              </template>
              <template v-else>
                <p class="text-xs text-red-500" v-for="product in getBundleRequirementInsufficient(bundle)" :key="product.productCode">Kurang {{ product.productCode }} {{ product.neededQty }} Produk</p>
              </template>
              <p v-if="!checkBundleBonusStock(bundle)" class="text-xs text-red-500">Tidak ada stok untuk produk bonus</p>
            </template>
          </div>
          <div>
            <base-dropdown v-if="bundle.selected && checkBundleChooseable(bundle)" width="144px" top="2.5rem">
              <template #toggle="{ toggle }">
                <base-button size="sm" color="white" v-on:click="toggle">Pilih Bonus</base-button>
              </template>

              <template #content>
                <ul class="py-1">
                  <li v-for="(bonus, bonusIndex) in bundle.bonuses" :key="bonus.product_code" class="px-3 py-1">
                    <base-radio :val="bonus.product_code" :label="bonus.product_code" v-model="bundles[index].bonus_choosen" :name="`bundle-${bundle.id}-choosen-bonus`" v-on:change="onChooseBonus(bonus, index, bonusIndex)" />
                  </li>
                </ul>
              </template>
            </base-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div v-if="Object.keys(earnedBonus).length" class="space-y-4">
      <div>
        <h3 class="text-lg font-bold leading-6 text-gray-900">
          Bonus Didapatkan
        </h3>
        <p class="max-w-4xl text-sm text-gray-500">
          Bonus yang didapatkan dari promo.
        </p>
      </div>
      <div class="flex gap-x-2">
        <div v-for="(bonus, code) in earnedBonus" :key="code" class="rounded-md border border-gray-200 truncate px-4 py-2 text-sm bg-gray-50">
          <p class="font-medium text-gray-900 hover:text-gray-600">{{ code }}</p>
          <p class="text-xs text-gray-500">Gratis {{ bonus.usedQty }} Produk</p>
        </div>
      </div>
    </div>
  </base-card>
</template>

<script>
import BaseCard from '@/components/base/BaseCard.vue';
import BaseCheckbox from '@/components/base/BaseCheckbox.vue';
import BaseDropdown from '@/components/base/BaseDropdown.vue';
import BaseRadio from '@/components/base/BaseRadio.vue';

export default {
  components: {
    BaseCard,
    BaseCheckbox,
    BaseDropdown,
    BaseRadio
  },
  props: {
    orderDetails: {
      type: Array,
      required: true
    },
    productBundles: {
      type: Array,
      required: true
    }
  },
  emits: ['change-selected-bundles', 'change-choosen-bonuses'],
  data() {
    return {
      bundles: [],
      products: [],
      bonusStocks: {},
      min: null
    }
  },
  computed: {
    selectedBundes() {
      if (!this.bundles.length) {
        return []
      }

      return this.bundles
        .filter(bundle => bundle.selected)
        .map(bundle => bundle.id)
    },
    earnedBonus() {
      return Object.fromEntries(Object.entries(this.bonusStocks).filter(([, bonus]) => bonus.usedQty))
    },
    choosenBonus() {
      return Object.fromEntries(this.bundles
        .filter(bundle => bundle.bonus_choosen)
        .map(bundle => [bundle.id, bundle.bonus_choosen]))
    }
  },
  watch: {
    productBundles() {
      this.setProducts()
      this.setBundles()
      this.setBonusStocks()
      this.setSelectedBundle()
    },
    bundles: {
      handler: function () {
        this.$emit('change-selected-bundles', this.selectedBundes)
        this.$emit('change-choosen-bonuses', this.choosenBonus)
      },
      deep: true
    }
  },
  methods: {
    setBundles() {
      this.bundles = this.productBundles.map(item => ({
        ...item.attributes,
        id: item.id,
        requirements: item.attributes.product_requirements_summary.map(requirement => ({
          product_code: requirement.product_code,
          product_name: requirement.product_name,
          min_qty: requirement.min_qty,
          received_qty: 0
        })),
        bonuses: item.attributes.product_bonuses_summary.map(bonus => ({
          product_code: bonus.product_code,
          product_name: bonus.product_name,
          bonus_qty: bonus.bonus_qty,
          received_qty: 0
        })),
        selected: false,
        bonus_choosen: null,
        original_bonus_choosen: null,
        multipier: 0
      }))
    },
    setBonusStocks() {
      this.bonusStocks = this.productBundles.reduce((bonusStocks, bundle) => {
        return Object.entries(bundle.attributes.product_bonuses_stock).reduce((bonusStocks, [bonus, stockQty]) => {
          if (Object.hasOwn(bonusStocks, bonus)) {
            return bonusStocks
          }

          return {...bonusStocks, [bonus]: {
            qty: stockQty,
            usedQty: 0
          }}
        }, bonusStocks)
      }, {})
    },
    setProducts() {
      this.products = this.orderDetails.map(item => ({
        ...item,
        usedQty: 0
      }))
    },
    async setSelectedBundle(bundleOptions = {}) {
      this.$nextTick(() => {
        this.bundles.forEach((bundle, index) => {
          if (bundle.selected) {
            this.removeSelectedBundle(this.bundles[index], index)
          }
        })

        this.bundles.forEach((bundle, index) => {
          if (this.checkBundleSelectable(bundle)) {
            this.$refs.checkbox[index].$refs.input.checked = true
            this.selectBundle(this.bundles[index], index, bundleOptions[index]?.bonusIndex ?? 0)
          } else {
            this.$refs.checkbox[index].$refs.input.checked = false
          }
        })
      })
    },
    checkBundleChooseable(bundle) {
      return bundle.bonus_type === 'choose'
    },
    checkBundleSelectable(bundle) {
      if (!this.checkBundleBonusStock(bundle)) {
        return false
      }

      if (this.checkBundleIsQuantity(bundle)) {
        return this.getBundleRequirementInsufficient(bundle) >= 0
      }

      return !this.getBundleRequirementInsufficient(bundle).length
    },
    checkBundleBonusStock(bundle) {
      return bundle.bonuses.every(bonus => Object.hasOwn(this.bonusStocks, bonus.product_code) && this.bonusStocks[bonus.product_code].qty - this.bonusStocks[bonus.product_code].usedQty > 0)
    },
    checkBundleIsQuantity(bundle) {
      return bundle.using_min_qty
    },
    findProduct(code) {
      return this.products.find(product => product.productCode === code)
    },
    getBundleRequirementInsufficient(bundle) {
      if (this.checkBundleIsQuantity(bundle)) {
        const bundleBonusStockRemainder = bundle.requirements.reduce((total, requirement) => {
          const product = this.findProduct(requirement.product_code)

          return total + (product.qty - product.usedQty)
        }, 0)

        return bundleBonusStockRemainder - bundle.bundle_min_qty
      }

      return bundle.requirements
        .filter(requirement => {
          const product = this.findProduct(requirement.product_code)

          return (product.qty - product.usedQty) < requirement.min_qty
        })
        .map(requirement => {
          const product = this.findProduct(requirement.product_code)

          return {
            productCode: requirement.product_code,
            neededQty: requirement.min_qty - Math.max((product.qty - product.usedQty), 0)
          }
        })
    },
    getBundleMultipier(bundle) {
      if (this.checkBundleIsQuantity(bundle)) {
        const productQty = bundle.requirements.reduce((total, requirement) => {
          const product = this.findProduct(requirement.product_code)

          return total + (product.qty - product.usedQty)
        }, 0)

        return Math.floor(productQty / bundle.bundle_min_qty)
      }

      return Math.min(...bundle.requirements.map(requirement => {
        const product = this.findProduct(requirement.product_code)

        return Math.floor((product.qty - product.usedQty) / requirement.min_qty)
      }))
    },
    removeSelectedBundle(bundle, index) {
      this.resetBundleRequirement(bundle, index)

      bundle.bonuses
        .forEach((bonus, bonusIndex) => this.resetBundleBonus(bonus, index, bonusIndex))

      this.bundles[index].multipier = 0
      this.bundles[index].selected = false
      this.bundles[index].bonus_choosen = null
      this.bundles[index].original_bonus_choosen = null
    },
    resetBundleRequirement(bundle, index) {
      bundle.requirements.forEach((requirement, requirementIndex) => {
        const productIndex = this.products.findIndex(product => product.productCode === requirement.product_code)

        this.products[productIndex].usedQty -= requirement.received_qty
        this.bundles[index].requirements[requirementIndex].received_qty = 0
      })
    },
    resetBundleBonus(bonus, bundleIndex, bonusIndex) {
      this.bonusStocks[bonus.product_code].usedQty -= bonus.received_qty
      this.bundles[bundleIndex].bonuses[bonusIndex].received_qty = 0
    },
    selectBundle(bundle, index, bonusIndex = 0) {
      const multipier = this.getBundleMultipier(bundle)

      this.bundles[index].multipier = multipier

      if (bundle.bonus_type === 'choose') {
        this.bundles[index].bonus_choosen = bundle.bonuses[bonusIndex].product_code
        this.bundles[index].original_bonus_choosen = bundle.bonuses[bonusIndex].product_code
      }

      this.selectBundleRequirement(bundle, index)

      bundle.bonuses
        .filter(bonus => {
          if (!bundle.bonus_choosen) {
            return true
          }

          return bonus.product_code === bundle.bonus_choosen
        })
        .forEach((bonus) => {
          this.selectBundleBonus(bonus, index)
        })

      this.bundles[index].selected = true
    },
    selectBundleRequirement(bundle, index) {
      bundle.requirements.forEach((requirement, requirementIndex) => {
        const productIndex = this.products.findIndex(product => product.productCode === requirement.product_code)

        if (this.checkBundleIsQuantity(bundle)) {
          const receivedQty = this.products[productIndex].qty - this.products[productIndex].usedQty

          this.products[productIndex].usedQty += receivedQty
          this.bundles[index].requirements[requirementIndex].received_qty = receivedQty
        } else {
          const receivedQty = this.bundles[index].multipier * requirement.min_qty

          this.products[productIndex].usedQty += receivedQty
          this.bundles[index].requirements[requirementIndex].received_qty = receivedQty
        }
      })
    },
    selectBundleBonus(bonus, bundleIndex) {
      const bonusIndex = this.bundles[bundleIndex].bonuses.findIndex(bundleBonus => bundleBonus.product_code === bonus.product_code)
      const receivedQty = Math.min(this.bundles[bundleIndex].multipier * bonus.bonus_qty, this.bonusStocks[bonus.product_code].qty - this.bonusStocks[bonus.product_code].usedQty)

      this.bundles[bundleIndex].bonuses[bonusIndex].received_qty = receivedQty
      this.bonusStocks[bonus.product_code].usedQty += receivedQty
    },
    onCheckBundle(bundle, index) {
      if (bundle.selected) {
        this.removeSelectedBundle(bundle, index)
      } else {
        this.selectBundle(bundle, index)
      }
    },
    onChooseBonus(bonus, bundleIndex, bonusIndex) {
      const bundle = this.bundles[bundleIndex]
      const prevChoosenBonusIndex = bundle.bonuses.findIndex(bundleBonus => bundleBonus.product_code === bundle.original_bonus_choosen)
      const prevChoosenBonus = bundle.bonuses[prevChoosenBonusIndex]
      
      this.resetBundleBonus(prevChoosenBonus, bundleIndex, prevChoosenBonusIndex)
      this.selectBundleBonus(bonus, bundleIndex)

      this.bundles[bundleIndex].original_bonus_choosen = this.bundles[bundleIndex].bonus_choosen

      this.setSelectedBundle({
        [bundleIndex]: {
          bonusIndex
        }
      })
    }
  },
  mounted() {
    this.setProducts()
    this.setBundles()
    this.setBonusStocks()
  }
}
</script>