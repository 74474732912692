import { debounce } from 'debounce';
import { mapGetters, mapActions } from 'vuex';

export const readReturShipment = {
  data() {
    return {
      filter: {
        search: null,
        status: null,
      },
      detailModal: {
        visible: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      isLoading: 'retur_shipments/getLoading',
      me: 'auth/getUser',
      getOfficeCategoryByName: 'office_categories/getOfficeCategoryByName',
      getReturShipments: 'retur_shipments/getReturShipments',
    }),
    computedFilter() {
      if (this.filter.status === null) {
        return {
          is_shipped: null,
          is_received: null,
        };
      }

      if (this.filter.status === 'draft') {
        return {
          is_shipped: false,
          is_received: null,
        };
      }

      if (this.filter.status === 'shipped') {
        return {
          is_shipped: true,
          is_received: false,
        };
      }

      if (this.filter.status === 'received') {
        return {
          is_shipped: null,
          is_received: true,
        };
      }

      return {
        is_shipped: null,
        is_received: null,
      };
    },
  },
  methods: {
    ...mapActions({
      fetchReturShipments: 'retur_shipments/fetchReturShipments',
      fetchReturShipment: 'retur_shipments/fetchReturShipment',
    }),
    onFilter() {
      this.loadReturShipments();
    },
    onSearch: debounce(function () {
      this.loadReturShipments();
    }, 300),
    onPageChange(page) {
      this.loadReturShipments({
        'page[number]': page,
      });
    },
    async onDetail(returShipment) {
      await this.fetchReturShipment({
        id: returShipment.id,
        params: {
          include: 'origin-office,destination-office,details',
          'fields[retur-shipments]':
            'retur_origin_code,code,origin-office,destination-office,updatedAt,product_count,shipment_status,is_shipped,is_received,receivement_status,details',
          'fields[offices]': 'code,name',
          'fields[retur-shipment-details]':
            'product_code,product_name,product_qty,expired_count,damaged_count,suitable_for_sale_count,unsuitable_for_sale_count',
        },
      });

      this.detailModal.visible = true;
    },
    onShipped() {
      this.loadReturShipments();

      this.detailModal.visible = false;
    },
    loadReturShipments(params = {}) {
      const originCategory = this.getOfficeCategoryByName(
        this.originOfficeCategoryName
      );
      const pusatCategory = this.getOfficeCategoryByName('Pusat');

      this.fetchReturShipments({
        'filter[origin_office_id]': this.me.office_id,
        'filter[origin_office_category_id]': originCategory.id,
        'filter[destination_office_category_id]': pusatCategory.id,
        'filter[is_shipped]': this.computedFilter.is_shipped,
        'filter[is_received]': this.computedFilter.is_received,
        'filter[search]': this.filter.search,
        'page[size]': 5,
        'fields[retur-shipments]':
          'retur_origin_code,code,updatedAt,product_count,total_weight,shipment_status',
        sort: '-createdAt',
        ...params,
      });
    },
  },
  created() {
    this.loadReturShipments();
  },
};
