import { render, staticRenderFns } from "./Tambah.vue?vue&type=template&id=bc031902&scoped=true"
import script from "./Tambah.vue?vue&type=script&lang=js"
export * from "./Tambah.vue?vue&type=script&lang=js"
import style0 from "./Tambah.vue?vue&type=style&index=0&id=bc031902&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc031902",
  null
  
)

export default component.exports