<template>
  <base-dropdown width="150px">
    <template #toggle="{ toggle }">
      <base-button style="height: 38px" @click="toggle">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
          />
        </svg>
        Export
      </base-button>
    </template>

    <template #content>
      <div class="flex items-center justify-between p-2 text-sm text-gray-700">
        <a
          href="#"
          :class="[
            'flex items-center gap-x-2',
            loading.exportExcel && 'cursor-disabled',
          ]"
          v-on:click="loading.exportExcel ? {} : handleExportExcel()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
            />
          </svg>
          Export Excel
        </a>
        <base-spinner v-if="loading.exportExcel" size="sm" />
      </div>
      <div class="flex items-center justify-between p-2 text-sm text-gray-700">
        <a
          href="#"
          :class="[
            'flex items-center gap-x-2',
            loading.exportPdf && 'cursor-disabled',
          ]"
          v-on:click="loading.exportPdf ? {} : handleExportPdf()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
            />
          </svg>
          Export PDF
        </a>
        <base-spinner v-if="loading.exportPdf" size="sm" />
      </div>
    </template>
  </base-dropdown>
</template>

<script>
import BaseButton from '@/components/base/BaseButton.vue';
import BaseDropdown from '@/components/base/BaseDropdown.vue';
import BaseSpinner from '@/components/base/BaseSpinner.vue';
import { mapActions, mapGetters } from 'vuex';
import { downloadFile, downloadFileUrl } from '@/services/utils.service';
import { now } from '@/services/date.service';

export default {
  components: { BaseButton, BaseDropdown, BaseSpinner },
  data() {
    return {
      exportStockChannelName: null,
      loading: {
        exportPdf: false,
        exportExcel: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
      getWarehouse: 'warehouses/getWarehouse',
    }),
  },
  created() {
    this.listenToExportStockEvent();
    this.fetchWarehouseById({
      id: this.me.current_warehouse,
      params: {
        'fields[warehouses]': 'code',
      },
    });
  },
  destroyed() {
    this.stopListenToExportStockEvent();
  },
  methods: {
    ...mapActions({
      exportPdf: 'stocks/exportPdf',
      exportExcel: 'stocks/exportExcel',
      fetchWarehouseById: 'warehouses/fetchWarehouseById',
      createAlert: 'alert/createAlert',
    }),
    async handleExportPdf() {
      this.loading.exportPdf = true;

      try {
        const res = await this.exportPdf({
          params: {
            'filter[warehouse_uuid]': this.me.current_warehouse,
          },
        });

        downloadFile(
          res,
          `stock_${this.getWarehouse.data.attributes.code}_${now()}.pdf`
        );
      } finally {
        this.loading.exportPdf = false;
      }
    },
    async handleExportExcel() {
      this.loading.exportExcel = true;

      await this.exportExcel({
        params: {
          'filter[warehouse_uuid]': this.me.current_warehouse,
        },
      });
    },
    listenToExportStockEvent() {
      this.exportStockChannelName = `private-StockExport.${this.me.id}`
      
      const exportStockChannel = this.$pusher.subscribe(this.exportStockChannelName);

      exportStockChannel.bind('StockExportStatusUpdated', (e) => {
        if (e.status === 'processing') {
          this.loading.exportExcel = true;
        } else if (e.status === 'failed') {
          this.loading.exportExcel = false;

          this.createAlert({ data: e.message, status: 'error' });
        } else if (e.status === 'finished') {
          this.loading.exportExcel = false;

          downloadFileUrl(e.file_url);
        }
      });
    },
    stopListenToExportStockEvent() {
      this.$pusher.unsubscribe(this.exportStockChannelName);
    },
  },
};
</script>
