<template>
  <!-- modal stockist gudang -->
  <BaseModal
    :showing="visible"
    @close="onClose"
    :showClose="true"
    size="max-w-6xl"
  >
    <div class="flex justify-between px-4 py-5 sm:px-6">
      <div>
        <h3 class="text-lg font-bold leading-6 text-gray-900">Daftar Gudang</h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">
          Berdasarkan data gudang yang telah dibuat
        </p>
      </div>
      <div>
        <img src="@/assets/images/logo.png" alt="" class="w-52" />
      </div>
    </div>
    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
      <div class="flex justify-end">
        <base-search
          placeholder="Cari Kode"
          v-model="filter.search"
          v-on:input="onSearch"
        />
      </div>
      <div class="flex my-2 sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <data-table
            :total="getWarehouses.meta.page.total"
            :perPage="getWarehouses.meta.page.perPage"
            :currentPage="getWarehouses.meta.page.currentPage"
            @pagechanged="onPageChange"
          >
            <template v-slot:thead>
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Kode Gudang
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Nama Gudang
                  </th>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody>
                <tr
                  class="cursor-pointer bg-white hover:bg-green-100"
                  v-for="(data, dataIdx) in getWarehouses.data"
                  :key="data.id"
                  :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                  @click="$emit('change', data)"
                >
                  <td
                    class="whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
                  >
                    {{ data.attributes.code }}
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    {{ data.attributes.name }}
                  </td>
                </tr>
              </tbody>
            </template>
          </data-table>
        </div>
      </div>
    </div>
    <div class="flex justify-end">
      <base-button @click="onClose" type="button"> Tutup </base-button>
    </div>
  </BaseModal>
  <!-- end of modal gudang -->
</template>
<script>
import BaseSearch from '@/components/base/Search.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import debounce from 'debounce';
import { mapGetters } from 'vuex';
import BaseModal from '@/components/base/BaseModal';
import DataTable from '@/components/base/Datatable.vue';

export default {
  name: 'WarehouseModal',
  components: {
    BaseModal,
    DataTable,
    BaseSearch,
    BaseButton,
  },
  props: {
    visible: {
      required: true,
    },
    fetchByOffice: Boolean,
  },
  data() {
    return {
      filter: {
        search: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      getWarehousesDefault: 'warehouses/getWarehouses',
      getWarehouseByOffice: 'warehouses/getWarehouseByOffice',
    }),
    getWarehouses() {
      return this.fetchByOffice
        ? this.getWarehouseByOffice
        : this.getWarehousesDefault;
    },
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    onSearch: debounce(function () {
      this.$emit('search', this.filter.search);
    }, 400),
    onPageChange(page) {
      this.$emit('page-change', page);
    },
  },
};
</script>
