export const orderStatusMixin = {
    methods: {
        parseOrderStatus(order) {
            const statuses = {
              draft: {
                color: 'indigo',
                text: 'Draft'
              },
              needPayment: {
                color: 'indigo',
                text: 'Diproses Kasir',
                position: `Kasir ${order.attributes.origin_office_code}`
              },
              shipping: {
                color: 'indigo',
                text: 'Diproses Pengeluaran',
                position: `Gudang ${order.attributes.origin_office_code}`
              },
              needShipping: {
                color: 'indigo',
                text: 'Diproses Pengiriman',
                position: `Gudang ${order.attributes.origin_office_code}`
              },
              shipped: {
                color: 'indigo',
                text: 'Dikirim',
                position: `Gudang ${order.attributes.destination_office_code}`
              },
              received: {
                color: 'indigo',
                text: 'Diterima',
                position: `Gudang ${order.attributes.destination_office_code}`
              },
              followUpDelivery: {
                color: 'indigo',
                text: 'Ada Susulan',
                position: `Gudang ${order.attributes.origin_office_code}`
              }
            }
      
            if (order.attributes.is_completed) {
              return statuses.received
            }
      
            if (order.attributes.is_shipped) {
              return statuses.shipped
            }
      
            if (order.attributes.is_pending_follow_up_delivery) {
              return statuses.followUpDelivery
            }
      
            if (order.attributes.is_valid_for_shipment) {
              return statuses.needShipping
            }
      
            if (order.attributes.is_valid_for_packing) {
              return statuses.shipping
            }
      
            if (order.attributes.is_valid_for_payment) {
              return statuses.needPayment
            }
      
            return statuses.draft
          },
    }
}