var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"customFade","enter-active-class":"fadeIn","leave-active-class":"fadeOut"}},[(_vm.value)?_c('div',{staticClass:"fixed inset-0 z-10",staticStyle:{"animation-duration":"0.3s"},attrs:{"aria-labelledby":"modal-title","role":"dialog","aria-modal":"true"}},[_c('div',{staticClass:"mx-4 flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"},[_c('div',{staticClass:"fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"hidden sm:inline-block sm:h-screen sm:align-middle",attrs:{"aria-hidden":"true"}},[_vm._v("​")]),_c('div',{staticClass:"inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}}},[_c('div',[_c('div',{staticClass:"mt-3 text-center sm:mt-5"},[_c('h3',{staticClass:"text-lg font-medium leading-6 text-gray-900",attrs:{"id":"modal-title"}},[_vm._v(" Validasi Pengiriman ")]),_c('div',{staticClass:"mt-2"},[_c('p',{staticClass:"text-sm text-gray-500"},[_vm._v(" Lengkapi form dibawah ini untuk memvalidasi pengiriman kemasan ")])])]),_c('div',{staticClass:"my-4 space-y-3"},[_c('div',{staticClass:"field-inset-default w-full bg-gray-100"},[_c('label',{staticClass:"block text-xs font-bold text-gray-700",attrs:{"for":"kode_po"}},[_vm._v(" Nama Kemasan ")]),_c('p',{staticClass:"block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"},[_vm._v(" "+_vm._s(_vm.getStockMovement.data.attributes.code)+" ")])]),(
                  _vm.getStockMovement.data.attributes.shipment_type === 'delivery'
                )?_c('div',{staticClass:"space-y-3"},[_c('div',{staticClass:"field-inset-default w-full"},[_c('label',{staticClass:"block text-xs font-bold text-gray-700",attrs:{"for":"no_resi"}},[_vm._v(" Nomor Resi ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataPengiriman.no_resi),expression:"dataPengiriman.no_resi"}],staticClass:"block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm",attrs:{"type":"text","name":"no_resi","id":"no_resi","placeholder":"Masukkan nomor resi yang didapatkan dari kurir"},domProps:{"value":(_vm.dataPengiriman.no_resi)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.dataPengiriman, "no_resi", $event.target.value)}}})]),_c('div',{staticClass:"field-inset-default w-full"},[_c('label',{staticClass:"block text-xs font-bold text-gray-700",attrs:{"for":"name"}},[_vm._v(" Diambil oleh ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataPengiriman.expedition),expression:"dataPengiriman.expedition"}],staticClass:"block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm",attrs:{"id":"expedition"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.dataPengiriman, "expedition", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{domProps:{"value":null}},[_vm._v("Pilih Ekspedisi")]),_vm._l((_vm.getOfficeExpeditions.data),function(expedition){return _c('option',{key:expedition.id,domProps:{"value":expedition.id}},[(
                          expedition.relationships['expedition-detail'].data
                            ?.id
                        )?[_vm._v(" "+_vm._s(_vm.getRelationships( _vm.getOfficeExpeditions, expedition.relationships['expedition'].data?.id )?.attributes?.name)+" - "+_vm._s(_vm.getRelationships( _vm.getOfficeExpeditions, expedition.relationships['expedition-detail'].data .id ).attributes.address)+" ")]:_vm._e()],2)})],2)]),_c('span',{staticClass:"mt-2 text-sm"},[_vm._v(" Untuk menambah ekspedisi "),_c('router-link',{staticClass:"text-blue-600 hover:underline",attrs:{"to":"/gudang/setup?tab=Ekspedisi"}},[_vm._v("Klik disini")])],1)]):(
                  _vm.getStockMovement.data.attributes.shipment_type === 'pickup'
                )?_c('div',[_c('div',{staticClass:"field-inset-default w-full"},[_c('label',{staticClass:"block text-xs font-bold text-gray-700",attrs:{"for":"taken_by"}},[_vm._v(" Diambil oleh ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataPengiriman.taken_by),expression:"dataPengiriman.taken_by"}],staticClass:"block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm",attrs:{"type":"text","name":"taken_by","id":"taken_by","placeholder":"Masukkan nama yang mengambil barang"},domProps:{"value":(_vm.dataPengiriman.taken_by)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.dataPengiriman, "taken_by", $event.target.value)}}})])]):_vm._e()])]),_c('div',{staticClass:"mt-5 sm:mt-6 sm:flex sm:flex-row sm:gap-x-3"},[_c('base-button',{attrs:{"fullwidth":"","type":"submit"}},[_vm._v(" Ya ")]),_c('base-button',{attrs:{"fullwidth":"","color":"white","type":"button"},on:{"click":_vm.onClose}},[_vm._v(" Tutup ")])],1)])])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }