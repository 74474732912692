var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"space-y-1"},[_c('dt',{staticClass:"flex items-end justify-between text-sm font-bold text-gray-700"},[_vm._v(" Riwayat Stock "+_vm._s(_vm.filter.period_uuid ? _vm.getPeriods.data.find((it) => it.id == _vm.filter.period_uuid).attributes .name : _vm.getPeriod.data.attributes.name)+" "),_c('div',[_c('period-select',{attrs:{"with-label":false},on:{"change":_vm.handleFilter},model:{value:(_vm.filter.period_uuid),callback:function ($$v) {_vm.$set(_vm.filter, "period_uuid", $$v)},expression:"filter.period_uuid"}})],1)]),_c('div',{staticClass:"my-2 flex sm:-mx-6 lg:-mx-8"},[_c('div',{staticClass:"inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"},[_c('Datatable',{attrs:{"total":_vm.getStockTrxes.meta.page.total,"perPage":_vm.getStockTrxes.meta.page.perPage,"currentPage":_vm.getStockTrxes.meta.page.currentPage},on:{"pagechanged":_vm.handlePageChangeStockTrx},scopedSlots:_vm._u([{key:"thead",fn:function(){return [_c('thead',{staticClass:"bg-gray-50"},[_c('tr',_vm._l(([
                  {
                    label: 'Tanggal',
                  },
                  {
                    label: 'Keterangan',
                  },
                  {
                    label: 'Masuk',
                    classes: 'text-right',
                  },
                  {
                    label: 'Keluar',
                    classes: 'text-right',
                  },
                  {
                    label: 'Saldo Stock',
                    classes: 'text-right',
                  },
                ]),function(item,index){return _c('th',{key:index,staticClass:"px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500",class:item.classes,attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(item.label)+" ")])}),0)])]},proxy:true},{key:"tbody",fn:function(){return [(!_vm.getStockTrxes.data.length)?_c('tbody',[_c('tr',{staticClass:"bg-white"},[_c('td',{staticClass:"whitespace-nowrap px-6 py-4 text-sm text-gray-500",attrs:{"colspan":"5"}},[_vm._v(" Riwayat Kosong ")])])]):_c('tbody',_vm._l((_vm.getStockTrxes.data),function(data,dataIdx){return _c('tr',{key:data.id,staticClass:"bg-white hover:bg-green-100",class:dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'},[_c('td',{staticClass:"whitespace-nowrap px-6 py-4 text-sm text-gray-700"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(data.attributes.createdAt))+" ")]),_c('td',{staticClass:"whitespace-nowrap px-6 py-4 text-sm text-gray-700"},[_vm._v(" "+_vm._s(data.attributes.description ?? '-')+" ")]),_c('td',{staticClass:"whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"},[_vm._v(" "+_vm._s(data.attributes.type === 'in' ? Math.abs(data.attributes.amount) : '-')+" ")]),_c('td',{staticClass:"whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"},[_vm._v(" "+_vm._s(data.attributes.type === 'out' ? Math.abs(data.attributes.amount) : '-')+" ")]),_c('td',{staticClass:"whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(data.attributes.current_stock))+" ")])])}),0)]},proxy:true}])})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }