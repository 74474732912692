<template>
  <tr class="bg-white">
    <td
      class="whitespace-nowrap px-6 py-4 text-center text-sm font-medium text-gray-900"
      colspan="5"
    >
      <div
        class="my-4 flex items-center justify-center space-y-2 text-gray-400 transition-all hover:text-gray-500"
      >
        <button @click="onClickCreate" type="button">
          <div class="mb-2 flex justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-6 w-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <p class="text-center text-xs font-normal">
            Tidak ada data <br />
            Klik untuk menambahkan
          </p>
        </button>
      </div>
    </td>
  </tr>
</template>
<script>
export default {
  name: 'EmptyKoli',
  methods: {
    onClickCreate() {
      this.$emit('click');
    },
  },
};
</script>
