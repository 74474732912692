<template>
  <div>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="3508"
      :filename="koli.code ?? 'koli'"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="2480px"
      ref="html2Pdf"
    >
      <section slot="pdf-content" class="mx-4 text-xs font-normal">
        <div class="p-4 text-base font-semibold">
          Kode Koli: {{ koli.code }} <br />
          Nama Koli: {{ koli.name }}
        </div>
        <table border="1">
          <thead>
            <th>Kode Produk</th>
            <th>Nama Produk</th>
            <th>Jumlah Produk Terkirim</th>
            <th>Harga Per Produk</th>
          </thead>
          <tbody>
            <tr v-for="(product, i) in koli.products" :key="i">
              <td>
                {{ product.product_code }}
              </td>
              <td>
                {{ product.product_name }}
              </td>
              <td>
                {{ product.shipped_product_qty | toCurrency }}
              </td>
              <td>
                {{ product.product_price | toCurrency }}
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </vue-html2pdf>
  </div>
</template>
<script>
import VueHtml2pdf from 'vue-html2pdf';
/**
  Usage:
      import PrintKoli from '@/components/PrintKoli.vue';
      
      // trigger btn
      <button @click="printKoli(index)" type="button">
      </button>
      // content
      <PrintKoli :koli="koli" ref="printRef" />
    
      printKoli(index) {
        // 1. manggil printRef
        // 2. ngetrigger child method yg bernama generateReport
        this.$refs.printRef[index].generateReport();
      },
 */
export default {
  name: 'PrintKoli',
  components: {
    VueHtml2pdf,
  },
  props: {
    koli: {
      type: Object,
    },
  },
  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
};
</script>
