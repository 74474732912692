import { sum } from "@/utils/array"

export const orderDetailsFormMixin = {
    data() {
        return {
            orderDetails: [
                {
                  id: null,
                  search: null,
                  product: {
                    id: null,
                    code: null,
                    name: null,
                    stock: null,
                    status: null,
                    isPoint: null,
                    pointValue: null,
                    bonusValue: null,
                    price: null,
                    weight: null
                  },
                  qty: null
                }
            ],
        }
    },
    computed: {
        totalPointValue() {
            return sum(this.orderDetails, (orderDetail) => orderDetail.product.pointValue * orderDetail.qty)
        },
        totalBonusValue() {
            return sum(this.orderDetails, (orderDetail) => orderDetail.product.bonusValue * orderDetail.qty)
        },
        totalPrice() {
            return sum(this.orderDetails, (orderDetail) => orderDetail.product.price * orderDetail.qty)
        },
        totalWeight() {
            return sum(this.orderDetails, (orderDetail) => orderDetail.product.weight * orderDetail.qty)
        }
    },
    methods: {
        setOrderDetails() {
            const orderDetails = this.getIncludedByType(this.getOrder, 'order-details')

            this.orderDetails = orderDetails.map(orderDetail => ({
                id: orderDetail.id,
                search: orderDetail.attributes.product_code,
                product: {
                    id: orderDetail.attributes.product_id,
                    code: orderDetail.attributes.product_code,
                    name: orderDetail.attributes.product_name,
                    stock: orderDetail.attributes.current_stock + orderDetail.attributes.product_qty,
                    status: true,
                    isPoint: !!orderDetail.attributes.point_value,
                    pointValue: orderDetail.attributes.point_value,
                    bonusValue: orderDetail.attributes.bonus_value,
                    price: orderDetail.attributes.product_price,
                    weight: orderDetail.attributes.product_weight
                },
                qty: orderDetail.attributes.product_qty,
                type: orderDetail.attributes.product_type
            }))
        
            this.pushEmptyProductItem();
        },
        pushEmptyProductItem() {
            this.orderDetails.push(
                {
                  id: null,
                  search: null,
                  product: {
                    id: null,
                    code: null,
                    name: null,
                    stock: null,
                    status: null,
                    isPoint: null,
                    pointValue: null,
                    bonusValue: null,
                    price: null,
                    weight: null
                  },
                  qty: null
            })
        }
    }
}