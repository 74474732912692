<template>
  <div>
    <BaseModal
      :showing="visible"
      :showClose="visible"
      :backgroundClose="true"
      size="max-w-6xl"
      v-on:close="$emit('close')"
    >
      <div class="flex justify-between px-4 py-5 sm:px-6">
        <div>
          <h3 class="text-lg font-bold leading-6 text-gray-900">Daftar Stok</h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">
            Data stok berdasarkan gudang yang dipilih
          </p>
        </div>
        <div>
          <img src="@/assets/images/logo.png" alt="" class="w-52" />
        </div>
      </div>
      <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
        <div class="flex justify-end">
          <base-search
            placeholder="Cari Kode"
            v-model="filter.search"
            v-on:input="handleSearch"
          />
        </div>
        <div class="my-2 flex sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <Datatable
              :total="getWarehouseStock.meta.page.total"
              :perPage="getWarehouseStock.meta.page.perPage"
              :currentPage="getWarehouseStock.meta.page.currentPage"
              @pagechanged="handleChangePage"
              :is-empty="!getWarehouseStock.data.length"
            >
              <template v-slot:thead>
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Kode Barang
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Nama Barang
                    </th>
                    <th
                      v-if="columns.includes('pv')"
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      PV
                    </th>
                    <th
                      v-if="columns.includes('bv')"
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      BV
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 pr-24 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Stock
                    </th>
                    <th
                      scope="col"
                      class="px-6 pr-24 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Berat
                    </th>
                    <th
                      scope="col"
                      class="px-6 pr-24 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Harga
                    </th>
                  </tr>
                </thead>
              </template>
              <template v-slot:tbody>
                <tbody>
                  <tr
                    class="cursor-pointer bg-white hover:bg-green-100"
                    v-for="(data, dataIdx) in getWarehouseStock.data"
                    :key="data.id"
                    :class="[
                      isRowDisabled(data)
                        ? 'cursor-not-allowed'
                        : 'cursor-pointer hover:bg-green-100',
                      dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50',
                    ]"
                    :title="getRowTitle(data)"
                    @click="
                      isRowDisabled(data) ? null : handleChangeProduct(data)
                    "
                  >
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
                    >
                      {{ data.attributes.product_code }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ data.attributes.product_name }}
                    </td>
                    <td
                      v-if="columns.includes('pv')"
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ data.attributes.bonus_point }}
                    </td>
                    <td
                      v-if="columns.includes('bv')"
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ data.attributes.bonus_value }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 pr-24 text-right text-sm text-gray-500"
                      :class="{
                        'text-red-600': data.attributes.orderable_stock < 1,
                      }"
                    >
                      {{ data.attributes.orderable_stock | toCurrency }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 pr-24 text-right text-sm text-gray-500"
                    >
                      {{ data.attributes.stock_weight }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 pr-24 text-right text-sm text-gray-500"
                    >
                      {{ data.attributes.product_price | toCurrency }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </Datatable>
          </div>
        </div>
      </div>
      <div class="flex justify-end">
        <button
          @click="$emit('close')"
          type="button"
          class="focus:outline-none inline-flex justify-end rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
        >
          Tutup
        </button>
      </div>
    </BaseModal>
    <loading v-if="isLoading" text="Memuat Barang" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseModal from '@/components/base/BaseModal';
import Datatable from '@/components/base/Datatable';
import { debounce } from 'debounce';
import BaseSearch from '@/components/base/Search.vue';

export default {
  components: { BaseModal, Datatable, BaseSearch },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    params: Object,
    columns: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['change'],
  data() {
    return {
      isLoading: false,
      filter: {
        search: null,
      },
      page: {
        number: 1,
      },
    };
  },
  computed: {
    ...mapGetters({
      getWarehouseStock: 'warehouses/getWarehouseStock',
      getUserOfficeCategory: 'auth/getUserOfficeCategory',
    }),
  },
  watch: {
    visible(value) {
      if (value) {
        this.loadWarehouseStocks({ resetPage: true, resetFilter: true });
      }
    },
  },
  methods: {
    ...mapActions({
      fetchWarehouseStock: 'warehouses/fetchWarehouseStock',
    }),
    handleSearch: debounce(function () {
      this.loadWarehouseStocks({ resetPage: true });
    }, 300),
    handleChangePage(page) {
      this.page.number = page;

      this.loadWarehouseStocks();
    },
    handleChangeProduct(product) {
      this.$emit('change', product);
    },
    getRowTitle(stock) {
      let result = '';
      const message = {
        orderable_stock:
          'Produk ini tidak dapat di order karena jumlah orderable_stock kurang dari 1',
        is_for_registration: 'Maaf produk ini tidak dapat dijual oleh stockist',
      };

      if (
        stock.attributes.is_for_registration &&
        this.getUserOfficeCategory.attributes.name == 'Stockist'
      ) {
        result = message['is_for_registration'];
      }

      if (stock.attributes.orderable_stock <= 0) {
        result = message['orderable_stock'];
      }

      return result;
    },
    isRowDisabled(stock) {
      return (
        stock.attributes.orderable_stock <= 0 ||
        stock.attributes.is_for_registration
      );
    },
    async loadWarehouseStocks({ resetPage, resetFilter } = {}) {
      this.isLoading = true;

      // const params = {
      //   ...this.params,
      //   search: this.filter.search,
      //   'page[limit]': 5,
      // };

      // if (page) {
      //   await this.fetchWarehouseStock({
      //     ...params,
      //     ...page,
      //   });
      // } else {
      //   await this.fetchWarehouseStock(params);
      // }

      if (resetPage) {
        this.page.number = 1;
      }

      if (resetFilter) {
        this.filter.search = null;
      }

      await this.fetchWarehouseStock({
        ...this.params,
        search: this.filter.search,
        'page[number]': this.page.number,
        'page[size]': 5,
        'filter[has_stock_real]': true,
      });

      this.isLoading = false;
    },
  },
};
</script>
