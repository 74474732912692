import { mapActions, mapGetters } from 'vuex';

export const promoOptions = [
  {
    label: 'Pilih Tipe Promo',
    value: null,
    key: null,
  },
  {
    label: 'Syarat / Barang Utama 1 barang dapat free 1 barang',
    description: 'Promo Total, 1 Syarat Barang, 1 Bonus Barang',
    value: 'promo-single-item-get-single-item-free',
    key: '1',
    selectedProductRequirementType: 1,
    selectedProductBonusType: 1,
    selectedPromoType: 2,
  },
  {
    label: 'Syarat / Barang Utama 1 barang dapat free 1 barang(memilih)',
    description: 'Promo Tetap, 1 Syarat Barang, 1 Bonus Barang',
    value: 'promo-choose-one-item-and-choose-one-free',
    key: '2',
    selectedProductRequirementType: 1,
    selectedProductBonusType: 1,
    selectedPromoType: 2,
  },
  {
    label: 'Syarat / Barang Utama 1 barang dapat free lebih dari 1 barang',
    description: 'Promo Total, 1 Syarat Barang, Lebih dari >1 Bonus Barang',
    value: 'promo-single-item-get-multiple-item-free',
    key: '3',
    selectedProductRequirementType: 1,
    selectedProductBonusType: 2,
    selectedPromoType: 2,
  },
  {
    label: 'Syarat / Barang Utama 1 barang dapat free lebih dari 1 barang',
    description: 'Promo Tetap, 1 Syarat Barang, Lebih dari >1 Bonus Barang',
    value: 'promo-single-item-get-multiple-item-free',
    key: '4',
    selectedProductRequirementType: 1,
    selectedProductBonusType: 2,
    selectedPromoType: 2,
  },
  {
    label: 'Syarat / Barang Utama minimal 2 barang dapat free 1 barang',
    description: 'Promo Total, Lebih dari >1 Syarat Barang, 1 Bonus Barang',
    value: 'promo-multiple-item-get-single-item-free',
    key: '5',
    selectedProductRequirementType: 2,
    selectedProductBonusType: 1,
    selectedPromoType: 2,
  },
  {
    label:
      'Syarat / Barang Utama minimal 2 barang dapat free 1 barang(memilih)',
    description: 'Promo Tetap, Lebih dari >1 Syarat Barang, 1 Bonus Barang',
    value: 'promo-multiple-item-choose-one-free',
    key: '6',
    selectedProductRequirementType: 2,
    selectedProductBonusType: 1,
    selectedPromoType: 2,
  },
  {
    label:
      'Syarat / Barang Utama minimal 2 barang dapat free lebih dari 1 barang',
    description:
      'Promo Total, Lebih dari >1 Syarat Barang, Lebih dari >1 Bonus Barang',
    value: 'promo-multiple-item-total-quantity',
    key: '7',
    selectedProductRequirementType: 2,
    selectedProductBonusType: 2,
    selectedPromoType: 1,
  },
  {
    label:
      'Syarat / Barang Utama lebih dari 1 jumlah  total barang jadi acuan : misal NASA, SUNPRO, AMNE total 7 dapet free MIXC 2 dan SKAO 3',
    description:
      'Promo Tetap, Lebih dari 1 Syarat Barang, Lebih dari >1 Bonus Barang',
    value: 'promo-multiple-item-get-multiple-item-free',
    key: '8',
    selectedProductRequirementType: 2,
    selectedProductBonusType: 2,
    selectedPromoType: 2,
  },
];

export const productBundleMixins = {
  data() {
    return {
      modalErrorVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      getAll: 'main/getAll',
      getOne: 'main/getOne',
      getOrder: 'orders/getOrder'
    }),
    getProductBundles() {
      return this.getAll['product-bundles'];
    },
  },
  methods: {
    ...mapActions({
      fetchAll: 'main/fetchAll',
      fetchOne: 'main/fetchOne',
      updateOne: 'main/updateOne',
      updateOneRelated: 'main/updateOneRelated',
      createAlert: 'alert/createAlert',
      deleteOne: 'main/deleteOne',
    }),
    promoable(item) {
      return [
        'promo-multiple-item-choose-one-free',
        'promo-choose-one-item-and-choose-one-free',
      ].includes(item.attributes.type);
    },
    async createProductBundleBonuses(item, orderId) {
      const response = await this.updateOneRelated({
        schema: 'orders',
        id: orderId,
        related: 'relationships/selected-product-bundle',
        payload: {
          data: {
            type: 'order-product-bundles',
            id: item.id,
          },
        },
      });
      if (response) {
        this.createAlert({
          data: 'Promo berhasil ditambahkan',
          type: 'success',
        });
      }

      return;
    },
    async loadProductBundles(orderId) {
      return await this.fetchAllRelated({
        schema: 'orders',
        related: 'product-bundles',
        id: orderId,
        params: {
          sort: 'createdAt'
        }
      });
    },
    async onRemoveProductBundle(productBundleId) {
      const productBundle = this.getOne['product-bundle']?.data;

      const response = await this.deleteOne({
        schema: 'product-bundles',
        id: productBundleId ?? productBundle.id,
      });

      if (!response) {
        this.modalErrorVisible = true;
      }
    },
    attachProductBundle() {
      this.createOne({
        payload: {
          data: {
            type: 'product-bundle-bonuses',
            id: '{{id}}',
          },
        },
      });
    },
  },
};
