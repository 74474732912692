<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div v-if="isLoading" class="justify-center text-center">
      <loading></loading>
    </div>
    <div v-else>
      <div class="mb-5 rounded-lg bg-white p-4 sm:p-6 md:p-8">
        <div class="border-b border-gray-200 pb-5">
          <h3 class="text-lg font-bold leading-6 text-gray-900">
            Detail Pengiriman
          </h3>
        </div>
        <div class="py-4">
          <dl class="flex flex-row gap-x-4">
            <div class="w-full">
              <dt class="text-sm font-bold text-gray-700">No Pengemasan</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ getStockMovement.data.attributes.code }}
              </dd>
            </div>
            <div class="w-full">
              <dt class="text-sm font-bold text-gray-700">
                Tanggal Pengemasan
              </dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{
                  dayjs(getStockMovement.data.attributes.updatedAt).format(
                    'll LT'
                  )
                }}
              </dd>
            </div>
            <div class="w-full">
              <dt class="text-sm font-bold text-gray-700">Mitra Usaha</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{
                  getRelationships(
                    getStockMovement,
                    getStockMovement.data.relationships['destination-office']
                      .data.id
                  ).attributes.code
                }}
              </dd>
            </div>
          </dl>
          <dl class="flex mt-6 flex-row gap-x-4">
            <div class="w-full">
              <dt class="text-sm font-bold text-gray-700">Nama Member</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{
                  getRelationships(
                    getStockMovement,
                    getStockMovement.data.relationships['destination-office']
                      .data.id
                  ).attributes.name
                }}
              </dd>
            </div>
            <div class="w-full">
              <dt class="text-sm font-bold text-gray-700">Alamat Member 1</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{
                  getRelationships(
                    getStockMovement,
                    getStockMovement.data.relationships['destination-office']
                      .data.id
                  ).attributes.address
                }}
              </dd>
            </div>
            <div class="w-full">
              <dt class="text-sm font-bold text-gray-700">Alamat Member 2</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{
                  getRelationships(
                    getStockMovement,
                    getStockMovement.data.relationships['destination-office']
                      .data.id
                  ).attributes.address2
                }}
              </dd>
            </div>
          </dl>
        </div>
      </div>

      <div
        v-if="getStockMovement.data.attributes.is_shipped"
        class="mb-5 rounded-lg bg-white p-4 sm:p-6 md:p-8"
      >
        <div class="flex justify-between pb-5">
          <div>
            <h3 class="text-lg font-bold leading-6 text-gray-900">
              Informasi Pengeluaran
            </h3>
            <p class="text-sm text-gray-600">
              Detail Pengeluaran yang akan dikirim ke Member
            </p>
          </div>
        </div>
        <div
          v-if="
            getRelationships(
              getStockMovement,
              getStockMovement.data.relationships['order'].data.id
            ).attributes.order_shipment === 'delivery'
          "
        >
          <div class="mb-8 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-12">
            <div class="sm:col-span-12">
              <div class="field-inset-default w-full bg-gray-100">
                <label
                  for="kode_po"
                  class="block text-xs font-bold text-gray-700"
                >
                  Nomor Resi
                </label>
                <p
                  class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                >
                  {{ dataPengiriman.no_resi }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else-if="
            getRelationships(
              getStockMovement,
              getStockMovement.data.relationships['order'].data.id
            ).attributes.order_shipment === 'pickup'
          "
        >
          <div class="mb-8 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-12">
            <div class="sm:col-span-12">
              <div class="field-inset-default w-full bg-gray-100">
                <label
                  for="kode_po"
                  class="block text-xs font-bold text-gray-700"
                >
                  Diambil oleh
                </label>
                <p
                  class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                >
                  {{ dataPengiriman.taken_by }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pt-5">
        <div class="flex justify-between">
          <div>
            <div class="flex space-x-2">
              <button
                v-if="!getStockMovement.data.attributes.is_shipped"
                @click="cancelStockMovementConfirmation = true"
                class="bg-white-600 ml-3 rounded-md border border-gray-300 py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-white hover:shadow-lg"
              >
                Batalkan Faktur Kemasan
              </button>
            </div>
          </div>
          <div>
            <button
              v-if="!getStockMovement.data.attributes.is_shipped"
              @click="validateConfirmation = true"
              class="ml-3 inline-flex rounded-md border border-gray-300 bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-600 hover:shadow-lg"
            >
              Kirim!
            </button>
            <router-link
              :to="indexPath"
              class="bg-white-600 ml-3 rounded-md border border-gray-300 py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-white hover:shadow-lg"
            >
              Kembali
            </router-link>
          </div>
        </div>
      </div>

      <!-- Detail Koli -->
      <modal-detail-koli
        :visible="openDetail"
        :packet="detailKoli"
        @close="openDetail = !openDetail"
      />

      <!-- MODAL VALIDASI KEMASAN-->
      <modal-validasi-pengiriman
        v-model="validateConfirmation"
        :data-pengiriman="dataPengiriman"
        @close="() => (validateConfirmation = !validateConfirmation)"
      />

      <!-- MODAL BATAL FAKTUR PENGEMASAN -->
      <transition
        name="customFade"
        enter-active-class="fadeIn"
        leave-active-class="fadeOut"
      >
        <div
          v-if="cancelStockMovementConfirmation"
          style="animation-duration: 0.3s"
          class="fixed inset-0 z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div
            class="flex mx-4 min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
          >
            <div
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              class="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
              >&#8203;</span
            >
            <div
              class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"
            >
              <form @submit.prevent="cancelStockMovement">
                <div>
                  <div
                    class="flex mx-auto h-12 w-12 items-center justify-center rounded-full bg-red-100"
                  >
                    <!-- Heroicon name: outline/check -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6 text-red-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </div>
                  <div class="mt-3 text-center sm:mt-5">
                    <h3
                      class="text-lg font-medium leading-6 text-gray-900"
                      id="modal-title"
                    >
                      Batalkan Faktur Pengemasan
                    </h3>
                    <div class="mt-2">
                      <p class="text-sm text-gray-500">
                        Sudah ada faktur pengemasan. Anda yakin ingin
                        membatalkan?
                      </p>
                    </div>
                  </div>
                </div>
                <div class="mt-5 sm:mt-6 sm:flex sm:flex-row sm:gap-x-3">
                  <button
                    type="submit"
                    class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                  >
                    Ya
                  </button>
                  <button
                    @click="cancelStockMovementConfirmation = false"
                    type="button"
                    class="focus:outline-none mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                  >
                    Tutup
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import dayjs from 'dayjs';
import { StorageService } from '@/services/storage.service';
import Loading from '@/components/Loading.vue';
// import Datatable from '@/components/base/Datatable';
import ModalDetailKoli from '@/components/pengiriman/modal-detail-koli.vue';
import ModalValidasiPengiriman from '@/components/pengiriman/modal-validasi-pengiriman.vue';

export default {
  name: 'TambahPengiriman',
  components: {
    // Datatable,
    Loading,
    ModalDetailKoli,
    ModalValidasiPengiriman,
  },
  data() {
    return {
      openDetail: false,
      validateConfirmation: false,
      cancelStockMovementConfirmation: false,
      dataPengiriman: {
        no_resi: null,
        taken_by: null,
        expedition: null,
      },
      detailKoli: {},
      indexPath: '/gudang/pengiriman/retur-ke-pusat',
    };
  },
  created() {
    if (this.$route.params.id) {
      this.fetchStockMovement({
        id: this.$route.params.id,
      }).then((response) => {
        const stockMovement = response.data.data;
        if (stockMovement.attributes.is_shipped) {
          this.dataPengiriman.no_resi =
            stockMovement.attributes.airwaybill_number;
          this.dataPengiriman.taken_by = stockMovement.attributes.taken_by;
        }
      });
    }
    this.fetchOfficeExpeditionsByOffice({
      office_id: StorageService.getUser().office_id,
      params: {
        include: 'expedition-detail,expedition',
      },
    });
    const handleEscape = () => {
      this.success = false;
      this.failed = false;
    };
    this.setEscape(handleEscape);
  },
  computed: {
    ...mapGetters({
      isLoading: 'stock_movements/getLoading',
      getStockMovement: 'stock_movements/getStockMovement',
      getOfficeExpeditions: 'office_expeditions/getOfficeExpeditions',
    }),
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchStockMovement: 'stock_movements/fetchStockMovement',
      deleteStockMovement: 'stock_movements/deleteStockMovement',
      fetchOfficeExpeditionsByOffice:
        'office_expeditions/fetchOfficeExpeditionsByOffice',
    }),
    getRelationships(resource, id) {
      let data = resource.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    detailModal(koli) {
      this.openDetail = !this.openDetail;
      this.detailKoli = koli;
    },
    cancelStockMovement() {
      this.deleteStockMovement({
        id: this.getStockMovement.data.id,
      }).then((response) => {
        if (response) {
          this.$router.push(this.indexPath);
        }
      });
    },
  },
};
</script>
