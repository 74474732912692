<template>
  <div class="relative" v-click-outside="handleClickOutside">
    <base-input
      :fullwidth="fullwidth"
      type="text"
      :placeholder="placeholder"
      :disabled="disabled"
      :shadow="shadow"
      v-model="search"
      v-on:input="handleInput"
      v-on:focus="handleFocus"
    />
    <div class="absolute top-2 right-2 z-20" v-if="isLoading">
      <base-spinner />
    </div>
    <button
      v-else-if="
        selectedItem?.attributes ? !!selectedItem.attributes[label] : false
      "
      type="button"
      class="absolute top-3 right-3 ml-auto inline-flex items-center rounded-lg bg-transparent p-0.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
      v-on:click="handleClickClear"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-4 w-4"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </button>
    <div
      v-else
      class="absolute top-3 right-3 ml-auto inline-flex items-center text-gray-400"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-4 w-4"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
          clip-rule="evenodd"
        />
      </svg>
    </div>
    <div
      class="absolute top-12 right-0 z-10 w-full divide-y divide-gray-100 overflow-y-auto rounded bg-white shadow"
      :class="{ hidden: !visible }"
      style="max-height: 200px"
      ref="content"
    >
      <ul class="py-1 text-sm text-gray-700" v-if="!data.length">
        <li v-if="emptyable" class="block w-full py-2 px-4 text-left text-sm">
          Data Kosong
        </li>
        <li
          v-if="createable"
          class="block w-full py-2 px-4 text-left text-sm hover:bg-gray-100"
          v-on:click="creating"
        >
          <div class="flex items-center">
            <Icon icon="heroicons:plus-20-solid" class="h-5 w-5" />
            <p>Tambah Data Baru "{{ search }}"</p>
          </div>
        </li>
      </ul>
      <ul class="py-1 text-sm text-gray-700" v-else>
        <template v-for="item in data">
          <li
            v-if="typeof item?.attributes[label] != 'undefined' || formatter"
            :key="item[id]"
            class="block w-full py-2 px-4 text-left text-sm"
            :class="[
              isItemActive(item)
                ? 'bg-green-600 text-white'
                : 'hover:bg-gray-100',
            ]"
            v-on:click="handleClickItem(item)"
          >
            <slot name="label" :item="item">
              {{ formatter ? formatter(item) : item?.attributes[label] }}
            </slot>
          </li>
        </template>
        <li
          v-if="createable"
          class="block w-full py-2 px-4 text-left text-sm hover:bg-gray-100"
          v-on:click="creating"
        >
          <div class="flex items-center">
            <Icon icon="heroicons:plus-20-solid" class="h-5 w-5" />
            <p>Tambah Data Baru: "{{ search }}"</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { debounce } from 'debounce';
import BaseInput from './BaseInput.vue';
import BaseSpinner from './BaseSpinner.vue';

export default {
  name: 'BaseSelectSearch',
  components: { BaseInput, BaseSpinner },
  props: {
    value: [Object, String],
    id: {
      type: String,
      default: 'id',
    },
    data: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: 'name',
    },
    placeholder: {
      type: String,
      default: 'Cari Kantor',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    shadow: {
      type: Boolean,
      default: false,
    },
    fullwidth: {
      type: Boolean,
      required: false,
    },
    createable: {
      type: Boolean,
      default: false,
    },
    emptyable: {
      type: Boolean,
      default: true,
    },
    formatter: {
      type: Function,
    },
  },
  emits: ['input', 'infinite-scroll', 'search', 'change', 'focus'],
  data: function () {
    return {
      visible: false,
      search: null,
    };
  },
  computed: {
    selectedItem: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.$emit('input', value);
        this.$emit('change');
      },
    },
  },
  methods: {
    show: function () {
      this.visible = true;
    },
    hide: function () {
      this.visible = false;
    },
    creating(value) {
      this.$emit('creating', value);
      this.hide();
      // this.handleClickClear()
    },
    debounceSearch: debounce(function () {
      this.$emit('search', this.search);
    }, 500),
    isItemActive: function (item) {
      return this.selectedItem
        ? this.selectedItem[this.id] === item[this.id]
        : false;
    },
    setListener: function () {
      this.$refs.content.addEventListener('scroll', this.handleScroll);
    },
    clearListener: function () {
      this.$refs.content.scrollTop = 0;
      this.$refs.content.removeEventListener('scroll', this.handleScroll);
    },
    handleScroll: function (e) {
      if (e.target.scrollTop >= e.target.scrollHeight - e.target.clientHeight) {
        this.$emit('infinite-scroll');
      }
    },
    handleClickItem: function (item) {
      this.selectedItem = item;
      this.search = this.formatter
        ? this.formatter(item)
        : item.attributes[this.label];

      this.hide();
      this.$emit('search', this.search);
    },
    handleInput: function () {
      this.debounceSearch();
    },
    handleFocus: function () {
      this.show();
      this.setListener();
      this.$emit('focus');
    },
    handleClickOutside: function () {
      if (this.visible) {
        if (this.selectedItem) {
          this.search = this.selectedItem.attributes
            ? this.selectedItem.attributes[this.label]
            : null;
        } else {
          this.search = null;
        }

        this.hide();
        this.$emit('search', this.search);

        this.clearListener();
      }
    },
    handleClickClear: function () {
      this.selectedItem = {};
      this.search = '';

      this.$emit('search', '');
    },
  },
  mounted: function () {
    this.search = this.selectedItem ? this.selectedItem[this.label] : null;

    this.setListener();
  },
};
</script>
