<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div v-if="isLoading" class="justify-center text-center">
      <loading></loading>
    </div>
    <form @submit.prevent="addProduct">
      <div class="mb-5 rounded-lg bg-white py-4">
        <div class="space-y-4 divide-y px-4">
          <h1 class="mt-2 text-lg font-bold text-gray-900">Detail Barang</h1>
          <div class="py-2">
            <div class="flex mt-4 gap-4">
              <div class="field-inset-default col-span-2 w-full">
                <label for="name" class="block text-xs font-bold text-gray-700">
                  Kode Barang
                </label>
                <input
                  v-model="dataStock.kode_barang"
                  @blur="searchProduct"
                  type="text"
                  name="name"
                  id="name"
                  class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                  placeholder="ex: PCR"
                />
              </div>
              <div class="field-inset-default col-span-5 w-full">
                <label for="name" class="block text-xs font-bold text-gray-700">
                  Nama Barang
                </label>
                <input
                  v-model="dataStock.nama_barang"
                  type="text"
                  name="name"
                  id="name"
                  class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                  placeholder="ex: Pocari"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-5 rounded-lg bg-white py-4">
        <div class="space-y-4 divide-y px-4">
          <div class="flex items-center justify-between">
            <h1 class="mt-2 text-lg font-bold text-gray-900">Status Order</h1>
            <button
              @click="openStatusOrder = !openStatusOrder"
              type="button"
              class="inline-flex items-center rounded-full border border-gray-300 bg-white p-1.5 text-gray-600 shadow-sm hover:text-gray-900"
            >
              <svg
                :class="[openStatusOrder ? '' : '-rotate-90']"
                class="transform transition duration-200 ease-in-out"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.29289 7.29289C5.68342 6.90237 6.31658 6.90237 6.7071 7.29289L9.99999 10.5858L13.2929 7.29289C13.6834 6.90237 14.3166 6.90237 14.7071 7.29289C15.0976 7.68342 15.0976 8.31658 14.7071 8.70711L10.7071 12.7071C10.3166 13.0976 9.68341 13.0976 9.29289 12.7071L5.29289 8.70711C4.90237 8.31658 4.90237 7.68342 5.29289 7.29289Z"
                />
              </svg>
            </button>
          </div>
          <div class="py-2">
            <div
              v-if="openStatusOrder"
              class="flex mt-4 sm:flex-row sm:gap-x-4"
            >
              <div class="w-full">
                <label
                  for="name"
                  class="mb-4 block text-sm font-bold text-gray-700"
                >
                  Penjualan Pusat
                </label>
                <toggle-button
                  :value="true"
                  v-model="dataStock.statusOrder.penjualan_pusat"
                  :labels="{
                    checked: 'Open',
                    unchecked: 'Close',
                  }"
                  :width="60"
                />
              </div>
              <div class="w-full">
                <label
                  for="name"
                  class="mb-4 block text-sm font-bold text-gray-700"
                >
                  KP
                </label>
                <toggle-button
                  :value="true"
                  v-model="dataStock.statusOrder.kp"
                  :labels="{
                    checked: 'Open',
                    unchecked: 'Close',
                  }"
                  :width="60"
                />
              </div>
              <div class="w-full">
                <label
                  for="name"
                  class="mb-4 block text-sm font-bold text-gray-700"
                >
                  SC
                </label>
                <toggle-button
                  :value="true"
                  v-model="dataStock.statusOrder.sc"
                  :labels="{
                    checked: 'Open',
                    unchecked: 'Close',
                  }"
                  :width="60"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-5 rounded-lg bg-white py-4">
        <div class="space-y-4 divide-y px-4">
          <div class="flex items-center justify-between">
            <h1 class="mt-2 text-lg font-bold text-gray-900">
              Status Pembatasan Jumlah Order
            </h1>
            <button
              @click="openStatusPembatasan = !openStatusPembatasan"
              type="button"
              class="inline-flex items-center rounded-full border border-gray-300 bg-white p-1.5 text-gray-600 shadow-sm hover:text-gray-900"
            >
              <svg
                :class="[openStatusPembatasan ? '' : '-rotate-90']"
                class="transform transition duration-200 ease-in-out"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.29289 7.29289C5.68342 6.90237 6.31658 6.90237 6.7071 7.29289L9.99999 10.5858L13.2929 7.29289C13.6834 6.90237 14.3166 6.90237 14.7071 7.29289C15.0976 7.68342 15.0976 8.31658 14.7071 8.70711L10.7071 12.7071C10.3166 13.0976 9.68341 13.0976 9.29289 12.7071L5.29289 8.70711C4.90237 8.31658 4.90237 7.68342 5.29289 7.29289Z"
                />
              </svg>
            </button>
          </div>
          <div class="py-2">
            <div
              v-if="openStatusPembatasan"
              class="flex mt-4 sm:flex-row sm:gap-x-4"
            >
              <div class="w-full">
                <label
                  for="name"
                  class="mb-4 block text-sm font-bold text-gray-700"
                >
                  Penjualan Pusat
                </label>
                <div class="mt-2 block text-sm text-gray-700">
                  <input
                    v-model="dataStock.statusPembatasan.penjualan_pusat"
                    type="radio"
                    name="status_pembatasan_penjualan"
                    :value="true"
                    class="mr-2 h-4 w-4 cursor-pointer border-gray-300 text-gray-600 focus:ring-gray-500"
                  />
                  Unlimited
                </div>
                <div
                  class="mt-2 inline-flex items-center text-sm text-gray-700"
                >
                  <input
                    v-model="dataStock.statusPembatasan.penjualan_pusat"
                    type="radio"
                    name="status_pembatasan_penjualan"
                    :value="dataStock.statusPembatasan.pp_limit"
                    class="mr-2 h-4 w-4 cursor-pointer border-gray-300 text-gray-600 focus:ring-gray-500"
                  />
                  <label for="limited">
                    <input
                      type="number"
                      @input="
                        dataStock.statusPembatasan.pp_limit =
                          $event.target.value
                      "
                      class="block w-full border-0 border-b border-transparent px-1 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                      placeholder="masukkan batas"
                    />
                  </label>
                </div>
              </div>
              <div class="w-full">
                <label
                  for="name"
                  class="mb-4 block text-sm font-bold text-gray-700"
                >
                  KP
                </label>
                <div class="mt-2 block text-sm text-gray-700">
                  <input
                    v-model="dataStock.statusPembatasan.kp"
                    type="radio"
                    name="status_pembatasan_kp"
                    :value="true"
                    class="mr-2 h-4 w-4 cursor-pointer border-gray-300 text-gray-600 focus:ring-gray-500"
                  />
                  Unlimited
                </div>
                <div
                  class="mt-2 inline-flex items-center text-sm text-gray-700"
                >
                  <input
                    v-model="dataStock.statusPembatasan.kp"
                    type="radio"
                    name="status_pembatasan_kp"
                    :value="dataStock.statusPembatasan.kp_limit"
                    class="mr-2 h-4 w-4 cursor-pointer border-gray-300 text-gray-600 focus:ring-gray-500"
                  />
                  <label for="limited">
                    <input
                      type="number"
                      @input="
                        dataStock.statusPembatasan.kp_limit =
                          $event.target.value
                      "
                      class="block w-full border-0 border-b border-transparent px-1 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                      placeholder="masukkan batas"
                    />
                  </label>
                </div>
              </div>
              <div class="w-full">
                <label
                  for="name"
                  class="mb-4 block text-sm font-bold text-gray-700"
                >
                  SC
                </label>
                <div class="mt-2 block text-sm text-gray-700">
                  <input
                    v-model="dataStock.statusPembatasan.sc"
                    type="radio"
                    name="status_pembatasan_sc"
                    :value="true"
                    class="mr-2 h-4 w-4 cursor-pointer border-gray-300 text-gray-600 focus:ring-gray-500"
                  />
                  Unlimited
                </div>
                <div
                  class="mt-2 inline-flex items-center text-sm text-gray-700"
                >
                  <input
                    v-model="dataStock.statusPembatasan.sc"
                    type="radio"
                    name="status_pembatasan_sc"
                    :value="dataStock.statusPembatasan.sc_limit"
                    class="mr-2 h-4 w-4 cursor-pointer border-gray-300 text-gray-600 focus:ring-gray-500"
                  />
                  <label for="limited">
                    <input
                      type="number"
                      @input="
                        dataStock.statusPembatasan.sc_limit =
                          $event.target.value
                      "
                      class="block w-full border-0 border-b border-transparent px-1 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                      placeholder="masukkan batas"
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-5 rounded-lg bg-white py-4">
        <div class="space-y-4 divide-y px-4">
          <div class="flex items-center justify-between">
            <h1 class="mt-2 text-lg font-bold text-gray-900">
              Status Pre-Order (Indent)
            </h1>
            <toggle-button
              @change="onPreOrder"
              :value="false"
              v-model="dataStock.statusPreorder.status"
              :labels="{ checked: 'Open', unchecked: 'Close' }"
              :width="60"
            />
          </div>
          <div class="py-2">
            <div
              v-if="openStatusPreorder"
              class="flex mt-4 sm:flex-row sm:gap-x-4"
            >
              <div class="w-full">
                <label
                  for="name"
                  class="mb-4 block text-sm font-bold text-gray-700"
                >
                  Penjualan Pusat
                </label>
                <div class="mt-2 block text-sm text-gray-700">
                  <input
                    v-model="dataStock.statusPreorder.penjualan_pusat"
                    type="radio"
                    name="status_pembatasan_penjualan"
                    :value="true"
                    class="mr-2 h-4 w-4 cursor-pointer border-gray-300 text-gray-600 focus:ring-gray-500"
                  />
                  Unlimited
                </div>
                <div
                  class="mt-2 inline-flex items-center text-sm text-gray-700"
                >
                  <input
                    v-model="dataStock.statusPreorder.penjualan_pusat"
                    type="radio"
                    name="status_pembatasan_penjualan"
                    :value="dataStock.statusPreorder.pp_limit"
                    class="mr-2 h-4 w-4 cursor-pointer border-gray-300 text-gray-600 focus:ring-gray-500"
                  />
                  <label for="limited">
                    <input
                      type="number"
                      @input="
                        dataStock.statusPreorder.pp_limit = $event.target.value
                      "
                      class="block w-full border-0 border-b border-transparent px-1 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                      placeholder="masukkan batas"
                    />
                  </label>
                </div>
              </div>
              <div class="w-full">
                <label
                  for="name"
                  class="mb-4 block text-sm font-bold text-gray-700"
                >
                  KP
                </label>
                <div class="mt-2 block text-sm text-gray-700">
                  <input
                    v-model="dataStock.statusPreorder.kp"
                    type="radio"
                    name="status_pembatasan_kp"
                    :value="true"
                    class="mr-2 h-4 w-4 cursor-pointer border-gray-300 text-gray-600 focus:ring-gray-500"
                  />
                  Unlimited
                </div>
                <div
                  class="mt-2 inline-flex items-center text-sm text-gray-700"
                >
                  <input
                    v-model="dataStock.statusPreorder.kp"
                    type="radio"
                    name="status_pembatasan_kp"
                    :value="dataStock.statusPreorder.kp_limit"
                    class="mr-2 h-4 w-4 cursor-pointer border-gray-300 text-gray-600 focus:ring-gray-500"
                  />
                  <label for="limited">
                    <input
                      type="number"
                      @input="
                        dataStock.statusPreorder.kp_limit = $event.target.value
                      "
                      class="block w-full border-0 border-b border-transparent px-1 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                      placeholder="masukkan batas"
                    />
                  </label>
                </div>
              </div>
              <div class="w-full">
                <label
                  for="name"
                  class="mb-4 block text-sm font-bold text-gray-700"
                >
                  SC
                </label>
                <div class="mt-2 block text-sm text-gray-700">
                  <input
                    v-model="dataStock.statusPreorder.sc"
                    type="radio"
                    name="status_pembatasan_sc"
                    :value="true"
                    class="mr-2 h-4 w-4 cursor-pointer border-gray-300 text-gray-600 focus:ring-gray-500"
                  />
                  Unlimited
                </div>
                <div
                  class="mt-2 inline-flex items-center text-sm text-gray-700"
                >
                  <input
                    v-model="dataStock.statusPreorder.sc"
                    type="radio"
                    name="status_pembatasan_sc"
                    :value="dataStock.statusPreorder.sc_limit"
                    class="mr-2 h-4 w-4 cursor-pointer border-gray-300 text-gray-600 focus:ring-gray-500"
                  />
                  <label for="limited">
                    <input
                      type="number"
                      @input="
                        dataStock.statusPreorder.sc_limit = $event.target.value
                      "
                      class="block w-full border-0 border-b border-transparent px-1 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                      placeholder="masukkan batas"
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pt-2">
        <div class="flex justify-end">
          <button
            type="submit"
            class="ml-3 inline-flex rounded-md border border-gray-300 bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-600 hover:shadow-lg"
          >
            Simpan
          </button>
          <button
            type="button"
            class="bg-white-600 ml-3 rounded-md border border-gray-300 py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-white hover:shadow-lg"
          >
            Batal
          </button>
          <router-link
            to="/master/barang"
            class="ml-3 inline-flex rounded-md border border-gray-300 bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-600 hover:shadow-lg"
          >
            Selesai
          </router-link>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { ToggleButton } from 'vue-js-toggle-button';
import Loading from '@/components/Loading.vue';
export default {
  name: 'TambahBarang',
  components: {
    Loading,
    ToggleButton,
  },
  data() {
    return {
      anyProduct: false,
      openStatusOrder: false,
      openStatusPembatasan: false,
      openStatusPreorder: false,
      dataStock: {
        id: '',
        kode_barang: '',
        nama_barang: '',
        harga: '',
        nama_supplier: '',
        gudang: '',
        statusOrder: {
          status: null,
          penjualan_pusat: null,
          kp: null,
          sc: null,
        },
        statusPembatasan: {
          penjualan_pusat: null,
          pp_limit: null,
          kp: null,
          kp_limit: null,
          sc: null,
          sc_limit: null,
        },
        statusPreorder: {
          penjualan_pusat: null,
          pp_limit: null,
          kp: null,
          kp_limit: null,
          sc: null,
          sc_limit: null,
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      isLoading: 'products/getLoading',
      getProducts: 'products/getProducts',
    }),
  },
  methods: {
    ...mapActions({
      fetchProducts: 'products/fetchProducts',
    }),
    onPreOrder() {
      this.openStatusPreorder = !this.openStatusPreorder;
    },
    addStock() {},
    searchProduct() {
      if (this.dataStock.kode_barang) {
        this.fetchProducts({
          keyword: this.dataStock.kode_barang,
        }).then(() => {
          if (this.getProducts.data.length > 0) {
            this.anyProduct = true;
            this.dataStock.id = this.getProducts.data[0].id;
            this.dataStock.kode_barang =
              this.getProducts.data[0].attributes.code;
            this.dataStock.nama_barang =
              this.getProducts.data[0].attributes.name;
            this.dataStock.harga = this.getProducts.data[0].attributes.price;
          } else {
            this.anyProduct = false;
            this.dataStock.id = '';
            this.dataStock.kode_barang = '';
            this.dataStock.nama_barang = '';
            this.dataStock.harga = '';
          }
        });
      }
    },
  },
};
</script>
