<template>
  <transition
    name="customFade"
    enter-active-class="fadeIn"
    leave-active-class="fadeOut"
  >
    <div
      v-if="openListKantor"
      class="fixed inset-0 z-10 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <span
          class="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl sm:p-6 sm:align-middle"
        >
          <div class="flex justify-between px-4 py-5 sm:px-6">
            <div>
              <h3 class="text-lg font-bold leading-6 text-gray-900">
                Daftar Kantor
              </h3>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                Berdasarkan data kantor yang telah dibuat
              </p>
            </div>
            <div>
              <img src="@/assets/images/logo.png" alt="" class="w-52" />
            </div>
          </div>
          <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
            <div class="flex justify-end">
              <base-search
                v-model="search"
                @input="debounceSearch"
                placeholder="Cari kode atau nama"
              />
            </div>
            <div class="flex my-2 sm:-mx-6 lg:-mx-8">
              <div
                class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
              >
                <Datatable
                  :total="getOffices.meta?.page.total"
                  :perPage="getOffices.meta?.page.perPage"
                  :currentPage="getOffices.meta?.page.currentPage"
                  :meta="getOffices.meta"
                  cursor
                  @pagechanged="onPageChanged"
                >
                  <template v-slot:thead>
                    <thead class="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                        >
                          Kode Kantor
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                        >
                          Nama Kantor
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                        >
                          Alamat
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                        >
                          No. Telp
                        </th>
                      </tr>
                    </thead>
                  </template>
                  <template v-slot:tbody>
                    <tbody>
                      <tr
                        class="cursor-pointer bg-white hover:bg-green-100"
                        v-for="(data, dataIdx) in getOffices.data"
                        :key="data.id"
                        :title="
                          !!getOfficeIncluded(data).attributes
                            .is_order_disabled &&
                          'Tidak dapat melakukan pembelian untuk kantor ini dikarenakan periode tutup buku'
                        "
                        :class="[
                          !!getOfficeIncluded(data).attributes.is_order_disabled
                            ? 'cursor-not-allowed opacity-50'
                            : '',
                          dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50',
                        ]"
                        @click="
                          !!getOfficeIncluded(data).attributes.is_order_disabled
                            ? null
                            : $emit('change-office', getOfficeIncluded(data))
                        "
                      >
                        <td
                          class="whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
                        >
                          {{ getOfficeIncluded(data).attributes.code }}
                        </td>
                        <td
                          class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                        >
                          {{ getOfficeIncluded(data).attributes.name }}
                        </td>
                        <td
                          class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                        >
                          {{ getOfficeIncluded(data).attributes.address }}
                        </td>
                        <td
                          class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                        >
                          {{ getOfficeIncluded(data).attributes.phone }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </Datatable>
              </div>
            </div>
          </div>
          <div class="flex justify-end">
            <button
              @click="onCloseModal"
              type="button"
              class="focus:outline-none inline-flex justify-end rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
            >
              Tutup
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Datatable from '../base/Datatable.vue';
import debounce from 'debounce';
import BaseSearch from '@/components/base/Search.vue';

export default {
  name: 'KantorModal',
  data() {
    return {
      search: null,
    };
  },
  components: {
    Datatable,
    BaseSearch,
  },
  props: {
    openListKantor: Boolean,
  },
  computed: {
    ...mapGetters({
      isLoading: 'suppliers/getLoading',
      getSuppliers: 'suppliers/getSuppliers',
      getProductsByOffice: 'products/getProductsByOffice',
      getError: 'purchaseorders/getError',
      getSetups: 'setups/getSetups',
      getOrder: 'orders/getOrder',
      getOrders: 'orders/getOrders',
      getOffices: 'simple_offices/getSimpleOffices',
      getWarehouseByOffice: 'warehouses/getWarehouseByOffice',
      getStocks: 'stocks/getStocks',
      getWarehouseStock: 'warehouses/getWarehouseStock',
    }),
  },
  methods: {
    ...mapActions({
      fetchSetups: 'setups/fetchSetups',
      fetchSuppliers: 'suppliers/fetchSupplier',
      fetchProductsByOffice: 'products/fetchProductsByOffice',
      fetchBuyerTypes: 'buyer_types/fetchBuyerTypes',
      fetchAreas: 'areas/fetchAreas',
      fetchOffices: 'offices/fetchOffices',
      fetchWarehouseByOffice: 'warehouses/fetchWarehouseByOffice',
      fetchOrder: 'orders/fetchOrder',
      fetchOrders: 'orders/fetchOrders',
      updateOrder: 'orders/updateOrder',
      fetchOfficesByOfficeCategory:
        'office_categories/fetchOfficesByOfficeCategory',
      fetchAllOfficeCategory: 'office_categories/fetchAllOfficeCategory',
      fetchStocksByWarehouse: 'stocks/fetchStocksByWarehouse',
      fetchWarehouseStock: 'warehouses/fetchWarehouseStock',
    }),

    debounceSearch: debounce(function () {
      this.$emit('onSearchKantor', this.search);
    }, 300),

    onPageChanged(page) {
      this.$emit('onPageChangeKantor', page);
    },

    onCloseModal() {
      this.search = '';
      this.$emit('closeKantorModal');
    },

    getOfficeIncluded(data) {
      return this.getSingleIncluded(
        this.getOffices,
        data.relationships.office.data.id
      );
    },
  },
};
</script>
