<template>
  <div class="transition duration-300 ease-in-out">
    <div>
      <div class="mb-4 flex justify-between">
        <div>
          <h3 class="text-lg font-medium text-gray-900">Layanan Ekspedisi</h3>
          <p class="text-sm text-gray-500">
            Pilih layanan ekspedisi yang akan digunakan untuk mengirim barang
          </p>
        </div>
      </div>

      <div>
        <Datatable
          with-overflow
          :total="getExpeditions.meta.page.total"
          :perPage="getExpeditions.meta.page.perPage"
          :currentPage="getExpeditions.meta.page.currentPage"
          @pagechanged="onPageChange"
          emptyMessage="hello world"
          :is-empty="!getExpeditions.data?.length"
        >
          <template v-slot:empty>
            Belum Memilih Master Ekspedisi
            <RouterLink
              class="text-blue-600 hover:underline"
              :to="{
                name: 'expedition.index',
              }"
            >
              Klik disini
            </RouterLink>
          </template>
          <template v-slot:thead>
            <thead class="bg-gray-50">
              <tr>
                <th
                  v-for="item in [
                    {
                      label: 'Kode Expedisi',
                    },
                    {
                      label: 'Nama Expedisi',
                    },
                    {
                      label: '',
                      classes: 'w-12',
                    },
                  ]"
                  scope="col"
                  :key="item.label"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  :class="item.classes"
                >
                  {{ item.label }}
                </th>
              </tr>
            </thead>
          </template>
          <template v-slot:tbody>
            <tbody v-if="getExpeditions.data?.length">
              <template v-for="(data, dataIdx) in getExpeditions.data">
                <tr
                  :key="data.id"
                  @click="showChildren(data)"
                  class="cursor-pointer bg-white hover:bg-green-100"
                  :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                >
                  <td
                    class="flex cursor-pointer items-center gap-x-2 whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
                  >
                    {{ data.attributes.code }}
                    <span v-if="data.attributes.is_global" class="tooltip">
                      <Icon
                        class="h-5 w-5 text-blue-600"
                        icon="heroicons:check-badge-20-solid"
                      />
                      <p class="tooltiptext">Terverifikasi NASA</p>
                    </span>
                    <img
                      v-if="data.attributes.logo"
                      :src="data.attributes.logo"
                      class="h-5"
                    />
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    {{ data.attributes.name }}
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    <Icon
                      class="h-6 w-6 transition-all"
                      :class="
                        data.id == selectedExpedition ? 'rotate-180' : null
                      "
                      icon="heroicons:chevron-down-20-solid"
                    />
                  </td>
                </tr>
                <template
                  v-if="
                    data.id == selectedExpedition &&
                    getExpeditionDetails.data.length &&
                    !loading.getExpeditionDetails
                  "
                >
                  <tr
                    v-for="(item, index) in getExpeditionDetails.data"
                    :key="index"
                  >
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ item.attributes.address }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ item.attributes.phone?.join(', ') }}
                    </td>
                    <td class="whitespace-nowrap py-4 text-sm text-gray-500">
                      <div class="h-full">
                        <toggle-button
                          @change="handleChangeActive(data, item)"
                          v-model="selectedExpeditionDetails[item.id]"
                          :labels="{
                            checked: 'Open',
                            unchecked: 'Close',
                          }"
                          :width="60"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
                <template
                  v-else-if="
                    data.id == selectedExpedition &&
                    getExpeditionDetails.data.length == 0
                  "
                >
                  <td
                    :key="dataIdx"
                    class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                    colspan="6"
                  >
                    Tidak ada alamat yang tersedia
                  </td>
                </template>
              </template>
            </tbody>
          </template>
        </Datatable>
      </div>
    </div>
    <office-expedition-form
      :visible="officeExpeditionFormProps.visible"
      @close="officeExpeditionFormProps.visible = false"
      @success="onSuccessForm"
      :expedition="officeExpeditionFormProps.expedition"
    />
  </div>
</template>
<script>
import Datatable from '@/components/base/Datatable';
import { ToggleButton } from 'vue-js-toggle-button';
import { mapActions, mapGetters } from 'vuex';
import { StorageService } from '@/services/storage.service';
import OfficeExpeditionForm from '@/components/office-expedition/office-expedition-form.vue';

export default {
  name: 'SetupEkspedisi',
  components: {
    Datatable,
    ToggleButton,
    OfficeExpeditionForm,
  },
  data() {
    return {
      selectedExpedition: '',
      selectedExpeditionDetails: {},
      officeExpeditionFormProps: {
        visible: false,
        expedition: {},
      },
      loading: {
        getExpeditionDetails: false,
      },
      expeditionParams: {
        params: {
          'page[size]': 5,
          'page[number]': 1,
          'filter[has_expedition_details]': true,
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      getExpeditionDetails: 'expedition_details/getExpeditionDetails',
      getExpeditions: 'expeditions/getExpeditions',
      getOfficeExpeditions: 'office_expeditions/getOfficeExpeditions',
    }),
  },
  methods: {
    ...mapActions({
      fetchExpeditions: 'expeditions/fetchExpeditions',
      fetchExpeditionDetails: 'expedition_details/fetchExpeditionDetails',
      fetchOfficeExpeditionsByOffice:
        'office_expeditions/fetchOfficeExpeditionsByOffice',
      attachToExpedition: 'office_expeditions/attachToExpedition',
    }),
    onSuccessForm() {},
    onPageChange(page) {
      this.expeditionParams['page[number]'] = page;
      this.fetchExpeditions(this.expeditionParams);
    },
    async showChildren(expedition) {
      this.loading.getExpeditionDetails = true;
      if (expedition.id == this.selectedExpedition) {
        this.selectedExpedition = '';
        return;
      }
      this.selectedExpedition = expedition.id;
      await this.fetchExpeditionDetails({ expedition_id: expedition.id });
      this.loading.getExpeditionDetails = false;
    },
    async handleChangeActive(expedition, expeditionDetail) {
      await this.attachToExpedition({
        data: {
          type: 'office-expeditions',
          attributes: {
            is_active: this.selectedExpeditionDetails[expeditionDetail.id],
          },
          relationships: {
            expeditions: {
              data: {
                id: expedition.id,
                type: 'expeditions',
              },
            },
            'expedition-details': {
              data: {
                id: expeditionDetail.id,
                type: 'expeditions-details',
              },
            },
          },
        },
      });
      this.fetchExpeditions(this.expeditionParams);
    },
  },
  created() {
    this.fetchExpeditions(this.expeditionParams);
    this.fetchOfficeExpeditionsByOffice({
      office_id: StorageService.getUser().office_id,
      params: {
        include: 'expedition-detail',
      },
    }).then((response) => {
      response.data.data.map((expedition) => {
        this.selectedExpeditionDetails[
          expedition.relationships['expedition-detail'].data?.id
        ] = expedition.attributes.isActive;
      });
    });
  },
};
</script>
