<template>
  <div class="html2pdf__page" slot="pdf-content">
    <section class="m-4 text-xs">
      <div class="relative bottom-2">
        <table class="mb-2">
          <tr>
            <td>No. Kemasan</td>
            <td class="px-4">:</td>
            <td>{{ stockMovement.code || '-' }}</td>
          </tr>
          <tr>
            <td>No. Faktur</td>
            <td class="px-4">:</td>
            <td>{{ stockMovement.order.code || '-' }}</td>
          </tr>
          <tr>
            <td>Tgl</td>
            <td class="px-4">:</td>
            <td>{{ stockMovement.date || '-' }}</td>
          </tr>
          <tr>
            <td>Kode Office Tujuan</td>
            <td class="px-4">:</td>
            <td>{{ stockMovement.order.destinationOffice || '-' }}</td>
          </tr>
        </table>

        <p v-if="stockMovement.order.address">
          {{ stockMovement.order.address }}
        </p>
        <p v-if="stockMovement.order.phone">{{ stockMovement.order.phone }}</p>

        <p class="mb-4 mt-4">
          Daftar Koli ({{ stockMovement.packets.length }} koli)
        </p>
        <table class="w-full border-t border-dashed border-gray-700">
          <tbody class="text-left">
            <tr v-for="(packet, i) in stockMovement.packets" :key="i">
              <td>{{ i + 1 }}.</td>
              <td class="text-left">
                {{ packet.code }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>

    <div class="pagebreak"></div>

    <template v-if="elements.packets ?? true">
      <section v-for="(packet, i) in stockMovement.packets" :key="i">
        <layout-print-stock-movement-packet
          :as-content="false"
          :packet="packet"
          :stock-movement="stockMovement"
        />
        <div class="pagebreak"></div>
      </section>
    </template>

    <layout-print-stock-movement-receipt
      v-if="elements.receipt ?? true"
      :stock-movement="stockMovement"
      :as-content="false"
    />
  </div>
</template>
<script>
import LayoutPrintStockMovementPacket from './LayoutPrintStockMovementPacket.vue';
import LayoutPrintStockMovementReceipt from './LayoutPrintStockMovementReceipt.vue';

export default {
  name: 'PrintKemasan',
  components: {
    LayoutPrintStockMovementPacket,
    LayoutPrintStockMovementReceipt,
  },
  props: {
    elements: {
      type: Object,
      default: () => ({
        packets: true,
        receipt: true,
      }),
    },
    stockMovement: {
      type: Object,
      default: () => ({
        code: null,
        date: null,
        order: {
          code: null,
          address: null,
          phone: null,
          destinationOffice: null,
        },
        packets: [],
      }),
    },
  },
  methods: {},
};
</script>
