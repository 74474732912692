<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div class="mb-5 rounded-lg bg-white p-4 sm:p-6 md:p-8">
      <div class="flex justify-between border-b border-gray-200 pb-5">
        <h3 class="text-lg font-bold leading-6 text-gray-900">
          Tambah Order Barang
        </h3>
        <base-badge v-if="isEditing"> Draft </base-badge>
      </div>
      <div class="py-2">
        <!-- Origin Office Category -->
        <div class="mt-6 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6">
          <div class="sm:col-span-6">
            <base-input label="Pilih Jalur Pembelian" with-label>
              <base-select
                @change="handleChangeOriginOfficeCategory"
                v-model="order.originOfficeCategory"
                :options="[
                  { label: 'Pilih Jalur Pembelian', value: null },
                  ...officeCategories.map((officeCategory) => ({
                    label: officeCategory.attributes.name,
                    value: officeCategory,
                  })),
                ]"
                id="filterMetode"
                name="filterMetode"
                :disabled="isEditing"
              />
            </base-input>
          </div>
          <!-- If Origin Office Category Selected -->
          <template v-if="order.originOfficeCategory">
            <!-- Origin Office -->
            <div class="sm:col-span-3">
              <div class="relative">
                <button
                  type="button"
                  v-if="!isEditing"
                  :disabled="isOriginPusat"
                  @click="handleOpenOriginOfficesModal"
                  class="absolute inset-y-0 top-4 right-4 z-10 flex cursor-pointer items-center"
                >
                  <Icon
                    class="h-5 w-5 text-gray-500 hover:text-gray-700"
                    icon="heroicons:magnifying-glass-circle-20-solid"
                  />
                </button>
                <base-input
                  v-model="searchOriginOffice"
                  ref="focusNamaSupplier"
                  @blur="handleChangeSearchOriginOffice"
                  type="text"
                  name="name"
                  id="name"
                  inset
                  with-label
                  label="Kode Kantor Tujuan"
                  placeholder="Masukkan Nama Barang"
                  :disabled="isEditing || isOriginPusat"
                />
              </div>
            </div>
            <div class="sm:col-span-3">
              <base-input
                :value="order.originOffice?.attributes.name ?? ''"
                readonly
                inset
                type="text"
                name="nama_supplier"
                id="nama_supplier"
                label="Nama Kantor"
                disabled
              />
            </div>
            <!-- Origin Warehouse -->
            <div class="sm:col-span-3">
              <div class="relative">
                <button
                  v-if="!isDisabledSelectWarehouse"
                  type="button"
                  @click="handleOpenOriginWarehousesModal"
                  class="absolute inset-y-0 top-4 right-4 z-10 flex cursor-pointer items-center"
                >
                  <Icon
                    class="h-5 w-5 text-gray-500 hover:text-gray-700"
                    icon="heroicons:magnifying-glass-circle-20-solid"
                  />
                </button>
                <base-input
                  v-model="searchOriginWarehouse"
                  ref="focusNamaSupplier"
                  @blur="handleChangeSearchOriginWarehouse"
                  type="text"
                  name="name"
                  id="name"
                  inset
                  label="Kode Gudang Tujuan"
                  placeholder="Masukkan Nama Barang"
                  :disabled="isDisabledSelectWarehouse"
                />
              </div>
            </div>
            <div class="sm:col-span-3">
              <base-input
                inset
                label="Wilayah Harga"
                v-model="searchAreaCode"
                @change="handleChangeArea"
                type="text"
                name="wilayah_harga"
                id="wilayah_harga"
                placeholder="Masukkan Wilayah Harga"
                :disabled="true"
              />
            </div>
            <!-- Order Shipment -->
            <div class="sm:col-span-6">
              <label for="name" class="text-xs font-bold text-gray-700">
                Metode Pengiriman
              </label>
              <div class="my-3 flex items-center space-x-8">
                <div
                  class="cursor-pointer items-center text-sm text-gray-700"
                  v-if="
                    isEditing
                      ? order.attributes.order_shipment === 'pickup'
                      : true
                  "
                >
                  <input
                    v-model="order.attributes.order_shipment"
                    @change="handleChangeOrderShipment"
                    type="radio"
                    id="diambil"
                    name="metode_pengiriman"
                    value="pickup"
                    class="mr-1 h-4 w-4 border-gray-300 text-gray-600 focus:ring-gray-500"
                    aria-labelledby="privacy-setting-1-label"
                    aria-describedby="privacy-setting-1-description"
                    :class="[
                      isEditing
                        ? 'text-gray-500 opacity-50'
                        : 'text-indigo-600',
                    ]"
                    :disabled="isEditing"
                  />
                  <label for="diambil"> Diambil </label>
                </div>
                <div
                  class="cursor-pointer items-center text-sm text-gray-700"
                  v-if="
                    isEditing
                      ? order.attributes.order_shipment === 'delivery'
                      : true
                  "
                >
                  <input
                    v-model="order.attributes.order_shipment"
                    @change="handleChangeOrderShipment"
                    type="radio"
                    id="dikirim"
                    name="metode_pengiriman"
                    value="delivery"
                    class="mr-1 h-4 w-4 border-gray-300 text-gray-600 focus:ring-gray-500"
                    aria-labelledby="privacy-setting-1-label"
                    aria-describedby="privacy-setting-1-description"
                    :class="[
                      isEditing
                        ? 'text-gray-500 opacity-50'
                        : 'text-indigo-600',
                    ]"
                    :disabled="isEditing"
                  />
                  <label for="dikirim"> Dikirim </label>
                </div>
              </div>
              <!-- Order Destination Address -->
              <div
                v-if="
                  order.attributes.order_shipment === 'delivery' &&
                  order.destinationOffice.attributes.name
                "
                class="my-6"
              >
                <h3 class="text-xs font-bold text-gray-700">
                  Alamat Pengiriman
                </h3>
                <div v-if="usingCustomAddress" class="divide-y-2">
                  <p class="mt-2 mb-3 text-gray-600">
                    {{
                      order.relationships.destinationAddress.attributes.alamat
                    }},
                    {{
                      order.relationships.destinationAddress.attributes.village
                        .name
                    }},
                    {{
                      order.relationships.destinationAddress.attributes.district
                        .name
                    }},
                    {{
                      order.relationships.destinationAddress.attributes.city
                        .name
                    }},
                    {{
                      order.relationships.destinationAddress.attributes.province
                        .name
                    }}
                  </p>
                  <div class="space-4">
                    <base-button
                      type="button"
                      color="white"
                      @click="handleOpenAddressModal"
                    >
                      Ganti Alamat
                    </base-button>
                    <base-button
                      type="button"
                      color="white"
                      @click="handleClearAddress"
                    >
                      Gunakan Alamat Lama
                    </base-button>
                  </div>
                </div>
                <div v-else class="divide-y-2">
                  <p class="mt-2 mb-3 text-gray-600">
                    {{ order.destinationOffice.attributes?.address }}
                  </p>
                  <div v class="py-3">
                    <base-button
                      v-if="!isEditing"
                      type="button"
                      color="white"
                      @click="handleOpenAddressModal"
                    >
                      Pilih Alamat Lain
                    </base-button>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <template v-if="order.originOffice">
      <OrderDetailTable
        class="mb-5"
        :create-order-payload="{
          order_type: 'purchase',
          is_ppn_included: order.attributes.is_ppn_included,
          order_shipment: order.attributes.order_shipment,
          destination_address: usingCustomAddress
            ? {
                province_id:
                  order.relationships.destinationAddress.attributes.province.id,
                city_id:
                  order.relationships.destinationAddress.attributes.city.id,
                district_id:
                  order.relationships.destinationAddress.attributes.district.id,
                village_id:
                  order.relationships.destinationAddress.attributes.village.id,
                address:
                  order.relationships.destinationAddress.attributes.alamat,
              }
            : undefined,
        }"
        :origin-warehouse="{
          id: order.originWarehouse?.id,
        }"
        :destination-warehouse="{
          id: order.destinationWarehouse?.id,
        }"
        :origin-office="{
          id: order.originOffice?.id,
          code: order.originOffice?.attributes.code,
        }"
        :destination-office="{
          id: order.destinationOffice?.id,
          code: order.destinationOffice?.attributes.code,
        }"
        :buyer-type="{
          id: order.buyerType?.id,
          code: order.buyerType?.attributes.code,
        }"
        :area="{
          id: order.area?.id,
          code: order.area?.attributes.code,
        }"
        :order-id="order?.id"
        :min-grand-total="minGrandTotalToProcess"
        :origin-office-category-type="
          order.originOfficeCategory.attributes.name
        "
        :with-cashback-warning="
          canDestinationOfficeHaveCashback
        "
        v-model="orderDetails"
        v-on:order-created="handleCreatedOrder"
        v-on:order-updated="handleUpdatedOrder"
      />

      <OrderPromoList class="mb-5" :order-details="orderDetailsForPromoList" :product-bundles="productBundles" @change-selected-bundles="onChangeSelectedBundles" @change-choosen-bonuses="onChangeChoosenBonuses" />

      <!-- Order Details Data -->
      <div class="mb-5">
        <OrderSummary
          :totalbarang="orderDetailsCount"
          :dpp="dpp"
          :ppn="ppn"
          :totalharga="totalPrice"
          :cashback="cashback"
          :with-cashback="canDestinationOfficeHaveCashback"
          :balance="totalBalance"
        />
      </div>
      <!-- Metode Pembayaran -->
      <div v-if="viewAs == 'stockist_center'" class="mb-5">
        <PaymentMethodSaldoForm
          v-model="paymentMethods"
          :balanceUsedTypes="balanceUsedTypes"
        />
      </div>
      <div
        v-else-if="orderDetails.length > 1"
        class="mb-5 rounded-lg bg-white p-4 sm:p-6 md:p-8"
      >
        <div class="pb-5">
          <h3 class="text-lg font-bold leading-6 text-gray-900">
            Metode Pembayaran
          </h3>
        </div>
        <OrderBalanceUsed
          v-if="
            order.originOfficeCategory.attributes.name.toLowerCase() === 'pusat'
          "
          :grand-total-price="totalPrice"
        />

        <PaymentMethodInlineForm
          :payment-id="getIncludedByType(getOrder, 'payments')[0].id"
          auto-store
          v-model="paymentMethodsForm"
        />
      </div>
      <!-- End Metode Pembayaran -->
    </template>

    <!-- Actions -->
    <div class="pt-5">
      <div class="flex justify-end">
        <button
          @click="handleProcessOrder"
          class="inline-flex rounded-md border border-gray-300 bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-600 hover:shadow-lg disabled:bg-gray-300"
          :disabled="totalPrice < minGrandTotalToProcess"
        >
          Siap, Validasi
        </button>
        <router-link
          :to="indexPath"
          class="ml-3 inline-flex rounded-md border border-gray-300 bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-600 hover:shadow-lg"
        >
          Selesai
        </router-link>
      </div>
    </div>

    <!-- Modal kantor -->
    <KantorModal
      :openListKantor="visibleOriginOfficeModal"
      @change-office="handleSelectOriginOfficesModal"
      @onPageChangeKantor="handleChangePageOriginOfficesModal"
      @onSearchKantor="handleSearchOriginOfficesModal"
      @closeKantorModal="handleCloseOriginOfficesModal"
    />

    <!--  Modal gudang -->
    <WarehouseModal
      :visible="visibleOriginWarehouseModal"
      @close="() => (visibleOriginWarehouseModal = false)"
      @search="handleSearchOriginWarehousesModal"
      @page-change="handleChangePageOriginWarehousesModal"
      @change="handleSelectOriginWarehousesModal"
      :fetch-by-office="!isOriginPusat"
    />

    <!-- Modal Product -->
    <BarangModal
      :openListBarang="visibleProductModal"
      :is-edit-form="isEditing"
      @changeSelectedBarang="handleSelectProductsModal"
      @onPageChangeBarang="handleChangePageProductsModal"
      @closeBarangModal="handleCloseProductsModal"
      @debounceSearchProduct="handleSearchProductsModal"
    />

    <!-- OrderDetailModal -->
    <OrderDetailModal
      :visible="visibleDetailModal"
      :order="getOrder.data"
      :editable="true"
      :deleteable="false"
      with-bonus
      @close="handleCloseDetailModal"
      @refresh="handleCloseDetailModal"
      @edit="handleEditDetailModal"
    />
    <!-- Address Modal -->
    <AddressModal
      v-model="order"
      :visible="addressModal.visible"
      @close="handleCloseAddress"
      @change="handleChangeAddress"
      :destinationOffice="{
        id: StorageService.getUser().office_id,
      }"
    />

    <!-- Modal Action -->
    <!-- Sccess Modal -->
    <div>
      <transition name="fade">
        <div
          v-if="visibleSuccessModal"
          class="fixed inset-0 z-10 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <modal :actions="false" :link="indexPath">
            <template v-slot:svg>
              <div
                class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100"
              >
                <svg
                  class="h-6 w-6 text-green-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
            </template>
            <template v-slot:heading>
              <h3
                class="text-lg font-bold leading-6 text-gray-900"
                id="modal-title"
              >
                Data Berhasil disimpan
              </h3>
            </template>
            <template v-slot:actions>
              <base-button type="button" fullwidth @click="handleClickReorder">
                Tambah Lagi
              </base-button>
              <base-button
                type="button"
                fullwidth
                color="danger"
                @click="handleFinishOrder"
              >
                Selesai
              </base-button>
            </template>
          </modal>
        </div>
      </transition>
    </div>
    <BaseModal
      :showing="visibleUploadModal"
      @close="handleUploadModal"
      :showClose="visibleUploadModal"
      :backgroundClose="true"
      size="max-w-lg"
    >
      <div class="field-inset-default">
        <label class="mb-2 block text-xs font-bold text-gray-700">
          Bukti File(wajib bertipe jpg, png)
        </label>
        <FilePond @onAddFile="onAddFile" />
      </div>
    </BaseModal>
    <loading v-if="loading.visible" :text="loading.text"></loading>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { StorageService } from '@/services/storage.service';
import Modal from '@/components/Modal.vue';
import BaseModal from '@/components/base/BaseModal';
// import BaseInput from '@/components/base/BaseInput.vue';
import KantorModal from '@/components/pembelian/KantorModal.vue';
import WarehouseModal from '@/components/modal/warehouse-modal.vue';
import BarangModal from '@/components/pembelian/BarangModal.vue';
import OrderDetailModal from '@/components/order/OrderDetailModal.vue';
import dayjs from 'dayjs';
import ApiService from '@/services/api.service';
import debounce from 'debounce';
import FilePond from '@/components/base/FilePond.vue';
import OrderDetailTable from '@/components/order/create/OrderDetailTable.vue';
import PaymentMethodInlineForm from '@/components/payment/PaymentMethodInlineForm';
import { loadingMixin } from '@/mixins/loading/loading.mixin';
import { orderDetailsFormMixin } from '@/mixins/order/order-details-form.mixin';
import { paymentMethodFormMixin } from '@/mixins/payment-method/payment-method-form.mixin';
import OrderBalanceUsed from '@/components/order/OrderBalanceUsed.vue';
import { cashbackMixin } from '@/mixins/office-category/cashback.mixin';
import OrderSummary from '@/components/order/OrderSummary.vue';
import AddressModal from '@/components/order/create/AddressModal.vue';
import PaymentMethodSaldoForm from '../../payment/PaymentMethodSaldoForm.vue';
import { productBundleMixins } from '@/mixins/product-bundle/product-bundle.mixin';
import OrderPromoList from '@/components/promo/OrderPromoList.vue';

export default {
  name: 'OrderPurchaseForm',
  mixins: [
    loadingMixin,
    orderDetailsFormMixin,
    paymentMethodFormMixin,
    cashbackMixin,
    productBundleMixins
  ],
  components: {
    KantorModal,
    WarehouseModal,
    BarangModal,
    OrderDetailModal,
    Modal,
    BaseModal,
    FilePond,
    OrderDetailTable,
    PaymentMethodInlineForm,
    OrderBalanceUsed,
    OrderSummary,
    AddressModal,
    PaymentMethodSaldoForm,
    OrderPromoList
  },
  props: {
    indexPath: {
      default: '/gudang/pembelian',
    },
    viewAs: {
      default: 'stockist',
    },
    allowEditPusatWarehouse: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      StorageService,
      paymentMethods:
        this.viewAs == 'stockist_center'
          ? [
              {
                id: null,
                isUsed: false,
                balance_used: 'restock',
                amount: null,
              },
              {
                id: null,
                isUsed: false,
                balance_used: 'multiplied_deposit',
                amount: null,
              },
            ]
          : [
              {
                id: 'cash',
                name: 'Cash',
                value: 'cash',
              },
              {
                id: 'bank',
                name: 'Bank',
                value: 'bank',
              },
              {
                id: 'stockist',
                name: 'Saldo Stockist',
                value: 'stockist',
              },
            ],
      paymentMethodsForm: [],
      usingCustomAddress: false,
      minGrandTotalToProcess: 300000,
      ppnPercentage: null,
      order: {
        id: null,
        attributes: {
          createdAt: null,
          is_ppn_included: true,
          order_shipment: 'pickup',
          destination_address: {
            village: null,
            city: null,
            address: null,
            district: null,
            province: null,
          },
          notes: '',
        },
        area: null,
        buyerType: null,
        destinationOffice: null,
        destinationWarehouse: null,
        destinationArea: null,
        originOfficeCategory: null,
        originOffice: null,
        originWarehouse: null,
        orderDetails: [
          {
            search_product_code: null,
            product_code: null,
            product_name: null,
            product_qty: null,
            product_price: null,
            total_price: null,
            stock_real: null,
          },
        ],
        paymentDetails: [
          {
            paymentMethod: null,
            office_bank_id: null,
            payment_amount: 0,
            files: [],
          },
        ],
        relationships: {
          orderDetails: {
            data: [],
          },
          paymentMethods: {
            data: [],
          },
          originWarehouse: {
            attributes: {
              area_code: null,
            },
          },
          destinationAddress: {
            attributes: {
              province: {
                id: null,
                name: null,
              },
              city: {
                id: null,
                name: null,
                area: null,
              },
              district: {
                id: null,
                name: null,
              },
              village: {
                id: null,
                name: null,
              },
              alamat: '',
            },
          },
        },
      },
      searchOriginOffice: null,
      searchOriginWarehouse: null,
      searchAreaCode: null,
      searchProduct: null,
      visibleOriginOfficeModal: false,
      visibleOriginWarehouseModal: false,
      visibleProductModal: false,
      visibleSuccessModal: false,
      visibleFailedModal: false,
      visibleDetailModal: false,
      addressModal: {
        visible: false,
      },
      visibleUploadModal: false,
      productModalSelectedProductIndex: 0,
      uploadModalIndex: 0,
      selectedBundles: [],
      choosenBonuses: {}
    };
  },
  computed: {
    ...mapGetters({
      getOrdersLoading: 'orders/getLoading',
      getOfficesLoading: 'offices/getLoading',
      getOffice: 'offices/getOffice',
      getSimpleOffices: 'simple_offices/getSimpleOffices',
      getWarehousesLoading: 'warehouses/getLoading',
      getOrder: 'orders/getOrder',
      getOfficeCategories: 'office_categories/getOfficeCategories',
      getBanksByOffice: 'banks/getBanksByOffice',
      getProvinces: 'indonesia/getProvinces',
      getCities: 'indonesia/getCities',
      getDistricts: 'indonesia/getDistricts',
      getVillages: 'indonesia/getVillages',
      getError: 'orders/getError',
      getPaymentMethods: 'payments/getPaymentMethods',
      getAll: 'main/getAll',
    }),
    orderDetailsForPromoList() {
      return this.orderDetails
        .filter(item => item.product.id)
        .map(item => ({
          productId: item.product.id,
          qty: item.qty,
          productCode: item.product.code 
        }))
    },
    productBundles() {
      return this.getAll['product-bundles']?.data ?? [];
    },
    balanceUsedTypes() {
      return {
        restock: {
          label: 'Saldo Restock',
          value: this.order.destinationOffice?.attributes.restock_balance ?? 0,
        },
        multiplied_deposit: {
          label: 'Nilai Deposit Awal / Tambahan',
          value:
            this.order.destinationOffice.attributes
              .multiplied_deposit_balance ?? 0,
        },
      };
    },
    canDestinationOfficeHaveCashback() {
      if (this.getOrder.data.attributes.order_shipment !== 'pickup') {
        return false
      }

      if (this.order.destinationOffice.attributes.office_type !== 'stockist') {
        return false
      }

      return true
    },
    isOriginPusat() {
      return this.order.originOffice?.attributes.code == 'PUSAT';
    },
    isEditing: function () {
      return !!this.order.id;
    },
    isDisabledSelectWarehouse: function () {
      if (this.isEditing) {
        return true
      }

      if (this.order.originOfficeCategory.attributes.name !== 'Pusat') {
        return true
      }

      return !this.allowEditPusatWarehouse
    },
    orderAreaCode: function () {
      return this.order.attributes.order_shipment !== 'pickup'
        ? this.usingCustomAddress
          ? Math.max(
              this.order.relationships.destinationAddress.attributes.city.area,
              this.order.originWarehouse.attributes.area_code
            )
          : Math.max(
              this.order.destinationArea.attributes.code,
              this.order.originWarehouse.attributes.area_code
            )
        : this.order.originWarehouse.attributes.area_code;
    },
    orderDetailsCount: function () {
      return this.orderDetails.length - 1;
    },
    totalPrice: function () {
      return this.orderDetails.reduce(
        (total, product) => total + product.qty * product.product.price,
        0
      );
    },
    totalBalance: function () {
      if (
        this.order.originOfficeCategory.attributes.name.toLowerCase() !==
        'pusat'
      ) {
        return 0;
      }

      return Math.max(
        this.getOffice.data.attributes.order_balance_available +
          this.getOrder.data.attributes.final_price,
        0
      );
    },
    cashback() {
      if (this.order.attributes.order_shipment !== 'pickup') {
        return 0;
      }

      if (this.order.destinationOffice.attributes.office_type !== 'stockist') {
        return 0
      }

      const result = this.getCashbackAmount(
        this.order.originOfficeCategory.attributes.name,
        this.order.area.attributes.code,
        this.totalPrice
      );
      return !isNaN(result) ? result : 0;
    },
    dpp: function () {
      return (100 / 111) * this.totalPrice;
    },
    ppn: function () {
      return (11 / 100) * this.dpp;
    },
    paymentRemainder() {
      const orderBalanceUsed = Math.max(
        this.getOffice.data.attributes.order_balance_available +
          this.getOrder.data.attributes.final_price,
        0
      );

      return Math.max(
        this.totalPrice -
          (this.totalPaymentAmount + this.cashback + orderBalanceUsed),
        0
      );
    },
    paymentOver() {
      const orderBalanceUsed = Math.max(
        this.getOffice.data.attributes.order_balance_available +
          this.getOrder.data.attributes.final_price,
        0
      );

      return Math.max(
        this.totalPaymentAmount +
          this.cashback +
          orderBalanceUsed -
          this.totalPrice,
        0
      );
    },
    officeCategories: function () {
      const allowedOfficeCategoryOriginName = [
        'pusat',
        'kantor pelayanan',
        'stockist center',
      ];
      if (this.viewAs == 'stockist_center') {
        allowedOfficeCategoryOriginName.pop();
      }

      return this.getOfficeCategories.data.filter((officeCategory) =>
        allowedOfficeCategoryOriginName.includes(
          officeCategory.attributes.name.toLowerCase()
        )
      );
    },
    officeBanks: function () {
      return this.getBanksByOffice.data;
    },
    officeBanksTypeBank: function () {
      return this.getBanksByOffice.data.filter(
        (bank) => bank.attributes.office_bank_type === 'bank'
      );
    },
    fetchOriginOfficeCategoryParams: function () {
      return {};
    },
    fetchOriginOfficesParams: function () {
      const officeCategoryId = {
        Pusat: 1,
        'Kantor Pelayanan': 2,
        'Stockist Center': 3,
        Stockist: 4,
        'Mitra Usaha': 5,
        Supplier: 6,
      }[this.order.originOfficeCategory.attributes.name];

      return {
        'filter[office_category_id]': officeCategoryId,
        'page[limit]': 5,
        include: 'office',
        'fields[simple-offices]': 'office',
      };
    },
    fetchDestinationOfficesParams: function () {
      return {
        office_id: StorageService.getUser().office_id,
        params: {
          include: 'addresses,area',
        },
      };
    },
    fetchBanksByOfficeParams: function () {
      return {
        office_id: this.order.originOffice.id,
        isActive: true,
      };
    },
    fetchWarehousesParams: function () {
      return {
        sort: '-code',
        userOfficeId: this.order.originOffice.id,
        pageNumber: 1,
        pageSize: 5,
        notCode:
          this.order.originOfficeCategory.attributes.name.toLowerCase() !==
          'pusat'
            ? 'nasa'
            : undefined,
      };
    },
    fetchWarehouseStocksParams: function () {
      return {
        warehouseId: this.order.originWarehouse.id,
        buyerOfficeCode: this.order.destinationOffice.attributes.code,
        sellerOfficeCode: this.order.originOffice.attributes.code,
        areaCode: this.order.area.attributes.code,
        buyerTypeCode: this.order.buyerType.attributes.code,
        pageNumber: 1,
        pageSize: 5,
      };
    },
    fetchAreasParams: function () {
      return {};
    },
    fetchEditOrderParams: function () {
      return {};
    },
    fetchDetailOrderParams: function () {
      return {};
    },
  },
  async created() {
    this.startLoading('Memuat Faktur');

    try {
      this.setMinOrder()

      await this.loadOfficeCategories();

      await this.setOrderDestinationOffice();

      this.setOrderDestinationArea(
        this.getRelationships(
          this.getOffice,
          this.getOffice.data.relationships.area.data.id
        )
      );

      if (this.$route.params.id) {
        await this.setOrder(this.$route.params.id);
      } else {
        this.setOrderArea(
          this.getRelationships(
            this.getOffice,
            this.getOffice.data.relationships.area.data.id
          )
        );
        await this.setCreateOrder();
      }
    } finally {
      this.stopLoading();
    }
  },
  methods: {
    ...mapActions({
      fetchSetups: 'setups/fetchSetups',
      fetchOrder: 'orders/fetchOrder',
      createOrder: 'orders/createOrder',
      updateOrder: 'orders/updateOrder',
      validateOrder: 'orders/validate',
      fetchOffices: 'simple_offices/fetchSimpleOffices',
      fetchOfficesByCategory: 'offices/fetchOfficesByCategory',
      fetchOfficeById: 'offices/fetchOfficeById',
      fetchOfficeCategories: 'office_categories/fetchOfficeCategories',
      fetchOfficesByOfficeCategory:
        'office_categories/fetchOfficesByOfficeCategory',
      fetchOfficeCategory: 'offices/fetchOfficeCategory',
      fetchBanksByOffice: 'banks/fetchBanksByOffice',
      fetchWarehouseByOffice: 'warehouses/fetchWarehouseByOffice',
      fetchWarehouseStock: 'warehouses/fetchWarehouseStock',
      fetchBuyerTypes: 'buyer_types/fetchBuyerTypes',
      fetchAreas: 'areas/fetchAreas',
      fetchWarehouses: 'warehouses/fetchWarehouses',
      fetchProvinces: 'indonesia/fetchProvinces',
      fetchCities: 'indonesia/fetchCities',
      fetchDistricts: 'indonesia/fetchDistricts',
      fetchVillages: 'indonesia/fetchVillages',
      createAlert: 'alert/createAlert',
      createPayment: 'payments/createPayment',
      createPaymentMethod: 'payment_methods/createPaymentMethod',
      fetchPaymentPaymentMethods: 'payments/fetchPaymentPaymentMethods',
      fetchPaymentMethodTypesByOffice:
        'payment_method_types/fetchPaymentMethodTypesByOffice',
      fetchAllRelated: 'main/fetchAllRelated',
    }),
    handleChangeAddress() {
      this.usingCustomAddress = true;

      this.resetOrderDetail();
      this.resetPaymentMethod();

      this.setArea();
    },
    handleCloseAddress() {
      if (this.usingCustomAddress) {
        this.resetOrderDetail();
        this.resetPaymentMethod();

        this.setArea();
      }

      this.addressModal.visible = false;
    },

    async setArea() {
      const calculatedArea = this.calculateArea();
      const res = await this.fetchAreas({
        keyword: calculatedArea,
        'page[size]': 1,
        'page[number]': 1,
        'fields[areas]': 'code',
      });

      if (res) {
        this.order.relationships.area = res.data.data[0];
      }
    },
    resetOrderDetail() {
      this.order.relationships.orderDetails.data = [
        {
          id: null,
          attributes: {
            product_code: null,
            product_name: null,
            orderable_stock: null,
            is_orderable: null,
            is_point: null,
            product_qty: null,
            product_price: null,
            product_weight: null,
            bonus_value: null,
            bonus_point: null,
          },
        },
      ];
    },
    resetPaymentMethod() {
      this.order.relationships.paymentMethods.data = [
        {
          id: null,
          used: false,
          attributes: {
            balance_used: 'restock',
            payment_amount: null,
          },
        },
        {
          id: null,
          used: false,
          attributes: {
            balance_used: 'deposit',
            payment_amount: null,
          },
        },
      ];
    },
    calculateArea() {
      const originWarehouseArea =
        this.order.relationships.originWarehouse.attributes.area_code;
      const { order_shipment: orderShipment } = this.order.attributes;

      if (orderShipment === 'pickup') {
        return originWarehouseArea;
      }

      const usingCustomAddress = this.usingCustomAddress;
      const destinationWarehouseArea =
        usingCustomAddress &&
        this.order.relationships.destinationAddress.attributes.city
          ? this.order.relationships.destinationAddress.attributes.city.area
          : this.order.relationships.destinationWarehouse.attributes.area_code;

      return Math.max(originWarehouseArea, destinationWarehouseArea);
    },
    isValidPayment: function () {
      return (
        this.paymentMethodsForm.length &&
        this.paymentMethodsForm.every((payment) => payment.value)
      );
    },
    getRelationships(resource, id) {
      let data = resource.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },

    // Set Selecct Options
    loadOfficeCategories: async function () {
      await this.fetchOfficeCategories({
        params: this.fetchOriginOfficeCategoryParams,
      });
    },
    setOfficeBanks: async function () {
      if (this.order.originOffice) {
        if (
          this.order.originOfficeCategory.attributes.name === 'Kantor Pelayanan'
        ) {
          // Mendapatkan Office ID Pusat
          const res = await this.fetchOffices({
            'page[limit]': 1,
            'filter[office_category_id]': 1,
            include: 'office',
            'fields[offices]': 'code',
            'fields[simple-offices]': 'code,office',
          });

          await this.fetchPaymentMethodTypesByOffice({
            params: { 'filter[is_reserved]': false },
            officeId: res.data.data[0].relationships.office.data.id,
          });
          await this.fetchBanksByOffice({
            ...this.fetchBanksByOfficeParams,
            office_id: res.data.data[0].relationships.office.data.id,
          });
        } else {
          await this.fetchPaymentMethodTypesByOffice({
            params: { 'filter[is_reserved]': false },
            officeId: this.order.originOffice.id,
          });
          await this.fetchBanksByOffice(this.fetchBanksByOfficeParams);
        }
      }
    },

    // Create Order
    async setCreateOrder() {
      this.order.id = null;
      this.order.attributes.createdAt = dayjs().format('YYYY-MM-DD');
      this.order.destinationWarehouse = {
        id: StorageService.getUser().current_warehouse,
      };

      const buyerTypes = await this.fetchBuyerTypes({
        keyword: 10,
      });

      this.setOrderBuyerType(buyerTypes.data.data[0]);
    },

    // Edit Order
    setOrder: async function (id) {
      await this.fetchOrder({
        id,
        ...this.fetchEditOrderParams,
      });

      this.loadProductBundles(id);

      this.order.id = this.getOrder.data.id;
      this.order.attributes.order_shipment =
        this.getOrder.data.attributes.order_shipment;
      this.order.attributes.createdAt = dayjs(
        this.getOrder.data.attributes.createdAt
      ).format('YYYY-MM-DD');
      this.order.destinationWarehouse = this.getRelationships(
        this.getOrder,
        this.getOrder.data.relationships['destination-warehouse'].data.id
      );
      this.order.attributes.notes = this.getOrder.data.attributes.notes;

      const officeCategory = await this.fetchOfficeCategory({
        office_id: this.getOrder.data.relationships['origin-office'].data.id,
        params: this.fetchOriginOfficeCategoryParams,
      });

      this.order.originOfficeCategory = officeCategory.data.data;

      this.setOrderArea(
        this.getRelationships(
          this.getOrder,
          this.getOrder.data.relationships.area.data.id
        )
      );
      this.setOrderBuyerType(
        this.getRelationships(
          this.getOrder,
          this.getOrder.data.relationships['buyer-type'].data.id
        )
      );

      await this.setOrderOriginOffice(
        this.getRelationships(
          this.getOrder,
          this.getOrder.data.relationships['origin-office'].data.id
        )
      );

      await this.fetchPaymentPaymentMethods({
        id: this.getOrder.data.relationships['payments'].data[0].id,
        include: 'office-bank',
      })

      if (this.order.destinationOffice.attributes.office_type === 'stockist_center') {
        this.setPaymentSaldoDetails()
      } else {
        this.setPaymentDetails()
      }

      this.setOrderOriginWarehouse(
        this.getRelationships(
          this.getOrder,
          this.getOrder.data.relationships['origin-warehouse'].data.id
        )
      );

      this.setOrderDetails();
    },

    // Set Order Destination
    setOrderDestinationOffice: async function () {
      await this.fetchOfficeById(this.fetchDestinationOfficesParams);

      this.order.destinationOffice = this.getOffice.data;
    },

    // Set Order Origin
    setOrderOriginOffice: async function (office) {
      this.order.originOffice = office;
      this.searchOriginOffice = office.attributes.code;

      await this.setOfficeBanks();
      this.clearOrderOriginWarehouse();
    },
    setOrderOriginWarehouse: function (warehouse) {
      this.order.originWarehouse = warehouse;
      this.searchOriginWarehouse = `${warehouse.attributes.code} (${warehouse.attributes.area_code})`;
    },

    // Set Order Buyer Type
    setOrderBuyerType: function (buyerType) {
      this.order.buyerType = buyerType;
    },

    // Set Order Area
    setOrderArea: function (area) {
      this.order.area = area;
      this.searchAreaCode = area.attributes.code;
    },
    setOrderAreaByCode: function (code) {
      this.fetchAreas({
        ...this.fetchAreasParams,
        keyword: code,
      }).then((res) => {
        this.setOrderArea(res.data.data[0]);
      });
    },
    setOrderDestinationArea: function (area) {
      this.order.destinationArea = area;
    },

    // Set Default Pusat Origin
    setDefaultPusatOrigin: async function () {
      this.startLoading('Mencari Kantor Pusat');

      const offices = await this.fetchOfficesByCategory({
        category: 'Pusat',
        params: {
          'pagination[type]': 'cursor',
          'page[limit]': 1,
          include: 'current-warehouse',
        },
      });

      if (offices) {
        const pusatOffice = offices.data[0];
        const pusatWarehouse = this.getSingleIncluded(
          offices,
          offices.data[0].relationships['current-warehouse'].data.id
        );

        await this.setOrderOriginOffice(pusatOffice);
        await this.setOrderOriginWarehouse(pusatWarehouse);
      }

      this.stopLoading();
    },

    // Set payment saldo
    setPaymentSaldoDetails() {
      this.getPaymentMethods.data.forEach(item => {
        const paymentMethodIndex = this.paymentMethods.findIndex(paymentMethod => paymentMethod.balance_used === item.attributes.balance_used)

        if (paymentMethodIndex !== -1) {
          this.paymentMethods[paymentMethodIndex].id = item.id
          this.paymentMethods[paymentMethodIndex].isUsed = true
          this.paymentMethods[paymentMethodIndex].amount = item.attributes.payment_amount
        }
      })
    },

    // Set Min Order
    async setMinOrder() {
      const res = await this.fetchSetups({
        'filter[setup_key]': this.viewAs === 'stockist' ? 'min_order_spend_for_stockist' : 'min_order_spend_for_sc',
      });

      if (res.data && res.data.data.length) {
        this.minGrandTotalToProcess = Number(res.data.data[0].attributes.setup_value.replace(/\D/g, ''))
      }
    },

    // Clear
    clearOrderArea: function () {
      this.searchAreaCode = this.order.destinationArea.attributes.code;
      this.order.area = { ...this.order.destinationArea };
    },
    clearOrderShipment: function () {
      this.order.attributes.order_shipment = 'pickup';
      this.clearOrderArea();
    },
    clearOrderOriginOffice: function () {
      this.order.originOffice = null;
      this.searchOriginOffice = null;
    },
    clearOrderOriginWarehouse: function () {
      this.order.originWarehouse = null;
      this.searchOriginWarehouse = null;
    },
    clearOrdersPaymentDetails: function () {
      this.order.paymentDetails.length = 0;

      this.pushEmptyPaymentDetailsItem();
    },
    clearOrder: function () {
      this.setCreateOrder();

      this.clearOrderShipment();
      this.clearOrderOriginOffice();
      this.clearOrderOriginWarehouse();
      this.clearOrdersPaymentDetails();
    },

    // Origin Office Category
    handleChangeOriginOfficeCategory: function () {
      this.clearOrderShipment();
      this.clearOrderOriginOffice();
      this.clearOrderOriginWarehouse();
      this.clearOrdersPaymentDetails();
      this.clearOrderArea();

      if (
        this.order.originOfficeCategory &&
        this.order.originOfficeCategory.attributes.name.toLowerCase() ===
          'pusat'
      ) {
        this.setDefaultPusatOrigin();
      }
    },

    // Origin Office Search
    handleChangeSearchOriginOffice: function () {
      this.startLoading('Mencari Kantor');

      this.fetchOffices({
        ...this.fetchOriginOfficesParams,
        'page[limit]': 1,
        'filter[code]': this.searchOriginOffice,
      }).then((res) => {
        if (res.data.data.length) {
          this.setOrderOriginOffice(
            this.getSingleIncluded(
              this.getSimpleOffices,
              res.data.data[0].relationships.office.data.id
            )
          ).then(() => {
            this.fetchWarehouseByOffice(this.fetchWarehousesParams).then(
              (res) => {
                this.setOrderOriginWarehouse(res.data.data[0]);
                this.setOrderAreaByCode(this.orderAreaCode);
                this.stopLoading();
              }
            );
          });
          this.clearOrderShipment();
        }
      });
    },

    // Origin Office Modal
    loadOfficesByCategory: async function (params = {}) {
      try {
        this.startLoading('Memuat Daftar Kantor');

        return await this.fetchOffices({
          ...this.fetchOriginOfficesParams,
          ...params,
        });
      } finally {
        this.stopLoading();
      }
    },
    handleOpenOriginOfficesModal: function () {
      this.visibleOriginOfficeModal = true;

      this.loadOfficesByCategory().then();
    },
    handleCloseOriginOfficesModal: function () {
      this.visibleOriginOfficeModal = false;
    },
    handleSelectOriginOfficesModal: function (data) {
      this.startLoading('Memilih Kantor');
      this.setOrderOriginOffice(data).then(() => {
        this.fetchWarehouseByOffice(this.fetchWarehousesParams).then((res) => {
          this.setOrderOriginWarehouse(res.data.data[0]);
          this.setOrderAreaByCode(this.orderAreaCode);
          this.stopLoading();
        });
      });
      this.clearOrderShipment();

      this.visibleOriginOfficeModal = false;
    },
    handleChangePageOriginOfficesModal: function (page) {
      this.loadOfficesByCategory({
        [`page[${page === 'next' ? 'after' : 'before'}]`]:
          this.getSimpleOffices.meta.page[page === 'next' ? 'to' : 'from'],
      });
    },
    handleSearchOriginOfficesModal: function (search) {
      this.loadOfficesByCategory({
        'filter[search]': search,
      });
    },

    // Origin Warehouse Search
    handleChangeSearchOriginWarehouse: function () {
      this.fetchWarehouseByOffice({
        ...this.fetchWarehousesParams,
        search: this.searchOriginWarehouse.split(' ')[0],
      }).then((res) => {
        if (res.data.data.length) {
          this.setOrderOriginWarehouse(res.data.data[0]);
        } else {
          this.clearOrderOriginWarehouse();
        }
      });
      this.setOrderAreaByCode(this.orderAreaCode);
    },
    loadWarehouses(params) {
      if (this.isOriginPusat) {
        this.fetchWarehouses({
          warehouseType: 'pusat,kantor_pelayanan',
          pageNumber: 1,
          pageSize: 5,
          ...params,
        });
        return;
      }
    },

    // Origin Warehouse Modal
    handleOpenOriginWarehousesModal: function () {
      if (this.order.originOffice) {
        this.visibleOriginWarehouseModal = true;
        if (this.isOriginPusat) {
          this.loadWarehouses();
          return;
        }
        this.fetchWarehouseByOffice(this.fetchWarehousesParams).then();
      }
    },
    handleCloseOriginWarehousesModal: function () {
      this.visibleOriginWarehouseModal = false;
    },
    handleSelectOriginWarehousesModal: function (data) {
      this.setOrderOriginWarehouse(data);
      this.setOrderAreaByCode(this.orderAreaCode);

      this.visibleOriginWarehouseModal = false;
    },
    handleChangePageOriginWarehousesModal: function (page) {
      if (this.isOriginPusat) {
        this.loadWarehouses({
          pageNumber: page,
        });
        return;
      }
      this.fetchWarehouseByOffice({
        ...this.fetchWarehousesParams,
        pageNumber: page,
      });
    },
    handleSearchOriginWarehousesModal: function (search) {
      if (this.isOriginPusat) {
        this.loadWarehouses({
          search,
        });
        return;
      }
      this.fetchWarehouseByOffice({
        ...this.fetchWarehousesParams,
        search,
      });
    },

    // Order Shipment
    handleChangeOrderShipment: function () {
      this.setOrderAreaByCode(this.orderAreaCode);
    },

    // Order Area
    handleChangeArea: function () {
      this.searchAreaCode =
        this.searchAreaCode > 4 || this.searchAreaCode < 0
          ? 0
          : this.searchAreaCode;

      const areaCode =
        this.order.attributes.order_shipment === 'pickup'
          ? this.order.originWarehouse.attributes.area_code
          : this.searchAreaCode;

      this.setOrderAreaByCode(areaCode);
    },

    // Product
    focusInputProductQty: function (index) {
      setTimeout(() => {
        this.$refs.product_qty[index].focus();
      });
    },
    checkProductCodeAlreadyExist: function (search_product_code) {
      return (
        this.order.orderDetails.filter(function (el) {
          return el.search_product_code === search_product_code;
        }).length > 1
      );
    },
    getOrderDetailsProduct: function (code) {
      return this.getOrder.included
        .filter((include) => include.type === 'order-details')
        .find((product) => product.attributes.product_code === code);
    },
    getProductStockReal: function (product) {
      // jika tidak edit stok real langsung dari atribut
      if (!this.isEditing) {
        return product.attributes.stock_real;
      }

      const productProductInOrderDetails = this.getOrderDetailsProduct(
        product.attributes.product_code
      );

      // jika tidak ada product produk di order details stok real langsung dari atribut
      if (!productProductInOrderDetails) {
        return product.attributes.stock_real;
      }

      // stok real + jumlah produk dibeli
      return (
        product.attributes.stock_real +
        productProductInOrderDetails.attributes.product_qty
      );
    },
    pushEmptyProductItem: function () {
      this.orderDetails.push({
        search: null,
        product: {
          id: null,
          code: null,
          name: null,
          price: null,
          weight: null,
          stock: null,
          status: null,
          isPoint: null,
        },
        qty: null,
      });
    },
    pushProductItem: function (index, product) {
      try {
        if (
          this.checkProductCodeAlreadyExist(
            this.order.orderDetails[index].search_product_code
          )
        ) {
          throw new Error('Kode Barang Sudah Ada');
        }

        // mengambil stok (lihat detail fungsi)
        const stockReal = this.getProductStockReal(product);

        // jika kode barang tidak memiliki stok
        if (stockReal < 1) {
          throw new Error('Kode Barang Tidak Memiliki Stok');
        }

        // Jika produk yang akan ditambah sama dengan produk saat ini maka dibatalkan
        if (
          product.attributes.product_code ===
          this.order.orderDetails[index].product_code
        ) {
          return;
        }

        this.order.orderDetails[index].search_product_code =
          product.attributes.product_code;
        this.order.orderDetails[index].product_code =
          product.attributes.product_code;
        this.order.orderDetails[index].product_name =
          product.attributes.product_name;
        this.order.orderDetails[index].stock_real = stockReal;
        this.order.orderDetails[index].product_qty = null;
        this.order.orderDetails[index].product_price =
          product.attributes.product_price;
        this.order.orderDetails[index].total_price =
          product.attributes.product_price;

        if (
          this.order.orderDetails[this.order.orderDetails.length - 1]
            .product_code
        ) {
          this.pushEmptyProductItem();
        }

        this.focusInputProductQty(index);
      } catch (err) {
        this.order.orderDetails[index].search_product_code =
          this.order.orderDetails[index].product_code;

        this.createAlert({ data: err.message, type: 'error' });
      }
    },

    // Product Table Action
    getOrderDetails: function () {
      return this.order.orderDetails
        .filter((product) => product.product_code !== null)
        .map((product) => ({
          product_code: product.product_code,
          product_qty: parseFloat(product.product_qty),
          product_price: parseFloat(product.product_price),
        }));
    },
    handleSearchProduct(index) {
      if (this.order.orderDetails[index].search_product_code) {
        this.fetchWarehouseStock({
          ...this.fetchWarehouseStocksParams,
          productCode: this.order.orderDetails[index].search_product_code,
        }).then((res) => {
          if (res?.data.data.length > 0) {
            this.pushProductItem(index, res.data.data[0]);
          } else {
            this.order.orderDetails[index].search_product_code =
              this.order.orderDetails[index].product_code;

            this.createAlert({
              data: 'Kode Barang Tidak Ditemukan',
              type: 'error',
            });
          }
        });
      }
    },
    handleChangeProductQty: function (index) {
      try {
        // jika jumlah yang diinputkan kurang dari 0
        if (this.order.orderDetails[index].product_qty < 0) {
          this.order.orderDetails[index].product_qty = 0;

          throw new Error('Jumlah tidak boleh kurang dari 0');
        }

        // jika jumlah melebihi batas stok
        if (
          this.order.orderDetails[index].product_qty >
          this.order.orderDetails[index].stock_real
        ) {
          this.order.orderDetails[index].product_qty =
            this.order.orderDetails[index].stock_real;

          throw new Error('Jumlah melebihi stok');
        }
      } catch (err) {
        this.createAlert({ data: err.message, type: 'error' });
      } finally {
        // Update total harga
        this.order.orderDetails[index].total_price =
          this.order.orderDetails[index].product_qty *
          this.order.orderDetails[index].product_price;
      }
    },
    handleRemoveProduct: function (index) {
      this.order.orderDetails.splice(index, 1);
    },

    // Product Modal
    handleOpenProductsModal: function (index) {
      this.visibleProductModal = true;
      this.fetchWarehouseStock(this.fetchWarehouseStocksParams).then(() => {
        this.productModalSelectedProductIndex = index;
      });
    },
    handleCloseProductsModal: function () {
      this.visibleProductModal = false;
    },
    handleSelectProductsModal: function (data) {
      this.order.orderDetails[
        this.productModalSelectedProductIndex
      ].search_product_code = data.attributes.product_code;

      this.pushProductItem(this.productModalSelectedProductIndex, data);

      this.visibleProductModal = false;
    },
    handleChangePageProductsModal: function (page) {
      this.fetchWarehouseStock({
        ...this.fetchWarehouseStocksParams,
        pageNumber: page,
      });
    },
    handleSearchProductsModal: debounce(function (text) {
      this.fetchWarehouseStock({
        ...this.fetchWarehouseStocksParams,
        search: text,
      });
    }, 300),

    // Payment
    getPaymentDetails: function () {
      return this.order.paymentDetails.map((payment) => {
        const paymentOfficeBank = this.officeBanks.find(
          (officeBank) =>
            officeBank.attributes.office_bank_type === payment.paymentMethod
        );

        return {
          ...(payment.paymentMethod !== 'stockist'
            ? {
                office_bank_id: payment.office_bank_id
                  ? payment.office_bank_id
                  : paymentOfficeBank?.id ?? null,
              }
            : {}),
          payment_amount: payment.payment_amount,
          ...(payment.paymentMethod === 'stockist'
            ? { balance_used: payment.paymentMethod }
            : {}),
          transfer_date: payment?.transfer_date ?? undefined,
        };
      });
    },
    handleChangePaymentAmount: function (e, paymentMethodIndex) {
      const value = Number(e.target.value.split(',')[0].replace(/\D/gi, ''));

      this.order.paymentDetails[paymentMethodIndex].payment_amount = value;
      e.target.value = this.$options.filters.toCurrency(value, {
        style: 'decimal',
        maxFractionDigit: 0,
      });
    },
    handleRemovePaymentDetailsItem: function (index) {
      this.order.paymentDetails.splice(index, 1);
    },

    // Action
    handleCreatedOrder(order) {
      this.order.id = order.id;

      this.$router.push(this.indexPath + '/' + order.id);
      this.loadProductBundles(order.id);
    },
    handleUpdatedOrder() {
      this.loadProductBundles(this.$route.params.id);
    },
    handleClearOrder: function () {
      this.clearOrder();
    },
    handleProcessOrder: async function () {
      this.startLoading('Menyimpan Faktur');
      const payload = {
        data: {
          type: 'orders',
          attributes: {
            order_type: 'purchase',
            order_shipment: this.order.attributes.order_shipment,
            notes: this.order.attributes.notes,
            is_ppn_included: this.order.attributes.is_ppn_included,
            origin_warehouse_id: this.order.originWarehouse.id,
            origin_office_id: this.order.originOffice.id,
            destination_warehouse_id: this.order.destinationWarehouse.id,
            destination_office_id: this.order.destinationOffice.id,
            details: this.getOrderDetails(),
            payment_details: this.getPaymentDetails(),
          },
          relationships: {
            'buyer-type': {
              data: {
                type: 'buyer-types',
                id: this.order.buyerType.id,
              },
            },
            area: {
              data: {
                type: 'areas',
                id: this.order.area.id,
              },
            },
          },
        },
      };

      if (this.usingCustomAddress) {
        payload.data.attributes.destination_address = {
          province_id:
            this.order.relationships.destinationAddress.attributes.province.id,
          city_id:
            this.order.relationships.destinationAddress.attributes.city.id,
          district_id:
            this.order.relationships.destinationAddress.attributes.district.id,
          village_id:
            this.order.relationships.destinationAddress.attributes.village.id,
          address:
            this.order.relationships.destinationAddress.attributes.alamat,
        };
      }

      if (this.isEditing) {
        this.validateOrder({
          id: this.order.id,
          data: {
            selected_bundles_id: this.selectedBundles,
            choosen_bonuses: this.choosenBonuses
          }
        })
          .then((response) => {
            if (response) {
              this.visibleSuccessModal = true;
            } else {
              this.visibleSuccessModal = false;
              this.visibleFailedModal = true;
            }
          })
          .finally(() => this.stopLoading());
      } else {
        this.createOrder(payload).then((response) => {
          if (response) {
            this.createOrderPayment({
              orderId: response.data.data.id,
              paymentId: response.data.included.find(
                (it) => it.type == 'payments'
              ).id,
            });
            this.visibleSuccessModal = true;
          } else {
            this.visibleSuccessModal = false;
            this.visibleFailedModal = true;
          }
        });
      }
    },
    async createOrderPayment({ paymentId }) {
      if (paymentId) {
        // Create Payment Methods
        const batchRequests = this.order.paymentDetails.map((method) => {
          const officeBankByType = this.officeBanks.filter(
            (officeBank) =>
              officeBank.attributes.office_bank_type === method.paymentMethod
          );
          return this.createPaymentMethod({
            paymentId: paymentId,
            officeBankId: method.officeBank
              ? method.officeBank.id
              : officeBankByType[0]?.id ?? null,
            attributes: {
              payment_amount: method.payment_amount ?? 0,
              transfer_date: method.transfer_date
                ? dayjs(method.transfer_date).format()
                : null,
              ...(method.paymentMethod === 'stockist'
                ? { balance_used: 'stockist' }
                : {}),
            },
          });
        });

        // request ke payment details
        return await Promise.all(batchRequests).then((res) => {
          res.forEach((it, index) => {
            const paymentDetailId = it.data.data.id;
            const files = this.order.paymentDetails[index].files;
            files.map((file) => {
              const formData = new FormData();
              formData.append('payment_method_id', paymentDetailId);
              formData.append('url', file);
              ApiService.post(
                'api/v1/payment-methods/-actions/uploadFiles',
                formData
              );
            });
          });
        });
      }
    },
    async storePaymentMethods(paymentId) {
      this.startLoading('Menyimpan Pembayaran');

      try {
        await Promise.all(
          this.paymentMethods
            .filter((paymentMethod) => paymentMethod.isUsed)
            .map(async (paymentMethod) => {
              return await this.createPaymentMethod({
                attributes: {
                  balance_used: paymentMethod.balance_used,
                  payment_amount: paymentMethod.amount,
                },
                paymentId,
              });
            })
        );
      } catch (err) {
        this.stopLoading();

        throw err;
      }
    },
    handleClickReorder: function () {
      this.visibleSuccessModal = false;
      this.clearOrder();
    },
    handleFinishOrder: function () {
      this.startLoading('Memuat Faktur');

      this.fetchOrder({
        id: this.getOrder.data.id,
        ...this.fetchDetailOrderParams,
      }).finally(() => this.stopLoading());

      this.visibleDetailModal = true;
      this.visibleSuccessModal = false;
    },
    handleClickFailed: function () {
      this.visibleFailedModal = false;
      this.clearOrder();
    },

    // Detail Order Modal
    handleCloseDetailModal: function () {
      this.$router.push(this.indexPath);
    },

    // Edit Order Modal
    handleEditDetailModal: function (id) {
      this.$router.push(`${this.indexPath}/${id}`).then(() => {
        this.clearOrder();
        this.setOrder(id);

        this.visibleDetailModal = false;
      });
    },

    // Address
    handleOpenAddressModal: function () {
      this.addressModal.visible = true;

      this.fetchProvinces({
        pageNumber: 1,
      });
    },
    handleClearAddress: function () {
      this.usingCustomAddress = false;
      this.setOrderAreaByCode(this.orderAreaCode);
    },
    handleSubmitAddress: function () {
      this.usingCustomAddress = true;

      this.setOrderAreaByCode(this.orderAreaCode);

      this.addressModal.visible = false;
    },
    handlePaymentMethodChange(index) {
      this.order.paymentDetails[index].transfer_date =
        dayjs().format('YYYY-MM-DDTHH:mm');
    },
    handleUploadModal(index) {
      index ? (this.uploadModalIndex = index) : null;
      this.visibleUploadModal = !this.visibleUploadModal;
    },
    onAddFile(out) {
      this.order.paymentDetails[this.uploadModalIndex].files.push(out.file);
    },

    // promo
    onChangeSelectedBundles(value) {
      this.selectedBundles = [ ...value ]
    },
    onChangeChoosenBonuses(value) {
      this.choosenBonuses = { ...value }
    },
  },
};
</script>
