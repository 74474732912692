<template>
  <div>
    <base-modal :showing="visible" size="max-w-5xl" @close="$emit('close')">
      <div class="space-y-6">
        <div class="flex items-center justify-between">
          <div>
            <h3 class="text-lg font-bold leading-6 text-gray-900">
              Klasifikasi Stok
            </h3>
            <p class="mt-1 max-w-2xl text-sm text-gray-500">
              Klasifikasi Stok Barang Retur
            </p>
          </div>
          <div>
            <img src="@/assets/images/logo.png" alt="" class="w-32" />
          </div>
        </div>
        <div v-if="visible" class="border-t border-gray-200 pt-6">
          <Datatable :paginated="false" :footer="false">
            <template v-slot:thead>
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Kode
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Kadaluwarsa
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Rusak
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Tidak Layak Dijual
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Layak Dijual
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Jumlah Retur
                  </th>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody>
                <tr
                  v-for="(product, index) in classifications"
                  :key="index"
                  class="bg-white"
                >
                  <td
                    class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                  >
                    {{ product.product_code }}
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    <input
                      type="text"
                      name="jumlah_barang"
                      ref="jumlah_barang"
                      placeholder="Jumlah"
                      v-model="classifications[index].expired_count"
                      class="block w-full border-0 p-0 text-right placeholder-gray-200 focus:ring-0 sm:text-sm"
                    />
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    <input
                      type="text"
                      name="jumlah_barang"
                      ref="jumlah_barang"
                      placeholder="Jumlah"
                      v-model="classifications[index].damaged_count"
                      class="block w-full border-0 p-0 text-right placeholder-gray-200 focus:ring-0 sm:text-sm"
                    />
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    <input
                      type="text"
                      name="jumlah_barang"
                      ref="jumlah_barang"
                      placeholder="Jumlah"
                      v-model="classifications[index].suitable_for_sale_count"
                      class="block w-full border-0 p-0 text-right placeholder-gray-200 focus:ring-0 sm:text-sm"
                    />
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    <input
                      type="text"
                      name="jumlah_barang"
                      ref="jumlah_barang"
                      placeholder="Jumlah"
                      v-model="classifications[index].unsuitable_for_sale_count"
                      class="block w-full border-0 p-0 text-right placeholder-gray-200 focus:ring-0 sm:text-sm"
                    />
                  </td>
                  <td
                    class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                  >
                    {{ product.product_qty | toCurrency }}
                  </td>
                </tr>
              </tbody>
            </template>
          </Datatable>
        </div>
        <div class="flex justify-end gap-x-2">
          <base-button @click="onReceive" type="button"> Terima </base-button>
        </div>
      </div>
    </base-modal>
  </div>
</template>

<script>
import BaseModal from '@/components/base/BaseModal.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    BaseModal,
  },
  props: {
    visible: Boolean,
  },
  emits: ['close', 'success'],
  data() {
    return {
      classifications: [],
    };
  },
  computed: {
    ...mapGetters({
      getReturShipment: 'retur_shipments/getReturShipment',
    }),
  },
  watch: {
    visible(value) {
      if (value) {
        this.setClassifications();
      }
    },
  },
  methods: {
    ...mapActions({
      receiveReturShipment: 'retur_shipments/receiveReturShipment',
    }),
    async onReceive() {
      const res = await this.receiveReturShipment({
        id: this.getReturShipment.data.id,
        payload: {
          data: this.classifications.map((item) => ({
            retur_detail_id: item.retur_detail_id,
            expired_count: item.expired_count,
            damaged_count: item.damaged_count,
            suitable_for_sale_count: item.suitable_for_sale_count,
            unsuitable_for_sale_count: item.unsuitable_for_sale_count,
          })),
        },
      });

      if (res) {
        this.$emit('success');
      }
    },
    setClassifications() {
      this.classifications = this.getIncludedByType(
        this.getReturShipment,
        'retur-shipment-details'
      ).map((detail) => ({
        retur_detail_id: detail.id,
        expired_count: null,
        damaged_count: null,
        suitable_for_sale_count: null,
        unsuitable_for_sale_count: null,
        product_qty: detail.attributes.product_qty,
        product_code: detail.attributes.product_code,
      }));
    },
  },
};
</script>
