<template>
  <div
    :class="{ html2pdf__page: asContent }"
    :slot="asContent ? 'pdf-content' : null"
  >
    <section class="m-4 text-xs">
      <div class="relative bottom-2">
        <table class="mb-2">
          <tr>
            <td>No. Kemasan</td>
            <td class="px-4">:</td>
            <td>{{ stockMovement.code || '-' }}</td>
          </tr>
          <tr>
            <td>No. Faktur</td>
            <td class="px-4">:</td>
            <td>{{ stockMovement.order.code || '-' }}</td>
          </tr>
          <tr>
            <td>Tgl</td>
            <td class="px-4">:</td>
            <td>{{ stockMovement.date || '-' }}</td>
          </tr>
          <tr>
            <td>Kode Office Tujuan</td>
            <td class="px-4">:</td>
            <td>{{ stockMovement.order.destinationOffice || '-' }}</td>
          </tr>
        </table>

        <p v-if="stockMovement.order.address">
          {{ stockMovement.order.address }}
        </p>
        <p v-if="stockMovement.order.phone">{{ stockMovement.order.phone }}</p>

        <div v-for="(packet, i) in stockMovement.packets" :key="i">
          <p class="mb-4 mt-4">Koli {{ packet.code }}</p>
          <table class="mb-4 w-full border-t border-dashed border-gray-700">
            <tbody class="text-left">
              <tr v-for="(product, i) in packet.products" :key="i">
                <td>{{ i + 1 }}.</td>
                <td class="text-left">
                  {{ product.product_name }} {{ product.type === 'order-detail-bonuses' || product.is_bonus ? '(BONUS)' : '' }}
                </td>
                <td class="text-left">
                  {{ product.shipped_product_qty }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: 'PrintKemasan',
  props: {
    asContent: {
      type: Boolean,
      default: false,
    },
    stockMovement: {
      type: Object,
      default: () => ({
        code: null,
        date: null,
        order: {
          code: null,
          address: null,
          phone: null,
          destinationOffice: null,
        },
        packets: [],
      }),
    },
  },
  methods: {},
};
</script>
