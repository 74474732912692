<template>
  <div class="items-center text-sm text-gray-700">
    <input
      type="radio"
      :name="name"
      v-model="inputVal"
      :id="id"
      :value="val"
      @change="handleChange"
      class="mr-1 h-4 w-4 cursor-pointer border-gray-300 text-gray-600 focus:ring-gray-500"
      :disabled="disabled"
    />
    <label :for="id">
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  name: 'BaseRadio',
  props: {
    label: {
      type: String,
      default: 'Label',
    },
    id: String,
    name: String,
    value: {
      required: true,
    },
    val: {
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // Your component data here
    };
  },
  computed: {
    inputVal: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.$emit('input', value);
      },
    },
    // Your computed properties here
  },
  components: {
    // Your child components here
  },
  methods: {
    handleChange: function (e) {
      this.$emit('change', e);
    },
    // Your component methods here
  },
  created() {
    // console.log(this.value)
    // Code to run when the component is created
  },
};
</script>

<style scoped>
/* Your component styles here */
</style>
