<template>
  <!-- Modal Tambah Ekspedisi -->
  <Modal
    :showing="modal === 'add'"
    @close="closeModal"
    :showClose="modal === 'add'"
    :backgroundClose="true"
    size="max-w-4xl"
  >
    <form @submit.prevent="addExpedition" id="modal-add">
      <div>
        <div
          class="flex justify-between border-b border-gray-200 px-4 py-5 sm:px-6"
        >
          <div>
            <h3 class="text-lg font-semibold leading-6 text-gray-900">
              {{ dataExpedition.id ? 'Edit Ekspedisi' : 'Tambah Ekspedisi' }}
            </h3>
          </div>
        </div>
        <div class="py-2 px-6">
          <div class="mt-4 flex sm:flex-row sm:gap-x-4">
            <div class="field-inset-default w-full">
              <label
                for="kode_ekspedisi"
                class="block text-xs font-bold text-gray-700"
              >
                kode Ekspedisi
              </label>
              <input
                v-model="dataExpedition.code"
                ref="focusNamaBarang"
                @keypress.enter.prevent
                type="text"
                name="kode_ekspedisi"
                id="kode_ekspedisi"
                class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                placeholder="Masukkan kode ekspedisi"
              />
            </div>
            <div class="field-inset-default w-full">
              <label
                for="nama_ekspedisi"
                class="block text-xs font-bold text-gray-700"
              >
                Nama Ekspedisi
              </label>
              <input
                v-model="dataExpedition.name"
                ref="focusNamaBarang"
                @keypress.enter.prevent
                type="text"
                name="nama_ekspedisi"
                id="nama_ekspedisi"
                class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                placeholder="Masukkan nama ekspedisi"
              />
            </div>
          </div>
          <div class="mt-4 flex sm:flex-row sm:gap-x-4">
            <div class="field-inset-default w-full">
              <label
                for="deskripsi_ekspedisi"
                class="block text-xs font-bold text-gray-700"
              >
                Deskripsi Ekspedisi
              </label>
              <input
                v-model="dataExpedition.description"
                ref="focusNamaBarang"
                type="text"
                name="deskripsi_ekspedisi"
                id="deskripsi_ekspedisi"
                class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                placeholder="Masukkan nama ekspedisi"
              />
            </div>
          </div>
          <div class="mt-4 flex sm:flex-row sm:gap-x-4">
            <div class="field-inset-default w-full">
              <label
                for="alamat1"
                class="block text-xs font-bold text-gray-700"
              >
                Alamat 1
              </label>
              <input
                v-model="dataExpedition.address_1"
                ref="focusNamaBarang"
                type="text"
                name="alamat1"
                id="alamat1"
                class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                placeholder="Masukkan alamat 1"
              />
            </div>
          </div>
          <div class="mt-4 flex sm:flex-row sm:gap-x-4">
            <div class="field-inset-default w-full">
              <label
                for="alamat2"
                class="block text-xs font-bold text-gray-700"
              >
                Alamat 2
              </label>
              <input
                v-model="dataExpedition.address_2"
                ref="focusNamaBarang"
                type="text"
                name="alamat2"
                id="alamat2"
                class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                placeholder="Masukkan alamat 1"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="pt-5">
        <div class="flex justify-end">
          <button
            type="submit"
            id="simpan_supplier"
            class="ml-3 inline-flex rounded-md border border-gray-300 bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-600 hover:shadow-lg"
          >
            Simpan
          </button>
          <button
            @click="clearData"
            id="batal_supplier"
            type="button"
            class="bg-white-600 ml-3 rounded-md border border-gray-300 py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-white hover:shadow-lg"
          >
            Batal
          </button>
          <button
            @click="closeModal"
            id="selesai_supplier"
            class="ml-3 inline-flex rounded-md border border-gray-300 bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-600 hover:shadow-lg"
          >
            Selesai
          </button>
        </div>
      </div>
    </form>
  </Modal>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import Modal from '@/components/base/BaseModal';
import debounce from 'debounce';
import { StorageService } from '@/services/storage.service';

export default {
  name: 'ExpeditionModal',
  components: {
    Modal,
  },
  props: {
    modal: {
      required: false,
    },
    formData: {
      required: false,
    },
  },
  data() {
    return {
      dataExpedition: {
        code: null,
        name: null,
        description: null,
        service: null,
        services: [],
        address_1: null,
        address_2: null,
        province: {
          id: '',
          name: '',
        },
        city: {
          id: '',
          name: '',
          code: '',
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      getExpeditions: 'expeditions/getExpeditions',
      getError: 'expeditions/getError',
      getProvinces: 'indonesia/getProvinces',
      getCities: 'indonesia/getCities',
      getLoading: 'indonesia/getLoading',
    }),
  },
  methods: {
    ...mapActions({
      fetchExpeditions: 'expeditions/fetchExpeditions',
      createExpedition: 'expeditions/createExpedition',
      createOfficeExpedition: 'office_expeditions/createOfficeExpedition',
      updateOfficeExpedition: 'office_expeditions/updateOfficeExpedition',
      fetchProvinces: 'indonesia/fetchProvinces',
      fetchCities: 'indonesia/fetchCities',
      fetchDistricts: 'indonesia/fetchDistricts',
      fetchVillages: 'indonesia/fetchVillages',
    }),
    changeProvince() {
      this.fetchCities({
        province_id: this.dataExpedition.provinsi.id,
        pageNumber: 1,
      });
      this.dataExpedition.kota =
        this.dataExpedition.kecamatan =
        this.dataExpedition.desa =
          {
            id: '',
            name: '',
          };
    },
    changeCity() {
      this.fetchDistricts({
        city_id: this.dataExpedition.kota.id,
        pageNumber: 1,
      });
      this.dataExpedition.kecamatan = this.dataExpedition.desa = {
        id: '',
        name: '',
      };
    },
    searchCity: debounce(function (value) {
      this.fetchCities({
        province_id: this.dataExpedition.province.id,
        pageNumber: 1,
        search: value,
      });
    }, 300),
    closeModal() {
      this.$emit('closeModal');
    },
    clearData() {
      this.$emit('clearData');
    },
    addExpedition() {
      const payload = {
        data: {
          type: 'office-expeditions',
          attributes: {
            code: this.dataExpedition.code,
            name: this.dataExpedition.name,
            description: this.dataExpedition.description,
            address_1: this.dataExpedition.address_1,
            address_2: this.dataExpedition.address_2,
          },
          relationships: {
            office: {
              data: {
                type: 'offices',
                id: StorageService.getUser().office_id,
              },
            },
          },
        },
      };
      if (this.dataExpedition.id) {
        payload.data.id = this.dataExpedition.id;
        this.updateOfficeExpedition(payload).then((response) => {
          if (response) {
            this.$emit('closeModal');
          } else {
            this.failed = true;
          }
        });
      } else {
        this.createOfficeExpedition(payload).then((response) => {
          if (response) {
            this.$emit('closeModal');
          } else {
            this.failed = true;
          }
        });
      }
    },
  },
  created() {
    if (this.formData) {
      this.dataExpedition.id = this.formData.id;
      this.dataExpedition.code = this.formData.attributes.code;
      this.dataExpedition.name = this.formData.attributes.name;
      this.dataExpedition.description = this.formData.attributes.description;
      this.dataExpedition.address_1 = this.formData.attributes.address_1;
      this.dataExpedition.address_2 = this.formData.attributes.address_2;
    }
  },
};
</script>
