<template>
  <transition
    name="customFade"
    enter-active-class="fadeIn"
    leave-active-class="fadeOut"
  >
    <div
      v-if="openListBarang"
      class="fixed inset-0 z-10 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <span
          class="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl sm:p-6 sm:align-middle"
        >
          <div class="flex justify-between px-4 py-5 sm:px-6">
            <div>
              <h3 class="text-lg font-bold leading-6 text-gray-900">
                Daftar Produk
              </h3>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                Data barang berdasarkan supplier yang dipilih
              </p>
            </div>
            <div>
              <img src="@/assets/images/logo.png" alt="" class="w-52" />
            </div>
          </div>
          <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
            <div class="flex justify-end">
              <base-search @input="onChangeSearch" />
            </div>
            <div class="my-2 flex sm:-mx-6 lg:-mx-8">
              <div
                class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
              >
                <Datatable
                  :total="getWarehouseStock.meta?.page.total"
                  :perPage="getWarehouseStock.meta?.page.perPage"
                  :currentPage="getWarehouseStock.meta?.page.currentPage"
                  @pagechanged="handlePage"
                >
                  <template v-slot:thead>
                    <thead class="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                        >
                          Kode Barang
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                        >
                          Nama Barang
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                        >
                          Unit
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                        >
                          PV
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                        >
                          BV
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                        >
                          Harga Supplier
                        </th>
                      </tr>
                    </thead>
                  </template>
                  <template v-slot:tbody>
                    <tbody v-if="getWarehouseStock.data.length > 0">
                      <tr
                        class="cursor-pointer bg-white hover:bg-green-100"
                        :class="[
                          getStockOrderableStock(data) > 0
                            ? 'cursor-pointer hover:bg-green-100'
                            : 'cursor-not-allowed',
                          index % 2 === 0 ? 'bg-white' : 'bg-gray-50',
                        ]"
                        @click="
                          getStockOrderableStock(data) > 0
                            ? $emit('changeSelectedBarang', data)
                            : null
                        "
                        v-for="(data, index) in getWarehouseStock.data"
                        :key="data.id"
                      >
                        <td
                          class="whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
                        >
                          {{ data.attributes.product_code }}
                        </td>
                        <td
                          class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                        >
                          {{ data.attributes.product_name }}
                        </td>
                        <td
                          class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                        >
                          {{ getStockOrderableStock(data) | toCurrency }}
                        </td>
                        <td
                          class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                        >
                          {{
                            getRelationships(
                              getWarehouseStock,
                              data.relationships.product.data.id
                            )?.attributes.point
                          }}
                        </td>
                        <td
                          class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                        >
                          {{
                            getRelationships(
                              getWarehouseStock,
                              data.relationships.product.data.id
                            )?.attributes.bonus
                          }}
                        </td>
                        <td
                          class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                        >
                          {{ data.attributes.product_price | toCurrency }}
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                      >
                        Tidak ada produk tersedia
                      </td>
                    </tbody>
                  </template>
                </Datatable>
              </div>
            </div>
          </div>
          <div class="flex justify-end">
            <button
              @click="$emit('closeBarangModal')"
              type="button"
              class="focus:outline-none inline-flex justify-end rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
            >
              Tutup
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Datatable from '../base/Datatable.vue';
import BaseSearch from '@/components/base/Search.vue';

export default {
  name: 'BarangModal',
  components: {
    Datatable,
    BaseSearch,
  },
  props: {
    openListBarang: Boolean,
    isEditForm: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      isLoading: 'suppliers/getLoading',
      getWarehouseStock: 'warehouses/getWarehouseStock',
      getOrder: 'orders/getOrder',
    }),
  },
  methods: {
    ...mapActions({
      fetchSetups: 'setups/fetchSetups',
      fetchSuppliers: 'suppliers/fetchSupplier',
      fetchProductsByOffice: 'products/fetchProductsByOffice',
      fetchBuyerTypes: 'buyer_types/fetchBuyerTypes',
      fetchAreas: 'areas/fetchAreas',
      fetchOffices: 'offices/fetchOffices',
      fetchOrder: 'orders/fetchOrder',
      fetchOrders: 'orders/fetchOrders',
      updateOrder: 'orders/updateOrder',
      fetchOfficesByOfficeCategory:
        'office_categories/fetchOfficesByOfficeCategory',
      fetchAllOfficeCategory: 'office_categories/fetchAllOfficeCategory',
      fetchStocksByWarehouse: 'stocks/fetchStocksByWarehouse',
      fetchWarehouseStock: 'warehouses/fetchWarehouseStock',
    }),

    onChangeSearch(value) {
      this.$emit('debounceSearchProduct', value);
    },

    handlePage(page) {
      this.$emit('onPageChangeBarang', page);
    },
    getRelationships(resource, id) {
      let data = resource?.included?.filter(function (el) {
        return el.id === id;
      });
      return data?.[0];
    },
    getOrderDetailsProduct(code) {
      return this.getOrder.included
        .filter((include) => include.type === 'order-details')
        .find((product) => product.attributes.product_code === code);
    },
    getStockOrderableStock(stock) {
      // jika tidak edit orderable stok langsung dari atribut
      if (!this.isEditForm) {
        return stock.attributes.orderable_stock;
      }

      const stockProductInOrderDetails = this.getOrderDetailsProduct(
        stock.attributes.product_code
      );

      // jika tidak ada stock produk di order details orderable stok langsung dari atribut
      if (!stockProductInOrderDetails) {
        return stock.attributes.orderable_stock;
      }

      // orderable stok + jumlah produk dibeli
      return (
        stock.attributes.orderable_stock +
        stockProductInOrderDetails.attributes.product_qty
      );
    },
  },
};
</script>
