<template>
  <OrderPurchaseIndex
    indexPath="/gudang/restock"
    title="Restock"
    :to="'restock/tambah-restock'"
    :destination-office-category-id="3"
  />
</template>

<script>
import OrderPurchaseIndex from '@/components/order/OrderPurchaseIndex.vue';

export default {
  name: 'IndexPembelian',
  props: {},
  data() {
    return {
      // Your component data here
    };
  },
  computed: {
    // Your computed properties here
  },
  components: {
    OrderPurchaseIndex,
  },
  methods: {
    // Your component methods here
  },
  created() {
    // Code to run when the component is created
  },
};
</script>

<style scoped>
/* Your component styles here */
</style>
