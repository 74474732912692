<template>
    <div v-if="isBalanceUsed" class="flex w-full flex-row gap-x-4 gap-y-2 pb-4">
        <div class="relative w-full">
            <div class="field-inset-default bg-gray-100">
                <label for="metode_saldo_pembayaran" class="block text-xs font-bold text-gray-700">
                    Metode Pembayaran
                </label>
                <div id="metode_saldo_pembayaran" class="input-inset-select disabled:bg-gray-100">
                    Saldo Pembayaran ({{
                        orderBalance
                        | toCurrency
                    }})
                </div>
            </div>
        </div>
        <div class="relative w-full">
            <div class="field-inset-default bg-gray-100">
                <label for="saldo_pembayaran" class="block text-xs font-bold text-gray-700">
                    Nominal Pembayaran
                </label>
                <input type="text" name="saldo_pembayaran" id="saldo_pembayaran"
                    class="input-inset-select disabled:bg-gray-100" placeholder="Masukkan Nominal Pembayaran"
                    :value="totalBalanceUsed | toCurrency" disabled />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    props: {
        grandTotalPrice: {
            type: Number,
            default: 0
        }
    },
    computed: {
        ...mapGetters({
            getOffice: 'offices/getOffice',
            getOrder: 'orders/getOrder'
        }),
        isBalanceUsed() {
            return this.totalBalanceUsed > 0
        },
        totalBalanceUsed() {
            return Math.min(this.orderBalance, this.grandTotalPrice)
        },
        orderBalance() {
            return this.getOffice.data.attributes.order_balance_available + this.getOrder.data.attributes.final_price
        }
    }
}
</script>