export const cashbackMixin = {
    methods: {
        canHaveCashback(stockistCode) {
            return !(['SCN', 'KYN', 'PRSH'].some(key => stockistCode.startsWith(key)))
        },
        getOfficeCategoryByType(type) {
            return this.$store.getters["office_categories/getAvailableOfficeCategories"].find(officeCategory => officeCategory.attributes.name.toLowerCase() === type.toLowerCase())
        },
        getCashbackAmount(officeCategoryOrOfficeType, areaCode, price) {
            if (!officeCategoryOrOfficeType) {
                return 0
            }

            if (typeof officeCategoryOrOfficeType === 'string') {
                const officeCategory = this.getOfficeCategoryByType(officeCategoryOrOfficeType)

                return this.getCashbackAmountByOfficeCategory(officeCategory, areaCode, price)
            } else {
                return this.getCashbackAmountByOfficeCategory(officeCategoryOrOfficeType, areaCode, price)
            }
        },
        getCashbackMinOrder(officeCategoryOrOfficeType, areaCode) {
            if (typeof officeCategoryOrOfficeType === 'string') {
                const officeCategory = this.getOfficeCategoryByType(officeCategoryOrOfficeType)

                return this.getCashbackMinOrderByOfficeCategory(officeCategory, areaCode)
            } else {
                return this.getCashbackMinOrderByOfficeCategory(officeCategoryOrOfficeType, areaCode)
            }
        },
        getCashbackAmountByOfficeCategory(officeCategory, areaCode, price) {
            const isJawa = areaCode === 0
            const cashback = officeCategory.attributes[isJawa ? 'cashback_jawa' : 'cashback_luar_jawa']
            const cashbackMinOrder = this.getCashbackMinOrderByOfficeCategory(officeCategory, areaCode)

            const cashbackMultiplier = Math.floor(price / cashbackMinOrder)

            return cashbackMultiplier * cashback
        },
        getCashbackMinOrderByOfficeCategory(officeCategory, areaCode) {
            const isJawa = areaCode === 0

            return officeCategory.attributes[isJawa ? 'min_order_jawa' : 'min_order_luar_jawa']
        }
    }    
}