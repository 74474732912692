import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(localeData);

export function getListMonths() {
  return dayjs.months();
}

export function getListPastNYear({ n = 10 } = {}) {
  return Array(n)
    .fill(dayjs().year())
    .map((x, i) => x - i);
}

export function getCurrentDate({ startMonthIndex = 1 } = {}) {
  return {
    fulldate: dayjs().format(),
    month: dayjs().month() + startMonthIndex,
    year: dayjs().year(),
  };
}

export function now(format) {
  return dayjs().format(format);
}
export function formatDate(date, format = 'LLL') {
  if (!date) {
    return null;
  }
  return dayjs(date).format(format);
}
