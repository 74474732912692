<template>
  <div class="flex flex-col space-y-5 rounded-lg bg-white p-4 sm:p-6 md:p-8">
    <div class="border-b border-gray-200 pb-5">
      <h3 class="text-lg font-bold leading-6 text-gray-900">Saldo</h3>
      <p class="mt-2 max-w-4xl text-sm text-gray-500">
        Pilih saldo yang akan digunakan untuk melakukan pembayaran
      </p>
    </div>
    <fieldset class="">
      <div class="space-y-4">
        <div
          class="flex items-center justify-between"
          v-for="(method, index) in paymentMethods"
          :key="index"
        >
          <label
            :for="method.balance_used"
            class="block cursor-pointer text-sm font-medium text-gray-700"
          >
            <input
              :disabled="!!method.id || balanceUsedTypes[method.balance_used].value < 1"
              :id="method.balance_used"
              v-model="method.isUsed"
              type="checkbox"
              name="balance"
              min="0"
              :class="!!method.id || balanceUsedTypes[method.balance_used].value < 1 ? 'cursor-not-allowed' : null"
              class="mr-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
            />
            {{ balanceUsedTypes[method.balance_used].label }}
            <span
              >(
              {{ balanceUsedTypes[method.balance_used].value | toCurrency }}
              )</span
            >
          </label>
          <!-- Input -->
          <div class="w-1/2">
            <div
              :class="[!method.isUsed ? 'bg-gray-100' : null]"
              class="field-inset-default relative"
            >
              <label for="name" class="block text-xs font-bold text-gray-700">
                Total Restock
              </label>
              <input
                type="text"
                :disabled="!method.isUsed"
                :value="method.amount | toCurrency"
                name="amount"
                :class="[!method.isUsed ? 'bg-gray-100' : null]"
                id="amount"
                class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                placeholder="contoh: 100000"
                @change="onChangeAmount(method, index)"
                @input="(e) => onInputAmount(e, index)"
              />
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'PaymentMethodSaldoForm',
  props: {
    value: {
      type: [Array, Object],
    },
    balanceUsedTypes: Object,
  },
  data() {
    return {
      trigger: 0,
      // Your component data here
    };
  },
  computed: {
    ...mapGetters({
      getPaymentMethods: 'payments/getPaymentMethods',
      getOrder: 'orders/getOrder',
    }),
    paymentMethods: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  components: {
    // Your child components here
  },
  methods: {
    ...mapActions({
      createPaymentMethod: 'payment_methods/createPaymentMethod',
      updatePaymentMethod: 'payment_methods/updatePaymentMethod',
    }),
    onInputAmount(e, index) {
      const value = Number(e.target.value.split(',')[0].replace(/\D/gi, ''));

      this.paymentMethods[index].amount = value;

      e.target.value = this.$options.filters.toCurrency(value, {
        style: 'decimal',
        maxFractionDigit: 0,
      });
    },
    async onChangeAmount(paymentMethod, index) {
      if (paymentMethod.id) {
        this.updatePaymentMethod({
          paymentMethodId: paymentMethod.id,
          attributes: {
            payment_amount: paymentMethod.amount,
            balance_used: paymentMethod.balance_used,
          },
        });
      } else {
        const res = await this.createPaymentMethod({
          paymentId: this.getOrder.data.relationships.payments.data[0].id,
          attributes: {
            payment_amount: paymentMethod.amount,
            balance_used: paymentMethod.balance_used,
          },
        });

        if (res) {
          this.paymentMethods[index].id = res.data.data.id
        }
      }
    },
    // Your component methods here
  },
  created() {
    // Code to run when the component is created
  },
};
</script>

<style scoped>
/* Your component styles here */
</style>
